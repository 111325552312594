import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { Button, ConfigProvider, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_SetAllowPlayFirstAction,
  AC_SetFilteredVideosAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { PlaylistsAPI } from 'api/playlists';
import { ReactComponent as IconDowndOutlined } from 'assets/img/icons/faDownOutlined.svg';
import ReelsLengthWarning from 'components/ReelsLengthWarning';
import AddRemoveKeyframeButton from 'components/VideoPlayerController/ReelsEditPlayerController/AddRemoveKeyframeButton';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import {
  EDIT_VIDEO_WINDOW_WIDTH_MS,
  FINISHED_TIME_REGEX,
  TIME_REGEX,
  // WINDOW_MODE_WIDTH_MS,
} from 'types/constants';
import { generateUUID } from 'types/crypto';
import {
  convertTimeToMilliseconds,
  filterAndIndex,
  formatMillisecondsToTime,
  handleVideoTimeInputChange,
  validateValue,
} from 'types/functions';

const ReelsEditPlayerController = ({
  to,
  setTo,
  setFrom,
  playerState,
}: {
  to: any;
  setTo: any;
  setFrom: any;
  playerState: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { activeVideo, filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const {
    playedEpisode,
    videosListEditMode,
    playedVideoSet,
    editedEpisodeRange,
    editedFileEpisodeRange,
  } = useSelector((state: AppStateType) => state.playerReducerV2);
  const { setPlayedEpsiodeAction } = playerReducerV2.actions;
  const { openedPlaylistId, openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { AC_setOpenedPlaylist } = playlistReducerV2.actions;
  const { keyframes, selectedReelsDurationWarning } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const {
    toggleReelsMode,
    setKeyframes,
    resetReelsState,
    setAddKeyframesIsEnabled,
  } = reelsReducer.actions;
  const initFromTime = (ef: number) => {
    return formatMillisecondsToTime(ef < 5000 ? 0 : ef - 0 * 1000);
  };
  const [inputValueFrom, setInputValueFrom] = useState(
    initFromTime(editedEpisodeRange[0]),
  );
  const [inputValueTo, setInputValueTo] = useState<string>(
    initFromTime(editedEpisodeRange[1]),
  );
  const { reelsModeOn, addKeyframesIsEnabled } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { showNotification } = notificationsReducer.actions;
  const [reelsFormat, setReelsFormat] = useState('9:16');
  const [episodeName, setEpisodeName] = useState<string>(
    playedEpisode?.user_event_name ||
      playedEpisode?.event_type?.name ||
      playedEpisode?.attributes.name ||
      t('New episode'),
  );
  useEffect(() => {
    if (activeVideo && activeVideo.duration !== undefined) {
      if (to + 0 * 1000 < activeVideo?.duration * 1000) {
        setInputValueTo(formatMillisecondsToTime(editedEpisodeRange[1]));
      } else {
        activeVideo?.duration !== undefined &&
          setInputValueTo(
            formatMillisecondsToTime(
              activeVideo.duration !== undefined
                ? activeVideo.duration * 1000
                : 0,
            ),
          );
      }
    }
    if (editedEpisodeRange[0] < 5000) {
      setInputValueFrom(formatMillisecondsToTime(0));
    } else {
      setInputValueFrom(
        formatMillisecondsToTime(editedEpisodeRange[0] - 0 * 1000),
      );
    }
    refFrom.current = editedEpisodeRange[0];
    refTo.current = editedEpisodeRange[1];
  }, [editedEpisodeRange, activeVideo]);
  useEffect(() => {
    if (
      reelsModeOn &&
      !addKeyframesIsEnabled &&
      playerState.playedSeconds * 1000 >= editedFileEpisodeRange[0] &&
      playerState.playedSeconds * 1000 <= editedFileEpisodeRange[1]
    ) {
      dispatch(setAddKeyframesIsEnabled(true));
    } else if (
      reelsModeOn &&
      addKeyframesIsEnabled &&
      (playerState.playedSeconds * 1000 < editedFileEpisodeRange[0] ||
        playerState.playedSeconds * 1000 > editedFileEpisodeRange[1])
    ) {
      dispatch(setAddKeyframesIsEnabled(false));
    }
  }, [
    reelsModeOn,
    editedEpisodeRange,
    playerState.playedSeconds,
    addKeyframesIsEnabled,
  ]);
  const refTo = useRef<number>(0);
  const refFrom = useRef<number>(0);
  const {
    setEditedEpisodeFrom,
    setEditedEpisodeTo,
    setEditedFileEpisodeFrom,
    setEditedFileEpisodeTo,
    resetEditMode,
  } = playerReducerV2.actions;
  const handleBlur = (value: string, callback: any, type: string) => {
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    const validatedValue = validateValue(value);
    const time = convertTimeToMilliseconds(validatedValue);
    let offset;
    if (
      playedEpisode?.user_timer !== undefined &&
      playedEpisode?.user_timer !== null
    ) {
      offset =
        playedEpisode?.user_timer?.started_at -
        playedEpisode?.game_timer?.started_at;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
    }
    if (type === 'to') {
      if (isNaN(time)) {
        setInputValueTo(formatMillisecondsToTime(refTo.current));
        return;
      }
      const msDelta = editedEpisodeRange[1] % 1000;
      if (time === 0 && time >= editedEpisodeRange[0]) {
        callback(0);
        dispatch(setEditedEpisodeTo(0));
        dispatch(setEditedFileEpisodeTo(offset));
        return;
      }
      if (activeVideo?.duration && time > activeVideo.duration * 1000) {
        setInputValueTo(formatMillisecondsToTime(refTo.current));
        return;
      }
      if (time < editedEpisodeRange[0]) {
        setInputValueTo(formatMillisecondsToTime(refTo.current));
        callback((prev: number) => prev);
        return;
      }
      if (activeVideo?.duration && time > 0) {
        if (time <= activeVideo?.duration * 1000) {
          if (
            time > editedEpisodeRange[1] &&
            time - editedEpisodeRange[1] > EDIT_VIDEO_WINDOW_WIDTH_MS
          ) {
            setInputValueTo(formatMillisecondsToTime(refTo.current));
            return;
          }
          if (time < editedEpisodeRange[0]) {
            setInputValueTo(formatMillisecondsToTime(refTo.current));
            callback((prev: number) => prev);
            return;
          }
          if (time !== undefined) {
            // setEditedTo(time);
            callback(time + msDelta);
            dispatch(setEditedEpisodeTo(time + msDelta));
            dispatch(setEditedFileEpisodeTo(time + offset + msDelta));
            // setInputValueTo(formatMillisecondsToTime(time));
            return;
          }
          return;
        } else {
          callback(activeVideo?.duration * 1000);
          return;
        }
      }
    } else {
      if (isNaN(time)) {
        setInputValueFrom(formatMillisecondsToTime(refFrom.current));
        return;
      }
      const msDelta = editedEpisodeRange[0] % 1000;
      if (time !== null) {
        if (
          time < editedEpisodeRange[0] &&
          editedEpisodeRange[0] - time > EDIT_VIDEO_WINDOW_WIDTH_MS
        ) {
          setInputValueFrom(formatMillisecondsToTime(refFrom.current));
          return;
        }
        if (time <= editedEpisodeRange[1]) {
          // setEditedFrom(time + 0);
          dispatch(setEditedEpisodeFrom(time + msDelta));
          dispatch(setEditedFileEpisodeFrom(time + offset + msDelta));
          callback(time + msDelta);
          return;
        }
        setInputValueFrom(formatMillisecondsToTime(refFrom.current));
        return;
      }
      if (callback) {
        callback(0);
        dispatch(setEditedEpisodeFrom(0));
        dispatch(setEditedFileEpisodeFrom(offset));
      }
    }
  };
  const saveChanges = () => {
    let offset;
    if (
      playedEpisode?.user_timer !== undefined &&
      playedEpisode?.user_timer !== null
    ) {
      offset =
        playedEpisode?.user_timer.started_at -
        playedEpisode?.game_timer.started_at;
    } else {
      offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
    }
    if (
      inputValueTo &&
      FINISHED_TIME_REGEX.test(inputValueFrom) &&
      TIME_REGEX.test(inputValueTo) &&
      videosListEditMode &&
      !openedPlaylistId &&
      !openedPlaylist
    ) {
      dispatch(resetEditMode());
      const updatedVideo = filterAndIndex(
        filteredVideos,
        playedVideoSet?.id,
        'id',
      );
      let editedEpisode;
      let updatedFilteredVideos;
      dispatch(AC_SetAllowPlayFirstAction(false));
      if (playedEpisode && playedEpisode.id.includes('temp-')) {
        editedEpisode = filterAndIndex(
          updatedVideo?.element?.user_episodes,
          playedEpisode.id,
          'id',
        );
        updatedFilteredVideos = [
          ...filteredVideos.slice(0, updatedVideo.index),
          {
            ...updatedVideo.element,
            user_episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.user_episodes?.slice(
                0,
                editedEpisode.index,
              ),
              {
                ...editedEpisode.element,
                initial_game_timer:
                  editedEpisode.element.initial_game_timer ||
                  editedEpisode.element.game_timer,
                game_timer: {
                  started_at: editedEpisodeRange[0],
                  finished_at: editedEpisodeRange[1],
                },
                user_timer: {
                  started_at: editedEpisodeRange[0] + offset,
                  finished_at: editedEpisodeRange[1] + offset,
                },
                isTemporary: true,
                keyframes: keyframes,
                user_event_name: episodeName,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.user_episodes?.slice(
                editedEpisode.index + 1,
              ),
            ],
          },
          ...filteredVideos.slice(updatedVideo.index + 1),
        ];
      } else {
        const editedEpisode = filterAndIndex(
          updatedVideo?.element?.episodes,
          videosListEditMode,
          'id',
        );
        updatedFilteredVideos = [
          ...filteredVideos.slice(0, updatedVideo.index),
          {
            ...updatedVideo.element,
            episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.episodes?.slice(0, editedEpisode.index),
              {
                ...editedEpisode.element,
                initial_game_timer: editedEpisode.element.game_timer,
                user_timer: {
                  started_at: editedEpisodeRange[0] + offset,
                  finished_at: editedEpisodeRange[1] + offset,
                },
                game_timer: {
                  started_at: editedEpisodeRange[0],
                  finished_at: editedEpisodeRange[1],
                },
                isTemporary: true,
                keyframes: keyframes,
                user_event_name: episodeName,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.episodes?.slice(editedEpisode.index + 1),
            ],
          },
          ...filteredVideos.slice(updatedVideo.index + 1),
        ];
      }
      dispatch(AC_SetFilteredVideosAction(updatedFilteredVideos));
      const editedPlayedEpisode = {
        ...playedEpisode,
        initial_game_timer: editedEpisode?.element?.game_timer,
        user_timer: {
          started_at: editedEpisodeRange[0] + offset,
          finished_at: editedEpisodeRange[1] + offset,
        },
        game_timer: {
          started_at: editedEpisodeRange[0],
          finished_at: editedEpisodeRange[1],
        },
        keyframes: keyframes,
      };
      dispatch(setPlayedEpsiodeAction(editedPlayedEpisode));
      dispatch(
        showNotification({
          notificationParameters: [
            {
              id: generateUUID(),
              text: t(
                'Episode was changed, but changes will be removed after closing or updating episodes list. To keep it add the epside to a playlist',
              ),
              callbackName: 'addToPlaylist',
              userEpsiodeId: playedEpisode?.id || '',
            },
          ],
        }),
      );
    } else if (openedPlaylist) {
      const editedPlaylistGame: any = { element: null, index: -1 };
      let found = false;
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const [index, game] of openedPlaylist?.playlist_events.entries()) {
        if (found) {
          break;
        }
        for (const ep of game.episodes) {
          if (ep.id === videosListEditMode) {
            editedPlaylistGame.element = game;
            editedPlaylistGame.index = index;
            found = true;
            break;
          }
        }
      }
      const editedEpisodeGame = filterAndIndex(
        editedPlaylistGame.element?.episodes,
        videosListEditMode,
        'id',
      );
      const updatedPlaylists = {
        ...openedPlaylist,
        playlist_events: [
          ...openedPlaylist.playlist_events.slice(0, editedPlaylistGame.index),
          {
            ...editedPlaylistGame.element,
            episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...editedPlaylistGame?.element.episodes.slice(
                0,
                editedEpisodeGame.index,
              ),
              {
                ...editedEpisodeGame.element,
                user_timer: {
                  started_at: editedEpisodeRange[0] + offset,
                  finished_at: editedEpisodeRange[1] + offset,
                },
                game_timer: {
                  started_at: editedEpisodeRange[0],
                  finished_at: editedEpisodeRange[1],
                },
                keyframes: keyframes,
                user_event_name: episodeName,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...editedPlaylistGame?.element.episodes.slice(
                editedEpisodeGame.index + 1,
              ),
            ],
          },
          ...openedPlaylist.playlist_events.slice(editedPlaylistGame.index + 1),
        ],
      };
      // TODO TRANSFER TO IMMER
      dispatch(AC_setOpenedPlaylist(updatedPlaylists));
      const updatedPayload = {
        game_timer: {
          started_at: editedEpisodeRange[0],
          finished_at: editedEpisodeRange[1],
        },
        user_timer: {
          started_at: editedEpisodeRange[0] + offset,
          finished_at: editedEpisodeRange[1] + offset,
        },
        file_timer: {
          started_at: editedFileEpisodeRange[0],
          finished_at: editedFileEpisodeRange[1],
        },
        keyframes: keyframes,
        user_event_name: episodeName,
      };
      dispatch(AC_SetAllowPlayFirstAction(false));
      openedPlaylistId &&
        PlaylistsAPI.updatePlaylistElement(
          openedPlaylistId,
          editedEpisodeGame.element.id,
          updatedPayload,
        ).then(() => {
          dispatch(
            showNotification({
              notificationParameters: [
                {
                  id: generateUUID(),
                  text: t('Episode was edited'),
                  callbackName: 'addPlaylistEpisodeToAnotherPlaylist',
                  userEpsiodeId: editedEpisodeGame.element.id,
                },
              ],
            }),
          );
          dispatch(
            setPlayedEpsiodeAction(
              updatedPlaylists.playlist_events[editedPlaylistGame.index]
                .episodes[editedEpisodeGame.index || 0],
            ),
          );
        });
    }
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(toggleReelsMode());
    dispatch(setKeyframes([]));
  };
  return (
    <div className="reels-edit-event-controller-wrapper">
      <div
        style={{
          width: 71,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueFrom}
            allowClear={false}
            onFocus={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            }}
            onChange={(event) =>
              handleVideoTimeInputChange(event, setInputValueFrom)
            }
            onBlur={(event) => {
              handleBlur(event.target.value, setFrom, 'from');
            }}
            placeholder=""
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>
      <div
        style={{
          width: 71,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                paddingInline: 12,
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueTo}
            allowClear={false}
            onChange={(event) =>
              handleVideoTimeInputChange(event, setInputValueTo)
            }
            onFocus={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            }}
            onBlur={(event) => {
              handleBlur(event.target.value, setTo, 'to');
            }}
            placeholder=""
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: 8 }}>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                paddingInline: 12,
                colorBgContainer: 'var(--colorBgContainer)',
                colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                fontSize: 14,
                fontFamily: 'SFProTextRegular',
              },
              Select: {
                colorBgContainer: 'var(--colorBgContainer)',
                colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                fontSize: 14,
                fontFamily: 'SFProTextRegular',
                selectorBg: 'var(--colorBgContainer)',
                optionSelectedColor: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            type="text"
            // style={{width: "96%"}}
            value={episodeName}
            allowClear={false}
            onBlur={() => dispatch(AC_SetPlayerHotkeysAllowedAction(true))}
            onFocus={() => dispatch(AC_SetPlayerHotkeysAllowedAction(false))}
            onChange={(e) => setEpisodeName(e.target.value)}
            placeholder={t('New episode')}
          />
          <Select
            style={{ width: 256 }}
            value={reelsFormat}
            onSelect={(value) => setReelsFormat(value)}
            dropdownStyle={{ backgroundColor: 'var(--colorBgSpotlight)' }}
            suffixIcon={
              <>
                {selectedReelsDurationWarning && <ReelsLengthWarning />}
                <IconDowndOutlined />
              </>
            }
          >
            <Select.Option key={2} label={t('Reels (9:16)')} value={'9:16'}>
              {t('Reels (9:16)')}
            </Select.Option>
          </Select>
        </ConfigProvider>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorBgContainer)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              lineHeight: 22,
            },
          },
        }}
      >
        <AddRemoveKeyframeButton mode={'add'} playerState={playerState} />
        <Button
          style={{ padding: '0px 16px 0px 16px' }}
          type="text"
          onClick={() => {
            dispatch(resetEditMode());
            dispatch(toggleReelsMode());
            dispatch(resetReelsState());
          }}
        >
          {t('Cancel ss')}
        </Button>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorPrimaryBase)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              paddingBlockLG: 16,
            },
          },
        }}
      >
        <Button
          onClick={() => saveChanges()}
          style={{ padding: '0px 16px 0px 16px' }}
          type="primary"
        >
          {t('Save')}
        </Button>
      </ConfigProvider>
    </div>
  );
};
export default ReelsEditPlayerController;
