import React, { useEffect, useState } from 'react';

import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as NewKeyFrame } from 'assets/img/icons/faNewKeyFrame.svg';
import { ReactComponent as RemoveKeyFrame } from 'assets/img/icons/faRemoveKeyFrame.svg';
import { AppStateType } from 'reducers';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import { KeyFrameType } from 'types/types';

const AddRemoveKeyframeButton = ({
  playerState,
}: {
  mode: string;
  playerState: any;
}) => {
  const dispatch = useAppDispatch();
  const { addKeyframesIsEnabled, reelEditorPosition } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { keyframes } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { insertKeyFrame, removeKeyFrame } = reelsReducer.actions;
  const [icon, setIcon] = useState<any>(null);
  useEffect(() => {
    if (
      keyframes.filter(
        (kf: KeyFrameType) => kf.time === playerState?.playedSeconds * 1000,
      ).length
    ) {
      setIcon(<RemoveKeyFrame />);
    } else {
      setIcon(<NewKeyFrame />);
    }
  }, [keyframes, playerState]);
  const onClickHandler = (e: any) => {
    if (
      keyframes.filter(
        (kf: KeyFrameType) => kf.time === playerState?.playedSeconds * 1000,
      ).length
    ) {
      dispatch(removeKeyFrame({ time: playerState.playedSeconds * 1000 }));
    } else if (reelEditorPosition) {
      const newKeyFrame: KeyFrameType = {
        time: playerState?.playedSeconds * 1000,
        ...reelEditorPosition,
      };
      dispatch(insertKeyFrame(newKeyFrame));
    }
    e.target.blur();
  };
  const [t] = useTranslation();
  return (
    <Tooltip title={t('Add key frame')}>
      <Button
        disabled={!addKeyframesIsEnabled}
        style={{
          width: 48,
          backgroundColor: 'var(--colorBgLayout)',
          outline: 'none',
        }}
        onClick={onClickHandler}
        icon={icon}
        onKeyDownCapture={(e) => e.preventDefault()}
      />
    </Tooltip>
  );
};
export default AddRemoveKeyframeButton;
