import { useEffect, useState } from 'react';
import './index.css';
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchOutlined } from '@ant-design/icons';
import { Badge, ConfigProvider, Select, Space, Tooltip } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { ClubsAPI } from 'api/clubs';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/faDownloadGrey.svg';
import { ReactComponent as DownloadIconWhite } from 'assets/img/icons/faDownloadWhite.svg';
import { ReactComponent as StarGrey } from 'assets/img/icons/faStarGrey.svg';
import { ReactComponent as StarWhite } from 'assets/img/icons/faStarWhite.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/LogoutIcon.svg';
import LogoBlack from 'assets/LogoBlackV2.svg';
import LogoLight from 'assets/LogoWhiteV2.svg';
import { AppStateType } from 'reducers';
import { downloadsReducer } from 'reducers/downloads.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';
import { generateUUID } from 'types/crypto';
import { NotificationType } from 'types/types';
// import { MenuItemType } from 'types/types';
// import { getItem } from 'utils/functions';

const fetchData = async (query: string, callback: any, t: any) => {
  const teamsSearchResult = await ClubsAPI.searchForTeams(query);
  const playersSearchResult = await ClubsAPI.searchForPlayers(query);
  const searchedTeams: any[] = teamsSearchResult.results.map((el: any) => {
    return {
      id: el.id,
      value: el.id,
      name: el.name,
      image: el.image,
    };
  });
  const searchedPlayers: any[] = playersSearchResult.results.map((el: any) => {
    return {
      id: el.id,
      value: el.id,
      name: `${el.first_name} ${el.last_name}`,
      image: el.photo,
      team: el.team,
    };
  });
  const searchResult = [];
  if (searchedTeams.length) {
    searchResult.push({
      label: t('Teams'),
      options: searchedTeams,
      type: 'teams',
      key: generateUUID(),
    });
  }
  if (searchedPlayers.length) {
    searchResult.push({
      label: t('Players'),
      options: searchedPlayers,
      type: 'players',
      key: generateUUID(),
    });
  }
  callback(searchResult);
};

const NavigationPanelV2 = ({
  page,
  position = 'sticky',
}: {
  page: string;
  position?: any;
}) => {
  const { style } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(page);
  const navigate = useNavigate();
  const PAGES: any = {
    main: '/',
    player: '/player',
    playlists: '/player/playlists',
    stats: '/',
    global: '/',
    settings: '/',
    mail: '/',
    downloads: '/downloads',
  };
  const goToPage = (page: string) => {
    setCurrentPage(page);
    navigate(PAGES[page]);
  };
  const LOGOS: any = {
    light: LogoBlack,
    dark: LogoLight,
  };
  const logoutClickHandler = () => {
    typeof window !== 'undefined' &&
      window.localStorage &&
      localStorage.removeItem('authtoken');
    localStorage.removeItem('redirectTo');
    localStorage.removeItem('refreshToken');
    return navigate('/login');
  };
  const { goBackToPage } = useSelector(
    (state: AppStateType) => state.dowloadsReducer,
  );
  const { notificatons } = useSelector(
    (state: AppStateType) => state.notificationsReducer,
  );
  const [downloadsNotificationsCount, setDownloadsNotificationsCount] =
    useState<number>(0);
  useEffect(() => {
    if (
      currentPage !== PAGES.downloads &&
      notificatons &&
      notificatons.length
    ) {
      setDownloadsNotificationsCount(
        notificatons.filter(
          (notification: NotificationType) => !notification.is_read, // TODO потом придется расширять тут
        ).length,
      );
    } else {
      setDownloadsNotificationsCount(0);
    }
  }, [notificatons]);
  const { setGoBackToPage } = downloadsReducer.actions;
  const { setSelectedSeason, setSelectedBestPlayerSeason } =
    teamReducer.actions;
  const [t] = useTranslation();
  const [selection, setSelection] = useState<any[]>([]);
  const [query, setQuery] = useState<string | null>(null);
  useEffect(() => {
    if (query && query.length >= 3) {
      fetchData(query, setSelection, t).then();
    }
  }, [query]);
  const location = useLocation();
  const { pathname } = location;
  const value = null;
  const search = (newValue: string) => {
    setQuery(newValue);
  };
  const dispatch = useAppDispatch();
  const { setOpenedPlayer } = teamPlayerReducer.actions;
  const navigteToCurrentTeam = () => {
    if (currentUser?.current_team) {
      navigate(`/teams/${currentUser?.current_team}`);
    } else if (currentUser?.teams && currentUser?.teams.length > 0) {
      navigate(`/teams/${currentUser?.teams[0]}`);
    }
  };
  const { teamId, playerId } = useParams();
  return (
    <Header
      style={{
        position: position,
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        background: 'var(--colorBgContainer, rgba(20, 20, 20, 1))',
        // background: 'var(--colorBgLayout, #F5F5F5)',
        padding: 0,
        height: 56,
        borderBottom: 'solid 1px var(--colorBorder)',
        justifyContent: 'space-between',
      }}
    >
      <div
        className="navbar-logo-container enabledClickable"
        onClick={navigteToCurrentTeam}
      >
        <div className="navbar-logo">
          <img src={LOGOS[style]} />
        </div>
      </div>
      <div style={{ flex: '1 1' }}>
        <ConfigProvider
          theme={{
            token: {
              // colorBgContainer: 'var(--colorBgContainer, rgba(20, 20, 20, 1))',
              colorBgContainer: 'var(--colorBgLayout, #F5F5F5)',
              borderRadiusSM: 24,
              colorBorder: 'transparent',
              // colorBorder: 'var(--colorBorder, #424242)',
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              controlOutlineWidth: 0,
              colorText: 'var(--colorInpuText)',
            },
          }}
        >
          <Select
            className="search-cascader"
            style={{ width: 499, minWidth: 499 }}
            onChange={() => {
              setSelection([]);
            }}
            onFocus={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            }}
            onBlur={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(true));
            }}
            showSearch={true}
            value={value}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={search}
            allowClear={false}
            suffixIcon={
              <SearchOutlined style={{ color: 'var(--headerIconColor)' }} />
            }
            placeholder={t('Search for teams and players')}
            notFoundContent={
              <div
                style={{
                  color: 'var(--colorTextDescription)',
                  padding: '24px 0px',
                  textAlign: 'center',
                }}
              >
                {t('Type player, team or tournament name')}
              </div>
            }
            dropdownStyle={{
              backgroundColor: 'var(--colorBgElevated, #3D4249)',
              borderRadius: 6,
              boxShadow:
                '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
              color: 'var(--colorInpuText)',
            }}
          >
            {selection.map((group) => (
              <Select.OptGroup label={group.label} key={group.key}>
                {group.options.map((option: any) => (
                  <Select.Option value={option.value} className="custom-option">
                    <div
                      className="search-row enabledClickable"
                      onClick={() => {
                        if (
                          group.type === 'teams' &&
                          option.id !== Number(teamId)
                        ) {
                          dispatch(setSelectedSeason(null));
                          dispatch(setSelectedBestPlayerSeason(null));
                        }
                        if (
                          group.type === 'players' &&
                          option.id !== playerId
                        ) {
                          dispatch(setOpenedPlayer(null));
                        }
                        navigate(`/${group.type}/${option.id}`);
                      }}
                    >
                      {option.image ? (
                        <img src={option.image} alt="" style={{ height: 36 }} />
                      ) : (
                        <img src={LogoBlack} alt="" />
                      )}
                      <div style={{ flex: 1 }}>{t(option.name)}</div>
                      <div style={{ color: 'var(--colorTextTertiary)' }}>
                        {option.team}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </ConfigProvider>
      </div>
      <div
        className="flex-row f-ga-16 ai-c"
        style={{
          padding: '0 32px',
          flex: '1 1',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          className={`navbar-button enabledClickable ${page === 'downloads' ? 'active-navbar-button' : ''}`}
          style={{
            position: 'relative',
          }}
          onClick={() => {
            if (currentPage === 'downloads') {
              navigate(goBackToPage);
              dispatch(setGoBackToPage(''));
            } else {
              dispatch(setGoBackToPage(pathname));
              navigate('/downloads');
            }
          }}
        >
          {downloadsNotificationsCount ? (
            <Space style={{ position: 'absolute' }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorBorderBg: 'var(--colorBgContainer, #141414)',
                  },
                }}
              >
                <Badge
                  offset={[15, -15]}
                  size="small"
                  overflowCount={99}
                  color={'var(--colorPrimaryBase, rgba(22, 104, 220, 1))'}
                  count={downloadsNotificationsCount}
                ></Badge>
              </ConfigProvider>
            </Space>
          ) : (
            <></>
          )}
          <Tooltip title={t('Downloads')} arrow={false}>
            {currentPage === 'downloads' ? (
              <DownloadIconWhite />
            ) : (
              <DownloadIcon />
            )}
          </Tooltip>
        </div>
        <div
          className={`navbar-button enabledClickable ${page === 'playlists' ? 'active-navbar-button' : ''}`}
          onClick={() => goToPage('playlists')}
        >
          <Tooltip title={t('Playlists')} arrow={false}>
            {currentPage === 'playlists' ? <StarWhite /> : <StarGrey />}
          </Tooltip>
        </div>
        <div
          className={'navbar-button enabledClickable'}
          onClick={() => logoutClickHandler()}
        >
          <Tooltip title={t('Logout')} arrow={false}>
            <LogoutIcon />
          </Tooltip>
        </div>
      </div>
    </Header>
  );
};
export default NavigationPanelV2;
