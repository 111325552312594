import React, { useEffect } from 'react';

import { GetCurrentUserAction } from 'actions/auth.actions';
import { AC_OpenFilterAction } from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
} from 'actions/player.acitons';
import ContentContainer from 'components/ContentContainer';
import VideoDownloadsController from 'components/VideoDownloadsController';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import { STYLE_TYPES } from 'types/constants';

const VideoDownloadsPage = () => {
  const dispatch = useAppDispatch();
  const {
    AC_setPlaylistsAndFolders,
    AC_setOpenedPlaylist,
    AC_setOpenedPlaylistId,
  } = playlistReducerV2.actions;
  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(AC_OpenFilterAction(''));
    dispatch(setStyleModeAction(STYLE_TYPES.dark));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(AC_SetPlayerLoadingAction(false));
    dispatch(AC_setPlaylistsAndFolders([]));
    dispatch(AC_setOpenedPlaylist(null));
    dispatch(AC_setOpenedPlaylistId(null));
    window.document.body.setAttribute('data-theme', 'dark');
  }, []);

  return (
    <ContentContainer
      pageTitle={''}
      page={'downloads'}
      theme="dark"
      boxSizing={'border-box'}
      showNavigationPanel={true}
      wideScreenMode={false}
    >
      <VideoDownloadsController />
    </ContentContainer>
  );
};
export default VideoDownloadsPage;
