import React, { useEffect, useState } from 'react';

import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as PlayCircle } from '../../../../assets/img/icons/faPlayCircle.svg';

import './index.css';

const ConsolidatedStatValueTag = ({
  stat,
  makeLink,
}: {
  stat: any;
  makeLink: any;
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayedValue, setDisplayedValue] = useState(null);
  useEffect(() => {
    stat && setDisplayedValue(stat.total);
  }, [stat]);
  const [t] = useTranslation();
  return (
    <>
      {displayedValue !== null ? (
        <Tooltip
          open={false}
          title={t('View episodes')}
          trigger={'hover'}
          placement="top"
        >
          <Flex
            className={`${stat?.total === undefined ? 'na-stat' : stat?.total > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
            style={{ minWidth: 70 }}
            justify={stat?.total !== undefined ? 'space-between' : 'center'}
            onClick={(e) => {
              e.stopPropagation();
              if (stat?.total) {
                navigate(makeLink(stat));
              }
            }}
          >
            {displayedValue ? <PlayCircle height={32} /> : <></>}
            {stat?.total === undefined ? (
              <div
                style={{ height: 32 }}
                className={'flex-row j-ce ai-c disabled-stat-color'}
              >
                {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
              </div>
            ) : stat?.total > 0 ? (
              <div style={{ height: 32 }} className={'flex-row ai-c'}>
                {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
              </div>
            ) : (
              <span className="stat-average-value zero-value">0</span>
            )}
          </Flex>
        </Tooltip>
      ) : (
        <Flex
          className={`${stat?.total === undefined ? 'na-stat' : stat?.total > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
          style={{ minWidth: 70 }}
          justify={stat?.total !== undefined ? 'space-between' : 'center'}
          onClick={(e) => {
            e.stopPropagation();
            if (stat?.total) {
              navigate(makeLink(stat));
            }
          }}
        >
          {displayedValue ? <PlayCircle height={32} /> : <></>}
          {stat?.total === undefined ? (
            <div
              style={{ height: 32 }}
              className={'flex-row j-ce ai-c disabled-stat-color'}
            >
              {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
            </div>
          ) : stat?.total > 0 ? (
            <div style={{ height: 32 }} className={'flex-row ai-c'}>
              {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
            </div>
          ) : (
            <span className="stat-average-value zero-value">0</span>
          )}
        </Flex>
      )}
    </>
  );
};
export default ConsolidatedStatValueTag;
