import React, { useEffect, useState } from 'react';

import './index.css';

import {
  DndContext,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ConfigProvider, Modal } from 'antd';
import { produce } from 'immer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UpdateUserAction } from 'actions/auth.actions';
import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import {
  deleteFolderAction,
  getRootPlaylistsActionV2,
  updateFolderAction,
} from 'actions/playlistV2.async.actions';
import { PlaylistsAPI } from 'api/playlists';
// import { ReactComponent as CheckCircleFilled } from 'assets/img/icons/faCheckCircleFilled.svg';
import { ReactComponent as ExclamationCircle } from 'assets/img/icons/faExclamationCircle.svg';
import PlaylistsSkeleton from 'components/Skeletons/PlaylistsSkeleton';
import { MODES } from 'components/VideoListController';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import { generateUUID } from 'types/crypto';

import FolderElement from './FolderElement';
import PlaylistElement from './PlaylistElement';
import DeletePlaylistModal from '../modals/DeletePlaylistModal';
import MoveToFolderModal from '../modals/MoveToFolderModal';

const FoldersComponent = ({
  shareEpisodesCallable,
  setMovePlaylistToFolderOpen,
}: {
  shareEpisodesCallable: any;
  movePlaylistToFolderOpen: any;
  setMovePlaylistToFolderOpen: any;
}) => {
  const { openedPlaylistId, playlistsAndFolders, playlistsAndFoldersLoading } =
    useSelector((state: AppStateType) => state.playlistReducerV2);
  const { AC_setPlaylistsAndFolders, setPlaylistsAndFoldersLoading } =
    playlistReducerV2.actions;
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(getFoldersAction(0));
    dispatch(getRootPlaylistsActionV2());
  }, []);

  const [t] = useTranslation();
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newFolderName, setNewFolderName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renameFolderHandler = (folder: any) => {
    const updatedFolder = {
      ...folder,
      name: newFolderName,
    };
    setRenameFolderId(null);
    dispatch(updateFolderAction(updatedFolder, folder.id));
  };
  useEffect(() => {
    if (renameFolderId && inputRef.current) {
      //@ts-ignore
      inputRef.current!.focus({
        cursor: 'all',
      });
    }
  }, [renameFolderId]);
  const inputRef = React.useRef(null);
  const { showNotification } = notificationsReducer.actions;
  const deleteFolder = (folderId: string | null) => {
    setDeleteFolderId(null);
    if (folderId) {
      dispatch(
        showNotification({
          notificationParameters: [
            {
              id: generateUUID(),
              text: t('Folder was deleted'),
              // callbackName: 'removeFolder',
              userEpsiodeId: folderId,
            },
          ],
        }),
      );
      dispatch(deleteFolderAction(folderId));
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  function handleDragEnd(event: any, playlists: any, folderId: string) {
    const { active, over } = event;
    // const type = playlistsAndFolders.filter(el => el.id === active.id)[0].elementType;
    const type = 'playlist';
    const oldIndex = playlists.findIndex((i: any) => i.id === active.id);
    const newIndex = playlists.findIndex((i: any) => i.id === over.id);
    // const newType = playlistsAndFolders.filter(el => el.id === over.id)[0].elementType;
    const newType = 'playlist';
    if (
      type === 'playlist' &&
      newType === 'playlist' &&
      over &&
      active?.id !== over?.id
    ) {
      const activeElId = playlists.filter((el: any) => el.id === active.id)[0]
        .elId;
      const overElId = playlists.filter((el: any) => el.id === over.id)[0].elId;
      const payload: any = { target_event_id: activeElId };
      if (
        active.data.current.sortable.index > over.data.current.sortable.index
      ) {
        payload['new_sibling_id'] = overElId;
      } else {
        payload['new_parent_id'] = overElId;
      }
      const reorderedPlaylists = arrayMove(playlists, oldIndex, newIndex);
      const updatedState = produce(playlistsAndFolders, (draft) => {
        const update = (els: any, localDraft: any): any => {
          for (const [index, folderRow] of els.entries()) {
            if (folderRow.elId === folderId) {
              localDraft[0].playlists = reorderedPlaylists;
              return;
            } else {
              update(folderRow.children, localDraft[index].children);
            }
          }
        };
        update(playlistsAndFolders, draft);
        // return draft;
      });
      dispatch(AC_setPlaylistsAndFolders(updatedState));

      if (currentUser?.playlist_sorting === 'manual') {
        PlaylistsAPI.reorderPlaylist(activeElId, payload).then();
      } else {
        currentUser &&
          dispatch(
            UpdateUserAction(currentUser?.id, {
              id: currentUser?.id,
              playlist_sorting: 'manual',
            }),
          );
        // dispatch(FullPlaylistsLevelReorder(reorderedPlaylists.map((el: any) => el.id)));
      }
    } else if (
      //@ts-ignore
      type === 'folder' &&
      //@ts-ignore
      newType === 'folder' &&
      over &&
      active?.id !== over?.id
    ) {
      const activeElId = playlistsAndFolders.filter(
        (el) => el.id === active.id,
      )[0].id;
      const overElId = playlistsAndFolders.filter((el) => el.id === over.id)[0]
        .id;
      const payload: any = { target_event_id: activeElId };
      if (
        active.data.current.sortable.index > over.data.current.sortable.index
      ) {
        payload['new_sibling_id'] = overElId;
      } else {
        payload['new_parent_id'] = overElId;
      }
      dispatch(
        AC_setPlaylistsAndFolders(
          arrayMove(playlistsAndFolders, oldIndex, newIndex),
        ),
      );
      PlaylistsAPI.reorderFolder(activeElId, payload);
    }
  }
  const [moveToFolderModalOpen, setMoveToFolderModalOpen] = useState<
    string | null
  >(null);

  const moveToFolderCallback = (destinationFolder: string | null) => {
    if (moveToFolderModalOpen) {
      let newFolder: any = undefined;
      const retrieveFolder = (iterable: any, newFolder: any): any => {
        for (const element of iterable) {
          if (newFolder) {
            return newFolder;
          }
          if (element.elId === moveToFolderModalOpen) {
            newFolder = {
              ...element,
              parent: destinationFolder,
            };
            return newFolder;
          }
          newFolder = retrieveFolder(element?.children, 0);
        }
        return newFolder;
      };
      newFolder = retrieveFolder(playlistsAndFolders, newFolder);

      const payload = {
        parent: newFolder.parent,
        name: newFolder.name,
        collapsed: newFolder.collapsed,
      };
      dispatch(updateFolderAction(payload, newFolder.elId));
      dispatch(getRootPlaylistsActionV2());
      setMoveToFolderModalOpen(null);
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    }
  };
  const [playlistForDeletionId, setPlaylistForDeletionId] = useState(null);

  const deletePlaylist = () => {
    if (playlistForDeletionId) {
      PlaylistsAPI.deletePlaylist(playlistForDeletionId).then(() => {
        setPlaylistForDeletionId(null);
        dispatch(getRootPlaylistsActionV2());
      });
    }
  };

  const renderTree = (rootelement: any, isRoot: boolean) => {
    const makePlaylist = () => {
      return !rootelement.collapsed && rootelement.playlists ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) =>
            handleDragEnd(event, rootelement.playlists, rootelement.elId)
          }
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
        >
          <SortableContext
            items={rootelement.playlists}
            strategy={verticalListSortingStrategy}
          >
            {rootelement.playlists.map((element: any) => {
              return (
                <PlaylistElement
                  key={`playlist-${element.id}`}
                  playlist={element}
                  root={element.folder_level === null}
                  movePlayToFolderCallback={setMovePlaylistToFolderOpen}
                  shareEpisodesCallable={shareEpisodesCallable}
                  deletePlaylistCallback={setPlaylistForDeletionId}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      ) : (
        <></>
      );
    };
    const makeFolder = () => {
      return (
        !isRoot && (
          <FolderElement
            key={`playlist-${rootelement.id}`}
            folder={rootelement}
            setDeleteFolderId={setDeleteFolderId}
            moveToFolderCallback={setMoveToFolderModalOpen}
            shareEpisodesCallable={shareEpisodesCallable}
          />
        )
      );
    };

    return rootelement ? (
      <>
        {isRoot ? (
          <>
            {makePlaylist()}
            {makeFolder()}
          </>
        ) : (
          <>
            {makeFolder()}
            {makePlaylist()}
          </>
        )}
        {!rootelement.collapsed && rootelement.children?.length > 0 ? (
          rootelement.children.map((el: any) => {
            return renderTree(el, false);
          })
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    );
  };
  const [tree, setTree] = useState<any>(undefined);
  const { playerMode } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const [rendering, setRendering] = useState(false);
  useEffect(() => {
    if (!playlistsAndFoldersLoading && playerMode === MODES.playlists) {
      setRendering(true);
      const t = renderTree(playlistsAndFolders[0], true);
      setTree(t);
      dispatch(setPlaylistsAndFoldersLoading(false));
      setRendering(false);
    }
  }, [playlistsAndFoldersLoading, playlistsAndFolders, playerMode, setTree]);
  return (
    <div
      className={'flex-column f-ga-8 gameListContainer'}
      style={{ margin: '0 0px 0 4px' }}
    >
      {openedPlaylistId ? (
        <></>
      ) : (
        <>
          {playlistsAndFoldersLoading || rendering ? (
            <PlaylistsSkeleton />
          ) : (
            tree
          )}
          {
            <MoveToFolderModal
              isOpen={moveToFolderModalOpen !== null}
              type={'folder'}
              folderId={moveToFolderModalOpen}
              handleOk={moveToFolderCallback}
              closeCallable={() => {
                setMoveToFolderModalOpen(null);
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
              }}
            />
          }
          {
            <ConfigProvider
              theme={{
                token: {
                  padding: 32,
                },
                components: {
                  Modal: {
                    contentBg: '#576170',
                    colorBgMask: 'var(--colorBgLayoutTransparent)',
                  },
                },
              }}
            >
              <Modal
                width={360}
                // ={188}
                closeIcon={null}
                // title={t('Delete folder')}
                open={deleteFolderId !== null}
                onOk={() => deleteFolder(deleteFolderId)}
                onCancel={() => setDeleteFolderId(null)}
                cancelText={t('Cancel')}
                okText={t('Delete')}
                style={{ padding: 12 }}
                cancelButtonProps={{
                  type: 'text',
                  style: {
                    border: '1px solid var(--character-secondary-45)',
                    color: 'var(--character-title-85)',
                    flex: 1,
                  },
                }}
                okButtonProps={{
                  style: { flex: 1, color: 'var(--character-primary-inverse)' },
                }}
                classNames={{
                  footer: 'flex-row w100 j-sb',
                }}
              >
                <div className="flex-row f-ga-16">
                  <div>
                    <ExclamationCircle />
                  </div>
                  <div className="flex-column f-ga-8">
                    <div className="modal-title-dark">{t('Delete folder')}</div>
                    <div className="modal-content-dark">
                      {t(
                        'All playlists from this folder will be saved to root',
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
            </ConfigProvider>
          }
        </>
      )}
      <DeletePlaylistModal
        closeCallable={() => setPlaylistForDeletionId(null)}
        handleOk={deletePlaylist}
        isOpen={playlistForDeletionId !== null}
      />
    </div>
  );
};
export default FoldersComponent;
