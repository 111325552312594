import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { Button, ConfigProvider, Input } from 'antd';
import { InputStatus } from 'antd/es/_util/statusUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_SetAllowPlayFirstAction,
  AC_SetFilteredVideosAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { PlaylistsAPI } from 'api/playlists';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import {
  EDIT_VIDEO_WINDOW_WIDTH_MS,
  FINISHED_TIME_REGEX,
  TIME_REGEX,
} from 'types/constants';
import { generateUUID } from 'types/crypto';
import {
  convertTimeToMilliseconds,
  filterAndIndex,
  formatMillisecondsToTime,
  handleVideoTimeInputChange,
  validateValue,
} from 'types/functions';

const EditEpisodePlayerController = ({
  // to,
  setTo,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  from,
  setFrom,
}: {
  to: any;
  setTo: any;
  from: any;
  setFrom: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { activeVideo, filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const {
    playedEpisode,
    videosListEditMode,
    playedVideoSet,
    editedEpisodeRange,
  } = useSelector((state: AppStateType) => state.playerReducerV2);
  const { openedPlaylistId, openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { setPlayedEpsiodeAction } = playerReducerV2.actions;
  const initFromTime = (ef: number) => {
    return formatMillisecondsToTime(ef < 5000 ? 0 : ef - 0 * 1000);
  };
  const [inputValueFrom, setInputValueFrom] = useState(
    initFromTime(editedEpisodeRange[0]),
  );
  const inputValueFromRef = useRef<any>(null);
  const [inputValueTo, setInputValueTo] = useState<string>(
    initFromTime(editedEpisodeRange[1]),
  );
  const inputValueToRef = useRef<any>(null);
  useEffect(() => {
    if (activeVideo && activeVideo.duration !== undefined) {
      if (editedEpisodeRange[1] + 0 * 1000 < activeVideo?.duration * 1000) {
        setInputValueTo(formatMillisecondsToTime(editedEpisodeRange[1]));
      } else {
        activeVideo?.duration !== undefined &&
          setInputValueTo(
            formatMillisecondsToTime(
              activeVideo.duration !== undefined
                ? activeVideo.duration * 1000
                : 0,
            ),
          );
      }
    }
    if (editedEpisodeRange[0] < 5000) {
      setInputValueFrom(formatMillisecondsToTime(0));
    } else {
      setInputValueFrom(
        formatMillisecondsToTime(editedEpisodeRange[0] - 0 * 1000),
      );
    }
    refFrom.current = editedEpisodeRange[0];
    refTo.current = editedEpisodeRange[1];
  }, [editedEpisodeRange, activeVideo]);
  const refTo = useRef<number>(0);
  const refFrom = useRef<number>(0);
  useEffect(() => {
    if (inputValueFrom.length >= 5) {
      const fromIsValid = handleBlur(inputValueFrom, undefined, 'from');
      setFromInputStatus(fromIsValid?.changed ? '' : 'error');
    } else {
      if (inputValueFromRef.current.input !== document.activeElement) {
        setFromInputStatus('error');
      }
    }
  }, [inputValueFrom]);
  useEffect(() => {
    if (inputValueTo.length >= 5) {
      const toIsValid = handleBlur(inputValueTo, undefined, 'to');
      setToInputStatus(toIsValid?.changed ? '' : 'error');
    } else {
      if (inputValueToRef.current.input !== document.activeElement) {
        setToInputStatus('error');
      }
    }
  }, [inputValueTo]);
  const {
    setEditedEpisodeFrom,
    setEditedEpisodeTo,
    setEditedFileEpisodeFrom,
    setEditedFileEpisodeTo,
    resetEditMode,
  } = playerReducerV2.actions;

  const handleBlur = (value: string, callback: any, type: string) => {
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    const validatedValue = validateValue(value);
    const time = convertTimeToMilliseconds(validatedValue);
    let offset;
    if (
      playedEpisode?.user_timer !== undefined &&
      playedEpisode?.user_timer !== null
    ) {
      offset =
        playedEpisode?.user_timer?.started_at -
        playedEpisode?.game_timer?.started_at;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
    }
    if (type === 'to') {
      if (isNaN(time)) {
        return {
          changed: false,
          value: refTo.current,
        };
      }
      const msDelta = editedEpisodeRange[1] % 1000;
      if (time === 0 && time >= editedEpisodeRange[0]) {
        if (callback) {
          dispatch(setEditedEpisodeTo(0));
          dispatch(setEditedFileEpisodeTo(offset));
          callback(0);
        }
        return {
          changed: true,
          value: 0,
        };
      }
      if (activeVideo?.duration && time > activeVideo?.duration * 1000) {
        return {
          changed: false,
          value: refTo.current,
        };
      }
      if (time < editedEpisodeRange[0]) {
        return {
          changed: false,
          value: refTo.current,
        };
      }
      if (activeVideo?.duration && time > 0) {
        if (time <= activeVideo?.duration * 1000) {
          if (
            time > editedEpisodeRange[1] &&
            time - editedEpisodeRange[1] > EDIT_VIDEO_WINDOW_WIDTH_MS
          ) {
            return {
              changed: false,
              value: refTo.current,
            };
          }
          if (time < editedEpisodeRange[0]) {
            return {
              changed: false,
              value: refTo.current,
            };
          }
          if (time !== undefined) {
            if (callback) {
              callback(time + msDelta);
              dispatch(setEditedEpisodeTo(time + msDelta));
              dispatch(setEditedFileEpisodeTo(time + offset + msDelta));
            }
            return {
              changed: true,
              value: time + msDelta,
            };
          }
          return {
            changed: false,
            value: refTo.current,
          };
        } else {
          if (
            activeVideo?.duration * 1000 - editedEpisodeRange[1] <=
            EDIT_VIDEO_WINDOW_WIDTH_MS
          ) {
            if (callback) {
              callback(activeVideo?.duration * 1000);
              dispatch(setEditedEpisodeTo(activeVideo?.duration * 1000));
              dispatch(setEditedFileEpisodeTo(activeVideo?.duration * 1000)); // todo ???
            }
            return {
              changed: true,
              value: activeVideo?.duration * 1000,
            };
          } else {
            return {
              changed: false,
              value: refTo.current,
            };
          }
        }
      }
    } else {
      if (isNaN(time)) {
        return {
          changed: false,
          value: refFrom.current,
        };
      }
      const msDelta = editedEpisodeRange[0] % 1000;
      if (time !== null) {
        if (
          time < editedEpisodeRange[0] &&
          editedEpisodeRange[0] - time > EDIT_VIDEO_WINDOW_WIDTH_MS
        ) {
          return {
            changed: false,
            value: refFrom.current,
          };
        }
        if (time <= editedEpisodeRange[1]) {
          if (callback) {
            callback(time + msDelta);
            dispatch(setEditedEpisodeFrom(time + msDelta));
            dispatch(setEditedFileEpisodeFrom(time + offset + msDelta));
          }
          return {
            changed: true,
            value: time + msDelta,
          };
        }
        return {
          changed: false,
          value: refFrom.current,
        };
      }
      if (callback) {
        callback(0);
        dispatch(setEditedEpisodeFrom(0));
        dispatch(setEditedFileEpisodeFrom(offset));
      }
      return {
        changed: true,
        value: 0,
      };
    }
  };
  const { AC_setOpenedPlaylist } = playlistReducerV2.actions;
  const { showNotification } = notificationsReducer.actions;
  const saveChanges = () => {
    const fromIsValid = handleBlur(
      inputValueFromRef?.current?.input.value || '',
      undefined,
      'from',
    );
    const toIsValid = handleBlur(
      inputValueToRef.current?.input.value || '',
      undefined,
      'to',
    );
    setFromInputStatus(fromIsValid?.changed ? '' : 'error');
    setToInputStatus(toIsValid?.changed ? '' : 'error');
    if (fromIsValid?.changed && toIsValid?.changed) {
      if (
        inputValueTo &&
        FINISHED_TIME_REGEX.test(inputValueFrom) &&
        TIME_REGEX.test(inputValueTo) &&
        videosListEditMode &&
        !openedPlaylistId &&
        !openedPlaylist
      ) {
        const updatedVideo = filterAndIndex(
          filteredVideos,
          playedVideoSet?.id,
          'id',
        );
        let editedEpisode;
        let updatedFilteredVideos;
        dispatch(AC_SetAllowPlayFirstAction(false));
        let offset;
        if (
          playedEpisode?.user_timer !== undefined &&
          playedEpisode?.user_timer !== null
        ) {
          offset =
            playedEpisode?.user_timer.started_at -
            playedEpisode?.game_timer.started_at;
        } else {
          offset =
            playedEpisode?.file_timer.started_at -
            playedEpisode?.game_timer.started_at;
        }
        if (playedEpisode && playedEpisode.id.includes('temp-')) {
          editedEpisode = filterAndIndex(
            updatedVideo?.element?.user_episodes,
            playedEpisode.id,
            'id',
          );
          updatedFilteredVideos = [
            ...filteredVideos.slice(0, updatedVideo.index),
            {
              ...updatedVideo.element,
              user_episodes: [
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...updatedVideo?.element.user_episodes?.slice(
                  0,
                  editedEpisode.index,
                ),
                {
                  ...editedEpisode.element,
                  initial_game_timer:
                    editedEpisode.element.initial_game_timer ||
                    editedEpisode.element.game_timer,
                  game_timer: {
                    started_at: fromIsValid.value,
                    finished_at: toIsValid.value,
                  },
                  user_timer: {
                    started_at: fromIsValid.value + offset,
                    finished_at: toIsValid.value + offset,
                  },
                  isTemporary: true,
                },
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...updatedVideo?.element.user_episodes?.slice(
                  editedEpisode.index + 1,
                ),
              ],
            },
            ...filteredVideos.slice(updatedVideo.index + 1),
          ];
        } else {
          const editedEpisode = filterAndIndex(
            updatedVideo?.element?.episodes,
            videosListEditMode,
            'id',
          );
          updatedFilteredVideos = [
            ...filteredVideos.slice(0, updatedVideo.index),
            {
              ...updatedVideo.element,
              episodes: [
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...updatedVideo?.element.episodes?.slice(
                  0,
                  editedEpisode.index,
                ),
                {
                  ...editedEpisode.element,
                  initial_game_timer:
                    editedEpisode.element.initial_game_timer ||
                    editedEpisode.element.game_timer,
                  user_timer: {
                    started_at: fromIsValid.value + offset,
                    finished_at: toIsValid.value + offset,
                  },
                  game_timer: {
                    started_at: fromIsValid.value,
                    finished_at: toIsValid.value,
                  },
                  isTemporary: true,
                },
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...updatedVideo?.element.episodes?.slice(
                  editedEpisode.index + 1,
                ),
              ],
            },
            ...filteredVideos.slice(updatedVideo.index + 1),
          ];
        }
        dispatch(AC_SetFilteredVideosAction(updatedFilteredVideos));
        const editedPlayedEpisode = {
          ...playedEpisode,
          initial_game_timer:
            playedEpisode?.initial_game_timer || playedEpisode?.game_timer,
          game_timer: {
            started_at: fromIsValid.value,
            finished_at: toIsValid.value,
          },
          user_timer: {
            started_at: fromIsValid.value + offset,
            finished_at: toIsValid.value + offset,
          },
        };
        dispatch(resetEditMode());
        dispatch(setPlayedEpsiodeAction(editedPlayedEpisode));
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: t(
                  'Episode was changed, but changes will be removed after closing or updating episodes list. To keep it add the epside to a playlist',
                ),
                callbackName: 'addToPlaylist',
                userEpsiodeId: playedEpisode?.id || '',
              },
            ],
          }),
        );
      } else if (openedPlaylist) {
        const editedPlaylistGame: any = { element: null, index: -1 };
        let found = false;
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const [index, game] of openedPlaylist?.playlist_events.entries()) {
          if (found) {
            break;
          }
          for (const ep of game.episodes) {
            if (ep.id === videosListEditMode) {
              editedPlaylistGame.element = game;
              editedPlaylistGame.index = index;
              found = true;
              break;
            }
          }
        }
        const editedEpisodeGame = filterAndIndex(
          editedPlaylistGame.element?.episodes,
          videosListEditMode,
          'id',
        );
        const updatedPlaylists = {
          ...openedPlaylist,
          playlist_events: [
            ...openedPlaylist.playlist_events.slice(
              0,
              editedPlaylistGame.index,
            ),
            {
              ...editedPlaylistGame.element,
              episodes: [
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...editedPlaylistGame?.element.episodes.slice(
                  0,
                  editedEpisodeGame.index,
                ),
                {
                  ...editedEpisodeGame.element,
                  user_timer: {
                    started_at: fromIsValid.value,
                    finished_at: toIsValid.value,
                  },
                  game_timer: {
                    started_at: fromIsValid.value,
                    finished_at: toIsValid.value,
                  },
                },
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...editedPlaylistGame?.element.episodes.slice(
                  editedEpisodeGame.index + 1,
                ),
              ],
            },
            ...openedPlaylist.playlist_events.slice(
              editedPlaylistGame.index + 1,
            ),
          ],
        };
        // TODO TRANSFER TO IMMER
        dispatch(AC_setOpenedPlaylist(updatedPlaylists));
        const updatedPayload = {
          game_timer: {
            started_at: fromIsValid.value,
            finished_at: toIsValid.value,
          },
          user_timer: {
            started_at: fromIsValid.value,
            finished_at: toIsValid.value,
          },
          file_timer: {
            started_at: fromIsValid.value,
            finished_at: toIsValid.value,
          },
        };
        dispatch(AC_SetAllowPlayFirstAction(false));
        openedPlaylistId &&
          PlaylistsAPI.updatePlaylistElement(
            openedPlaylistId,
            editedEpisodeGame.element.id,
            updatedPayload,
          ).then(() => {
            dispatch(
              showNotification({
                notificationParameters: [
                  {
                    id: generateUUID(),
                    text: t('Episode was edited'),
                    callbackName: 'addPlaylistEpisodeToAnotherPlaylist',
                    userEpsiodeId: editedEpisodeGame.element.id,
                  },
                ],
              }),
            );
          });
      }
    }
    return fromIsValid?.changed && toIsValid?.changed;
  };
  const [fromInputStatus, setFromInputStatus] = useState<InputStatus>();
  const [toInputStatus, setToInputStatus] = useState<InputStatus>();
  const saveButtonRef = useRef(null);
  return (
    <div className="edit-event-controller-wrapper">
      <div
        style={{
          width: 71,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,

              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueFrom}
            ref={inputValueFromRef}
            allowClear={false}
            onFocus={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            }}
            onChange={(event) => {
              handleVideoTimeInputChange(event, setInputValueFrom);
              setFromInputStatus('');
            }}
            onBlur={(event) => {
              if (event.relatedTarget === saveButtonRef?.current) {
                return;
              }
              const isValid = handleBlur(event.target.value, setFrom, 'from');
              if (isValid?.changed) {
                setFromInputStatus('');
              } else {
                if (isValid?.value !== undefined) {
                  setInputValueFrom(initFromTime(isValid?.value));
                }
                setFromInputStatus('error');
              }
            }}
            placeholder=""
            status={fromInputStatus}
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>
      <div
        style={{
          width: 71,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: 'var(--colorTextPlaceholder)',
              fontFamily: 'SFProTextRegular',
              fontSize: 14,
              colorBorder: 'var(--colorBorder)',
              colorText: 'white',
            },
            components: {
              Input: {
                paddingInline: 12,
                colorBgContainer: 'var(--colorBgContainer)',
              },
            },
          }}
        >
          <Input
            pattern={'^([0-9]{1,3}){1}:([0-9]{2}){1}$'}
            value={inputValueTo}
            allowClear={false}
            ref={inputValueToRef}
            onFocus={() => {
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            }}
            onChange={(event) => {
              handleVideoTimeInputChange(event, setInputValueTo);
              setToInputStatus('');
            }}
            onBlur={(event) => {
              if (event.relatedTarget === saveButtonRef?.current) {
                return;
              }
              const isValid = handleBlur(event.target.value, setTo, 'to');
              if (isValid?.changed) {
                setToInputStatus('');
              } else {
                if (isValid?.value !== undefined) {
                  setInputValueTo(initFromTime(isValid?.value));
                }
                setToInputStatus('error');
              }
            }}
            status={toInputStatus}
            placeholder=""
            style={{ width: '71px' }}
          />
        </ConfigProvider>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorBgContainer)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              lineHeight: 22,
            },
          },
        }}
      >
        <Button
          style={{ padding: '0px 16px 0px 16px' }}
          type="text"
          onClick={() => {
            dispatch(resetEditMode());
          }}
        >
          {t('Cancel ss')}
        </Button>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBg: 'var(--colorPrimaryBase)',
              colorText: 'var(--white)',
              colorBorder: 'var(--colorBorder)',
              defaultHoverBg: 'var(--colorBgContainer)',
              defaultActiveBg: 'var(--colorBgContainer)',
              paddingBlockLG: 16,
            },
          },
        }}
      >
        <Button
          ref={saveButtonRef}
          onClick={() => {
            const saved = saveChanges();
            if (saved) {
              dispatch(resetEditMode());
              dispatch(AC_SetVisibleRangeAction([]));
            }
          }}
          style={{ padding: '0px 16px 0px 16px' }}
          type="primary"
        >
          {openedPlaylistId ? t('Save') : t('Apply a')}
        </Button>
      </ConfigProvider>
    </div>
  );
};
export default EditEpisodePlayerController;
