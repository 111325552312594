import React, { FC } from 'react';

import { Button, ConfigProvider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExclamationCircle } from '../../../assets/img/icons/faExclamationCircle.svg';

interface Interface {
  isOpen: boolean;
  closeCallable: any;
  handleOk: any;
}
const DeletePlaylistModal: FC<Interface> = ({
  isOpen,
  closeCallable,
  handleOk,
}) => {
  const [t] = useTranslation();
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'var(--colorBgLayout)',
            headerBg: 'var(--colorBgLayout)',
            titleColor: 'var(--colorText)',
            colorText: 'var(--colorText)',
            paddingContentHorizontal: 12,
            borderRadiusLG: 16,
          },
        },
      }}
    >
      <Modal
        width={400}
        closeIcon={null}
        style={{ height: 188, padding: 12 }}
        open={isOpen}
        onOk={handleOk}
        onCancel={closeCallable}
        footer={[
          <div className="flex-row f-ga-8 mt32">
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: 'var(--colorBgContainer)',
                    colorText: 'var(--colorText)',
                    colorBorder: 'var(--colorBorder)',
                    defaultHoverBg: 'var(--colorBgContainer)',
                    defaultActiveBg: 'var(--colorBgContainer)',
                  },
                },
              }}
            >
              <Button
                style={{ flex: 1 }}
                type="default"
                onClick={closeCallable}
              >
                {t('Cancel')}
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorTextDisabled: 'var(--colorTextDisabled)',
                    borderColorDisabled: 'var(--colorBorder)',
                  },
                },
              }}
            >
              <Button style={{ flex: 1 }} type="primary" onClick={handleOk}>
                {t('Delete')}
              </Button>
            </ConfigProvider>
          </div>,
        ]}
      >
        <div className="flex-row f-ga-16">
          <div>
            <ExclamationCircle />
          </div>
          <div className="flex-column f-ga-8">
            <div className="modal-title-dark">{t('Delete playlist?')}</div>
            <div className="modal-content-dark">
              {t('Are you sure you want to delete this playlist?')}
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default DeletePlaylistModal;
