import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { useAppDispatch } from 'store';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisV.svg';

interface Interface {
  renameCallback: any;
  deleteCallback: any;
  downloadCallback: any;
  folder: any;
  shareCallback: any;
  moveToFolderCallback: any;
}
const FolderContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div
          onClick={() => {
            // console.log('call ', props.folder)
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            props.renameCallback(props.folder.elId);
          }}
        >
          {t('Rename')}
        </div>
      ),
    },
    // {key: 2, label: (<div onClick={() => props.downloadCallback(props.folder.elId)}>{t('Download')}</div>), disabled: true},
    // {key: 3, label: (<div onClick={() => props.shareCallback("create")}>{t('Share')}</div>), disabled: true},
    {
      key: 4,
      label: (
        <div
          onClick={() => {
            props.moveToFolderCallback(props.folder.elId);
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
          }}
        >
          {t('Move to folder')}
        </div>
      ),
    },
    {
      key: 5,
      label: (
        <div
          onClick={() => {
            // console.log('call ', props.folder.elId)
            props.deleteCallback(props.folder.elId);
          }}
        >
          {t('Delete')}
        </div>
      ),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{ width: 'max-content' }}
        placement="bottomRight"
      >
        <ElipsisVertical width={24} />
      </Dropdown>
    </ConfigProvider>
  );
};
export default FolderContext;
