import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisV.svg';
interface Interface {
  shareSelectedEpisodesCallback: any;
  addSelectedToPlaylistCallback: any;
  isIndeterminate: boolean;
}
const EpisodesNavbarContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div onClick={() => props.shareSelectedEpisodesCallback()}>
          {props.isIndeterminate ? t('Share selected') : t('Share')}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div onClick={() => props.addSelectedToPlaylistCallback()}>
          {props.isIndeterminate
            ? t('Add selected to playlist')
            : t('Add to playlist')}
        </div>
      ),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{ width: 'max-content' }}
        placement="bottomRight"
      >
        <ElipsisVertical width={24} />
      </Dropdown>
    </ConfigProvider>
  );
};
export default EpisodesNavbarContext;
