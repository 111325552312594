import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisV.svg';
interface Interface {
  renameCallback: any;
  shareCallback: any;
  deleteCallback: any;
  reorderByTimeCallback: any;
  moveToFolderCallback: any;
  playlist: any;
  deleteSelectedFromPlaylist: any;
  hasSelected: boolean;
}
const PlaylistViewContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div onClick={() => props.shareCallback(props.playlist.id)}>
          {t('Share')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 2,
      label: (
        <div onClick={() => props.renameCallback(props.playlist.id)}>
          {t('Rename')}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div onClick={() => props.reorderByTimeCallback(props.playlist.id)}>
          {t('Reorder by time')}
        </div>
      ),
      disabled: true,
    },
    {
      key: 4,
      label: (
        <div onClick={() => props.moveToFolderCallback(props.playlist.id)}>
          {t('Move to folder')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div onClick={() => props.deleteCallback(props.playlist.id)}>
          {t('Delete')}
        </div>
      ),
      disabled: true,
    },
    {
      key: 5,
      label: (
        <div onClick={() => props.deleteSelectedFromPlaylist()}>
          {t('Delete selected from playlist')}
        </div>
      ),
      disabled: !props.hasSelected,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
          // lineHeight: 16
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['click']}
        overlayStyle={{
          width: 'max-content',
        }}
        placement="bottomRight"
      >
        <ElipsisVertical width={20} height={16} />
      </Dropdown>
    </ConfigProvider>
  );
};
export default PlaylistViewContext;
