import React, { FC, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  AC_SetPlayerCommandAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { ReactComponent as Brush } from 'assets/img/icons/faBrushIcon.svg';
import { ReactComponent as Reels } from 'assets/img/icons/faReelsIcon.svg';
import { ReactComponent as Scissors } from 'assets/img/icons/faScisorsIcon.svg';
import EditorIconContext from 'components/contexts/EditorIconContext';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import { PlayCommandType } from 'types/types';
import { initTimer } from 'utils/functions';
import './index.css';

export const FloatingButtons: FC<any> = ({
  isNarrowScreenMode,
  wrapperRef,
  playerState,
}: {
  isNarrowScreenMode: any;
  wrapperRef: any;
  playerState: any;
}) => {
  const [episodeEditorContextOpen, setEpisodeEditorContextOpen] =
    useState(false);
  const { activeVideo } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );

  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { toggleReelsMode, resetReelsState } = reelsReducer.actions;
  const { playedEpisode, createEpisodeMode, videosListEditMode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { isLoading } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const dispatch = useAppDispatch();
  const getClassName = () => {
    const baseClassSet = 'flex-row f-ga-8 flex-end floating-buttons-wrapper';
    return `${baseClassSet} ${activeVideo && isNarrowScreenMode && wrapperRef?.current ? 'down' : 'up'}`;
  };
  const { reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const {
    setEditedEpisodeRange,
    setEditedEpisodeFileRange,
    setVideoListEditMode,
    resetEditMode,
  } = playerReducerV2.actions;
  const turnEditMode = () => {
    const episode = playedEpisode;
    if (episode && episode.episode_type !== 'period') {
      const eventId = episode.id;
      if (!videosListEditMode) {
        const timerStart = initTimer(episode, 'started_at', true, false);
        const timerFinish = initTimer(episode, 'finished_at', true, false);
        const range = [timerStart, timerFinish];

        const fileRange = [
          initTimer(episode, 'started_at', false, true),
          initTimer(episode, 'finished_at', false, true),
        ];
        dispatch(setEditedEpisodeRange(range));
        dispatch(setEditedEpisodeFileRange(fileRange));
        dispatch(setVideoListEditMode(eventId));
        dispatch(AC_SetPlayerCommandAction(PlayCommandType.scrollTo));
      }
    }
  };

  return (
    <div className={getClassName()}>
      <div
        className={`flex-row f-ga-8 flex-end ${isNarrowScreenMode ? 'floating-buttons' : 'w100 '}`}
        style={{ zIndex: 20 }}
      >
        {playedEpisode?.episode_type !== 'period' &&
          currentUser?.custom_permissions.includes('can_cut_reels') && (
            <Reels
              width={30}
              height={30}
              style={{ padding: 5 }}
              onClick={() => {
                if (playerState && !isLoading) {
                  dispatch(toggleReelsMode());
                  if (reelsModeOn) {
                    dispatch(resetEditMode());
                    dispatch(AC_SetVisibleRangeAction([]));
                    dispatch(resetReelsState());
                  } else {
                    turnEditMode();
                  }
                  if (createEpisodeMode) {
                    dispatch(setCreateEpisodeMode(false));
                  }
                }
              }}
              className={`enabledClickable ${reelsModeOn ? 'active-video-action' : ''}`}
            />
          )}

        <EditorIconContext
          changeEpsiodeTimelineCallback={() => turnEditMode()}
          setOpenContext={setEpisodeEditorContextOpen}
        >
          <Scissors
            width={30}
            height={30}
            style={{ padding: 5 }}
            className={`enabledClickable ${episodeEditorContextOpen ? 'active-video-action' : ''}`}
          />
        </EditorIconContext>
        <Brush
          width={30}
          height={30}
          style={{ padding: 5 }}
          className="enabledClickable"
        />
      </div>
    </div>
  );
};
