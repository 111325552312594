import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_setIntermediateActivitiesAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
} from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { ClubsAPI } from 'api/clubs';
import ContentContainerV2 from 'components/ContentContainerV2';
import PlayerPageController from 'components/PlayerPageController';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

const PlayerPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  const dispatch = useAppDispatch();
  const { resetReelsState } = reelsReducer.actions;
  const { playerId } = useParams();
  const { setOpenedPlayer } = teamPlayerReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setTeamPlayerLoading } = teamPlayerReducer.actions;
  const { setStatsCustomGameList } = teamReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;

  useEffect(() => {
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetReelsState());
    dispatch(AC_SetPlayerLoadingAction(false));
    dispatch(setStyleModeAction('light'));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
    dispatch(setOpenedPlayer(null));
    dispatch(AC_setIntermediateActivitiesAction([]));
    window.document.body.setAttribute('data-theme', 'light');
    dispatch(AC_SetPlayerLoadingAction(false));
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  useEffect(() => {
    if (playerId) {
      dispatch(setTeamPlayerLoading(true));
      ClubsAPI.getPlayer(playerId).then((response) => {
        dispatch(setOpenedPlayer(response.data));
        dispatch(setTeamPlayerLoading(false));
      });
    }
  }, [playerId]);
  return (
    <ContentContainerV2
      pageTitle={''}
      page={'playerProfile'}
      style={{
        padding: '0 32px',
      }}
      showNavigationPanel={showNavigationPanel}
    >
      <PlayerPageController />
    </ContentContainerV2>
  );
};
export default PlayerPage;
