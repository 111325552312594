import React, { useEffect, useState } from 'react';

import { GetCurrentUserAction } from 'actions/auth.actions';
import { AC_OpenFilterAction } from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
} from 'actions/player.acitons';
import ContentContainerV2 from 'components/ContentContainerV2';
import { TeamPageController } from 'components/TeamPageController';
import TeamHeaderBlock from 'components/TeamPageController/TeamHeaderBlock';
import { useAppDispatch } from 'store';
import { STYLE_TYPES } from 'types/constants';

const TeamPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(AC_OpenFilterAction(''));
    dispatch(setStyleModeAction(STYLE_TYPES.light));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(AC_SetPlayerLoadingAction(false));
  }, []);
  return (
    <>
      <ContentContainerV2
        pageTitle={''}
        page={'teamProfile'}
        showNavigationPanel={showNavigationPanel}
        style={{ padding: '0 0' }}
      >
        <TeamHeaderBlock />
        <TeamPageController />
      </ContentContainerV2>
    </>
  );
};
export default TeamPage;
