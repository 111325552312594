import React, { FC } from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TriangleExclamation } from 'assets/img/icons/faTriangleExclamation.svg';

import './index.css';

const ReelsLengthWarning: FC<any> = () => {
  const [t] = useTranslation();
  return (
    <Tooltip
      color="var(--colorBgLayout)"
      title={t('Total selected Reels duration exceeds 60 seconds')}
      placement="bottom"
      arrow={false}
      overlayClassName="custom-tooltip-dark"
    >
      <TriangleExclamation style={{ display: 'block', margin: '4 2 4 2' }} />
    </Tooltip>
  );
};
export default ReelsLengthWarning;
