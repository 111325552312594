import React from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { useAppDispatch } from 'store';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisV.svg';

const PlaylistContext = ({
  playlist,
  openRenameMode,
  movePlayToFolderCallback,
  downloadCallback,
  deleteCallback,
  shareCallback,
}: {
  playlist: any;
  openRenameMode: any;
  movePlayToFolderCallback: any;
  downloadCallback: any;
  deleteCallback: any;
  shareCallback: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div
          onClick={() => {
            openRenameMode(true);
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
          }}
        >
          {t('Rename')}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div onClick={() => downloadCallback(playlist.elId)}>
          {t('Download')}
        </div>
      ),
      disabled: true,
    },
    {
      key: 4,
      label: (
        <div onClick={() => shareCallback(playlist.elId)}>{t('Share')}</div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div onClick={() => movePlayToFolderCallback(playlist.elId)}>
          {t('Move to folder')}
        </div>
      ),
    },
    {
      key: 6,
      label: (
        <div onClick={() => deleteCallback(playlist.elId)}>{t('Delete')}</div>
      ),
    },
  ];
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <Dropdown
          className="enabledClickable"
          menu={{ items }}
          trigger={['click']}
          overlayStyle={{ width: 'max-content' }}
          placement="bottomRight"
        >
          <ElipsisVertical width={24} />
        </Dropdown>
      </ConfigProvider>
    </>
  );
};
export default PlaylistContext;
