/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import './index.css';

import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import { initTimer } from 'utils/functions';
import { Rnd } from 'utils/rnd-local';

import { useReelHandler } from '../hooks';

const ReelsScreenRecorderV3 = ({ playerState }: { playerState: any }) => {
  const initContainerHeight = () => {
    const parentNode = document.querySelectorAll('video')[0];
    if (parentNode) {
      const videoConrainerNode: any = parentNode.childNodes[0];
      return parentNode.getBoundingClientRect().height;
    }
    return 0;
  };
  const { wideScreenMode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const initContainerWidth = () => {
    const parentNode = document.querySelectorAll('.videoPlayerWrapper')[0];
    if (parentNode) {
      const videoConrainerNode: any = parentNode.childNodes[0];
      return videoConrainerNode.getBoundingClientRect().width;
    }
    return 0;
  };
  const resRef = useRef<any>(null);
  const [containerHeight, setContainerHeight] = useState(initContainerHeight());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [containerWidth, setContainerWidth] = useState(initContainerWidth());
  const [reelsWidth, setReelsWidth] = useState(0);
  const [reelsHeight, setReelsHeight] = useState(0);
  const [reelsPosition, setReelsPosition] = useState({ x: 0, y: 0 });
  const { isPlaying, editedFileEpisodeRange } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { playedEpisode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { keyframes, reelEditorPosition, addKeyframesIsEnabled } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { setVideoDimentions } = playerReducerV2.actions;
  const {
    updateKeyframe,
    insertKeyFrame,
    setReelEditorPosition,
    setKeyframes,
  } = reelsReducer.actions;
  const setReelsDimesions = (height: number, width: number) => {
    setReelsWidth(width);
    setReelsHeight(height);
  };
  const reelsAnimationHanlder = useReelHandler({
    keyframes: keyframes,
    setReelWindowPosition: setReelsPosition,
    setReelsDimesions: setReelsDimesions,
    isPlaying: isPlaying,
    currentPlayerState: playerState,
    currentVideoScreenWidth: containerWidth,
    currentVideoScreenHeight: containerHeight,
    reelEditorPosition: reelEditorPosition,
  });
  const constructFrame = ({
    height,
    width,
    x,
    y,
    time,
    contHeight,
    contWidth,
  }: {
    height: number;
    width: number;
    x: number;
    y: number;
    contHeight: number;
    contWidth: number;
    time?: number;
  }) => {
    const heightRatio = height / contHeight < 1 ? height / contHeight : 1;
    const widthRatio = width / contWidth < 1 ? width / contWidth : 1;
    return {
      x: x,
      y: y,
      xRatio: x / contWidth,
      yRatio: y / contHeight,
      width: width,
      time: time || playerState.playedSeconds * 1000,
      height: height,
      heightRatio: heightRatio,
      widthRatio: widthRatio,
    };
  };
  useEffect(() => {
    const x = async () => {
      if (editedFileEpisodeRange.length > 0) {
        const localContainerHeight = initContainerHeight();
        const localContainerWidth = initContainerWidth();
        await dispatch(
          setVideoDimentions({
            height: localContainerHeight,
            width: localContainerWidth,
          }),
        );
        let frame;
        if (playedEpisode?.keyframes?.length) {
          frame = playedEpisode.keyframes[0];
          await dispatch(setKeyframes(playedEpisode.keyframes));
        } else {
          frame = constructFrame({
            height: localContainerHeight,
            width: (localContainerHeight / 16) * 9,
            x: localContainerWidth / 2 - ((localContainerHeight / 16) * 9) / 2,
            y: 0,
            time: editedFileEpisodeRange[0] | 0,
            contHeight: localContainerHeight,
            contWidth: localContainerWidth,
          });
          await dispatch(setKeyframes([frame]));
        }
        await dispatch(setReelEditorPosition(frame));
        setReelsPosition({
          x: frame.x,
          y: frame.y,
        });
        setReelsDimesions(frame.height, frame.width);
      } else {
        let frame;
        if (playedEpisode?.keyframes?.length) {
          frame = playedEpisode.keyframes[0];
          await dispatch(setKeyframes(playedEpisode.keyframes));
        } else {
          frame = constructFrame({
            height: initContainerHeight(),
            width: (initContainerHeight() / 16) * 9,
            x:
              initContainerWidth() / 2 - ((initContainerHeight() / 16) * 9) / 2,
            y: 0,
            time: initTimer(playedEpisode, 'started_at', false, true) || 0,
            contHeight: initContainerHeight(),
            contWidth: initContainerWidth(),
          });
        }
        setReelsPosition({
          x: initContainerWidth() / 2 - ((initContainerHeight() / 16) * 9) / 2,
          y: 0,
        });
        await dispatch(setReelEditorPosition(frame));
        if (keyframes.length === 0) {
          dispatch(insertKeyFrame(frame));
        }
        setReelsWidth((initContainerHeight() / 16) * 9);
        setReelsHeight(initContainerHeight());
      }
    };
    const t = setTimeout(() => x().then(), 500);
    return () => clearTimeout(t);
  }, []);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const x = () => {
      const ch = initContainerHeight();
      const cw = initContainerWidth();
      setContainerHeight(ch);
      setContainerWidth(cw);
      dispatch(
        setVideoDimentions({
          height: ch,
          width: cw,
        }),
      );
      if (reelEditorPosition?.heightRatio !== undefined) {
        const h = ch * reelEditorPosition?.heightRatio;
        const w = cw * reelEditorPosition?.widthRatio;
        const x = reelEditorPosition?.xRatio * cw;
        const y = reelEditorPosition?.yRatio * ch;
        setReelsWidth(w);
        setReelsHeight(h);
        resRef.current?.updateSize({ height: h, width: w });
        resRef.current?.updatePosition({ x: x, y: y });
        setReelsPosition({ x: x, y: y });
        resRef.current?.setState({ maxHeight: ch });
      }
    };
    const t = setTimeout(() => x(), 500);
    return () => clearTimeout(t);
  }, [wideScreenMode]);
  useEffect(() => {
    // UPDATE ON PLAYBACK
    reelsAnimationHanlder.monitorPlayback();
  }, [playerState?.playedSeconds]);
  const stopResizeHandler = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: any,
  ) => {
    e.stopPropagation();
    const h = initContainerHeight();
    const w = initContainerWidth();
    if (
      delta.height + (reelsHeight - h) <= h &&
      delta.width + (reelsWidth - w) <= w &&
      ref.offsetHeight >= h / 2 &&
      ref.offsetHeight <= h
    ) {
      setReelsHeight(ref.offsetHeight);
      setReelsWidth(ref.offsetWidth);
      const frame = constructFrame({
        height: ref.offsetHeight,
        width: ref.offsetWidth,
        x: position.x,
        y: position.y,
        contHeight: h,
        contWidth: w,
      });
      dispatch(setReelEditorPosition(frame));
      if (addKeyframesIsEnabled) {
        const matchingKeyFrames = keyframes.filter(
          (keyframe) => keyframe.time === playerState.playedSeconds * 1000,
        );
        if (matchingKeyFrames.length > 0) {
          dispatch(updateKeyframe(frame));
        } else {
          dispatch(insertKeyFrame(frame));
        }
      }
    } else {
      setReelsHeight(containerHeight / 2);
      setReelsWidth((containerHeight / 2 / 16) * 9);
      e.preventDefault();
    }
    setReelsPosition({
      x: position.x,
      y: position.y,
    });
  };
  const stopDraggingHanlde = (e: any, info: any) => {
    e.stopPropagation();
    const frame = constructFrame({
      height: reelsHeight,
      width: reelsWidth,
      x: info.x,
      y: info.y,
      contHeight: initContainerHeight(),
      contWidth: initContainerWidth(),
    });
    if (addKeyframesIsEnabled) {
      const matchingKeyFrames = keyframes.filter(
        (keyframe) => keyframe.time === playerState.playedSeconds * 1000,
      );
      if (matchingKeyFrames.length > 0) {
        dispatch(updateKeyframe(frame));
      } else {
        dispatch(insertKeyFrame(frame));
      }
    }
    dispatch(setReelEditorPosition(frame));
    setReelsPosition({ x: info.x, y: info.y });
  };
  return (
    <>
      {reelsWidth ? (
        <Rnd
          style={{
            position: 'absolute',
            height: '100%',
            border: '1px solid #F0F0F0',
            cursor: 'move',
            zIndex: '100',
            boxShadow: '0 0 0 99999px rgba(0, 0, 0, .5)',
          }}
          default={{
            x: reelsPosition.x,
            y: reelsPosition.y,
            width: reelsWidth,
            height: reelsHeight,
          }}
          size={{
            width: reelsWidth,
            height: reelsHeight,
          }}
          position={{
            x: reelsPosition.x,
            y: reelsPosition.y,
          }}
          onResizeStop={stopResizeHandler}
          onDragStop={stopDraggingHanlde}
          ref={resRef}
          bounds={'video'}
          resizeHandleClasses={{
            bottom: 'handle-s',
            bottomLeft: 'handle handle-sw',
            bottomRight: 'handle handle-se',
            left: 'handle-w',
            right: 'handle-e',
            top: 'handle-n',
            topLeft: 'handle handle-nw',
            topRight: 'handle handle-ne',
          }}
          lockAspectRatio={true}
          minHeight={containerHeight / 2}
          maxHeight={containerHeight}
          minWidth={(containerHeight / 2 / 16) * 9}
          scale={1}
          enableResizing={{
            bottom: false,
            bottomLeft: true,
            bottomRight: true,
            left: false,
            right: false,
            top: false,
            topLeft: true,
            topRight: true,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ReelsScreenRecorderV3;
