import React, { FC, useEffect, useState } from 'react';

import { ConfigProvider, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';

import { AC_OpenFilterAction } from '../../../actions/filter.actions';
import {
  AC_SetIsPlayingVideoAction,
  AC_setPlayerMode,
  AC_SetVideoTimeZoomAction,
} from '../../../actions/player.acitons';
import { ReactComponent as CollapseButtonGray } from '../../../assets/img/icons/faArrowsRight.svg';
import { ReactComponent as FilmLight } from '../../../assets/img/icons/faFilmLight.svg';
import { ReactComponent as StarLight } from '../../../assets/img/icons/faStarLight.svg';
import { AppStateType } from '../../../reducers';
import './index.css';
import { playlistReducerV2 } from '../../../reducers/playlist.reducer';
import { useAppDispatch } from '../../../store';
import { VIDEO_TIME_MAX_ZOOM } from '../../../types/constants';
import { MenuItemType } from '../../../types/types';
import { getItem } from '../../../utils/functions';
import { MODES } from '../index';

interface Interface {
  toggleWideScreen: any;
  playerMode: string;
  setHasNewPlaylists: any;
  setSelectedEpisodes: any;
  setSelectedPlaylistEpisodes: any;
  modesRef: any;
  isShared: boolean;
  isWideScreenMode: boolean;
}

const VerticalListNavigationController: FC<Interface> = ({
  toggleWideScreen,
  playerMode,
  setHasNewPlaylists,
  // setSelectedEpisodes,
  setSelectedPlaylistEpisodes,
  // modesRef,
  isShared,
  isWideScreenMode,
}) => {
  const { activeVideo } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const dispatch = useAppDispatch();
  const { AC_setOpenedPlaylist, AC_setOpenedPlaylistId } =
    playlistReducerV2.actions;
  const { resetEditMode } = playerReducerV2.actions;
  const changeMode = (mode: string) => {
    if (mode !== playerMode) {
      dispatch(AC_setPlayerMode(mode));
      if (mode === MODES.playlists) {
        setHasNewPlaylists(false);
        dispatch(AC_OpenFilterAction(''));
        dispatch(AC_setOpenedPlaylistId(null));
        dispatch(AC_setOpenedPlaylist(null));
      } else if (mode === MODES.episodes) {
        dispatch(AC_setOpenedPlaylist(null));
        dispatch(AC_setOpenedPlaylistId(null));
      }
      setSelectedPlaylistEpisodes({});
      dispatch(resetEditMode());
      dispatch(AC_SetIsPlayingVideoAction(false));
      activeVideo &&
        dispatch(AC_SetVideoTimeZoomAction(VIDEO_TIME_MAX_ZOOM / 2));
    }
  };
  const navigate = useNavigate();
  const { resetReelsState } = reelsReducer.actions;
  const items: MenuItemType[] = [
    getItem(
      'episodes',
      'episodes',
      <FilmLight
        fill="white"
        height={39}
        fillOpacity={`${playerMode === MODES.episodes ? 1 : 0.45}`}
      />,
      () => {
        changeMode(MODES.episodes);
        dispatch(resetReelsState());
        navigate('/player', { replace: true });
      },
      'icon',
      null,
      { marginBottom: 16 },
    ),
    getItem(
      'playlists',
      'playlists',
      <StarLight
        fill="white"
        width={21}
        height={37}
        fillOpacity={`${playerMode === MODES.playlists || playerMode === MODES.playlist_view ? 1 : 0.45}`}
      />,
      () => {
        changeMode(MODES.playlists);
        dispatch(resetReelsState());
        navigate('/player/playlists', { replace: true });
      },
      'icon',
      null,
      { marginBottom: 16 },
    ),
  ];
  const [selectedMenuItems, setSelectedMenuItems] = useState<string[]>([]);
  useEffect(() => {
    if (playerMode === MODES.playlists || playerMode === MODES.playlist_view) {
      setSelectedMenuItems([MODES.playlists]);
    } else {
      setSelectedMenuItems([MODES.episodes]);
    }
  }, [playerMode]);
  const { reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  return (
    <div className={'playerFilterVerticalController'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '40px' }}>
          <div
            className="flex-row ai-c j-ce"
            style={{ height: 40, marginBottom: 16 }}
          >
            <CollapseButtonGray
              className={reelsModeOn ? 'disabledClickable' : 'enabledClickable'}
              style={{
                transform: isWideScreenMode
                  ? 'rotate(-180deg)'
                  : 'rotate(0deg)',
                // marginLeft: 13, marginBottom: 16
              }}
              onClick={() => {
                if (!reelsModeOn) {
                  {
                    dispatch(AC_OpenFilterAction(''));
                    toggleWideScreen((prev: boolean) => !prev);
                  }
                }
              }}
              fill={'white'}
              fillOpacity={'0.45'}
            />
          </div>
          {!isShared && (
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    iconSize: 20,
                    collapsedIconSize: 20,
                    itemMarginInline: 0,
                    itemPaddingInline: 10,
                    darkItemBg: 'vat(colorBgElevated)',
                    itemMarginBlock: 0,
                    itemHeight: 40,
                    itemSelectedBg: 'var(colorPrimaryActive)',
                  },
                },
              }}
            >
              <Menu
                style={{ width: '100%', borderRadius: 8 }}
                selectedKeys={selectedMenuItems}
                defaultSelectedKeys={['episodes']}
                items={items}
                theme="dark"
                mode="vertical"
                inlineCollapsed={true}
                itemType="icon"
              />
            </ConfigProvider>
          )}
        </div>
      </div>
    </div>
  );
};
export default VerticalListNavigationController;
