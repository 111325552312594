import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Divider, Flex, Segmented, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';

import EventsCloud from './EventsCloud';
import { ReactComponent as FavoritesIcon } from '../../../assets/img/icons/faStarWithPlusDark.svg';

import './index.css';

const PlayerInfoBlock = () => {
  const { openedTeamPlayer, topPlayerRole, teamPlayerLoading } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastXEvents, setLastXEvents] = useState<any>([]);
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getFlagEmoji(countryCode: string): string {
    // return String.fromCodePoint(...codePoints);
    return countryCode
      .toUpperCase()
      .split('')
      .map((char: string) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
      .join('');
  }
  const handleAddToFavorite = () => {
    console.log('handleAddToFavorite');
  };
  const [years, setYears] = useState(0);
  useEffect(() => {
    if (openedTeamPlayer && metricsRegistry.length) {
      setYears(dayjs().diff(dayjs(openedTeamPlayer?.birthday), 'year'));
      EventsAPI.getPlayerEventsCloud(openedTeamPlayer.id, 5).then(
        (response) => {
          setTimeout(() => setLastXEvents(response.data), 1000);
        },
      );
    }
  }, [openedTeamPlayer, metricsRegistry]);
  const translateYears = () => {
    if (years === 0 || (years > 10 && years.toString().endsWith('1'))) {
      return t('year');
    }
    if ([10, 11, 12, 13, 14].includes(years)) {
      return t('years_many');
    }
    if (
      years.toString().endsWith('2') ||
      years.toString().endsWith('3') ||
      years.toString().endsWith('4')
    ) {
      return t('years_two');
    }
    return t('years_many');
  };
  const navigate = useNavigate();
  const imgRef = useRef<any>(null);
  return (
    <Flex vertical style={{ width: '100%', height: 196 }}>
      <Flex>
        <Flex justify={'space-between'} style={{ width: '100%', marginTop: 8 }}>
          <Flex gap={4} flex={1}>
            <Flex gap={21}>
              <Flex
                align="center"
                justify="center"
                style={{
                  width: 88,
                  height: 88,
                }}
              >
                <img
                  style={{
                    height: '100%',
                  }}
                  src={
                    teamPlayerLoading || !openedTeamPlayer?.photo
                      ? 'https://dev.sota.id/files/player/player_icon.png'
                      : openedTeamPlayer?.photo
                  }
                />
              </Flex>
              <Flex vertical style={{ marginTop: 4, marginBottom: 12 }}>
                <Flex gap={8} align="center">
                  <div style={{ fontSize: 20 }} className="flag-icon">
                    {openedTeamPlayer?.country ? (
                      openedTeamPlayer.country.flag_image ? (
                        <img
                          width={16}
                          height={12}
                          src={openedTeamPlayer.country.flag_image}
                          alt=""
                        />
                      ) : (
                        getFlagEmoji(openedTeamPlayer?.country?.code)
                      )
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: '&nbsp;',
                        }}
                      />
                    )}
                  </div>
                  <div className="team-player-country-code">
                    {openedTeamPlayer?.country?.code}
                  </div>
                </Flex>
                <div className="team-player-name">
                  {openedTeamPlayer?.first_name} {openedTeamPlayer?.last_name}
                </div>
                <div
                  className="team-player-team-name enabledClickable"
                  onClick={() => navigate(`/teams/${openedTeamPlayer.team.id}`)}
                >
                  {openedTeamPlayer?.team.name}
                </div>
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={4} align="center" justify="center" flex={1}>
            <ConfigProvider
              theme={{
                components: {
                  Segmented: {
                    trackBg: 'var(--segmentedGroupBg)',
                    itemColor: 'red',
                  },
                },
              }}
            >
              <Segmented
                style={{ width: '422px' }}
                size="large"
                color="black"
                block
                options={[t('Overview'), t('Episodes search')]}
                onChange={(value) => {
                  console.log(value); // string
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex gap={4} flex={1} align="center" justify={'flex-end'}>
            <Tooltip title={t('Add to favorites')}>
              <FavoritesIcon
                className="enabledClickable"
                onClick={() => handleAddToFavorite()}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex className="team-player-stats-container">
          <Flex vertical style={{ padding: '8px 0' }} className="f-grow f-ga-4">
            <span
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Birthday')}: </span>
              {openedTeamPlayer?.birthday ? (
                dayjs(openedTeamPlayer?.birthday).format('DD.MM.YYYY')
              ) : (
                <></>
              )}
            </span>
            <span>
              <span
                className={
                  openedTeamPlayer?.birthday ? '' : 'no-data-span-label'
                }
                style={{ fontSize: 16 }}
              >
                <span className="span-label">{t('Age')}: </span>
                {years ? (
                  `${years} ${translateYears()}`
                ) : (
                  <span className="no-data-span-label">
                    {t('No data about age')}
                  </span>
                )}
              </span>
            </span>
          </Flex>
          <Divider
            type="vertical"
            style={{
              height: 46,
              margin: 0,
            }}
          />
          <Flex
            vertical
            style={{ padding: '8px 0', fontSize: 16 }}
            className="f-grow f-ga-4"
          >
            <Flex align="center" gap={4}>
              <span className="span-label">{t('Position')}:</span>
              {topPlayerRole ? (
                <div className="capitalize" style={{ fontSize: 16 }}>
                  {topPlayerRole.map((role: string) => t(role)).join(', ')}
                </div>
              ) : (
                <span className="no-data-span-label"> {t('no data')}</span>
              )}
            </Flex>
            <span>
              <span className="span-label">{t('Leg')}: </span>
              {openedTeamPlayer?.main_leg &&
              openedTeamPlayer.main_leg !== 'unset' ? (
                <span className="capitalize" style={{ fontSize: 16 }}>
                  {t(`${openedTeamPlayer.main_leg} leg`)}
                </span>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </span>
          </Flex>
          <Divider
            type="vertical"
            style={{
              height: 46,
              margin: 0,
            }}
          />
          <Flex
            vertical
            style={{ padding: '8px 0', fontSize: 16 }}
            className="f-grow f-ga-4"
          >
            <span>
              <span className="span-label">{t('Height')}: </span>
              {openedTeamPlayer?.height ? (
                `${openedTeamPlayer?.height} ${t('cm')}`
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </span>
            <span>
              <span className="span-label">{t('Weight')}: </span>
              {openedTeamPlayer?.weight ? (
                `${openedTeamPlayer?.weight} ${t('kg')}`
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </span>
          </Flex>
          <Divider
            type="vertical"
            style={{
              height: 46,
              margin: 0,
            }}
          />
          <Flex vertical style={{ padding: '8px 0' }} className="f-grow f-ga-4">
            <span>
              <span className="span-label">{t('National team')}: </span>
              {openedTeamPlayer !== null &&
              openedTeamPlayer?.national_team &&
              openedTeamPlayer.national_team.id !== 52 ? (
                <span>{openedTeamPlayer?.national_team?.name}</span>
              ) : openedTeamPlayer?.national_team?.id === 52 ? (
                <span style={{ fontSize: 16 }}>{t('not called up')}</span>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </span>
            <span>
              <span className="span-label">{t('Contract expires')}: </span>
              {openedTeamPlayer?.contract_untill ? (
                <span style={{ fontSize: 16 }}>
                  {dayjs(openedTeamPlayer?.contract_untill).format(
                    'DD.MM.YYYY',
                  )}
                </span>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </span>
          </Flex>
          <Divider type="vertical" />
        </Flex>
        <div className="team-player-field-container" ref={imgRef}>
          <img
            src="https://dev.sota.id/files/fields/team_player_field_footbal.png"
            alt=""
          />
          <EventsCloud lastXEvents={lastXEvents} imgRef={imgRef} />
        </div>
      </Flex>
    </Flex>
  );
};
export default PlayerInfoBlock;
