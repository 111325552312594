import React, { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as FullScreeExpand } from 'assets/img/icons/faExpandFullScreenIcon.svg';
import { ReactComponent as SoundIcon } from 'assets/img/icons/faSoundIcon.svg';
import { ReactComponent as SoundZeroIcon } from 'assets/img/icons/faSoundZeroIcon.svg';
import './index.css';
import VideoSizeSelectorContext from 'components/contexts/VideoSizeSelectorContext';
import VideoSoundContext from 'components/contexts/VideoSoundControllerContext';
import VideoSpeedContext from 'components/contexts/VideoSpeedContext';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';

interface HelperPlayerButtonsInterface {
  isNarrowScreenMode: boolean;
  toggleFullScreen: any;
  playbackRate: any;
  setPlaybackRate: any;
  volume: number;
  setVolume: any;
  setIsMuted: any;
}

const HelperPlayerButtons: FC<HelperPlayerButtonsInterface> = ({
  // isNarrowScreenMode,
  toggleFullScreen,
  playbackRate,
  setPlaybackRate,
  volume,
  setVolume,
  setIsMuted,
}) => {
  //  const [t] = useTranslation();
  const [dimensions, setDimensions] = useState(1080);
  const [savedVolume, setSavedVolume] = useState(0.5);
  const setVolumeCallback = (newVolume: number) => {
    setIsMuted(false);
    setVolume(newVolume);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { toggleWideScreenMode } = playerReducerV2.actions;
  const dispatch = useDispatch();
  return (
    <div className={'flex-row f-ga-4 ai-c'} style={{ marginRight: '0px' }}>
      <VideoSpeedContext speedSetCallback={setPlaybackRate}>
        <div
          className={'enabledClickable helper-button-text flex-row j-ce'}
          style={{ width: 40 }}
        >
          X{playbackRate}
        </div>
      </VideoSpeedContext>
      <VideoSoundContext setVolumeCallback={setVolumeCallback} volume={volume}>
        <div
          onClick={() => {
            if (volume) {
              setSavedVolume(volume);
              setVolume(0);
              setIsMuted(true);
            } else {
              setIsMuted(false);
              setVolume(savedVolume);
            }
          }}
          className={'enabledClickable flex-row j-ce ai-c'}
          style={{ width: 40, height: 40 }}
        >
          {volume > 0 ? <SoundIcon /> : <SoundZeroIcon />}
        </div>
      </VideoSoundContext>
      <VideoSizeSelectorContext videoSizeSetCallable={setDimensions}>
        <div
          className={'enabledClickable helper-button-text flex-row j-ce'}
          style={{ width: 40 }}
        >
          {dimensions}
        </div>
      </VideoSizeSelectorContext>
      <div
        // className={`${false ? 'disabledClickable' : 'enabledClickable'} flex-row j-ce`}
        className={'enabledClickable flex-row j-ce'}
        style={{ width: 40 }}
        onClick={() => {
          toggleFullScreen((prev: boolean) => !prev);
          dispatch(toggleWideScreenMode());
        }}
      >
        <FullScreeExpand />
      </div>
    </div>
  );
};
export default HelperPlayerButtons;
