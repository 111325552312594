/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './index.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Row,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import MatchSkeleton from 'components/Skeletons/MatchSkeleton';
import { AppStateType } from 'reducers';
import { metricsReducer } from 'reducers/metrics.reducer';
import { protocolReducer } from 'reducers/protocol.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { PLAYER_ROLES_ABBREVIATED } from 'types/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MatchRow from './MatchRow';

const PlayerMatchesBlock = () => {
  const [t] = useTranslation();
  const {
    setGameEventCoordinates,
    clearGameEventCoordinates,
    showAllGameEventCoordinates,
    showGameEventCoordinates,
    leaveSingleEvent,
    setTopPlayerRole,
  } = teamPlayerReducer.actions;
  const [matches, setMatches] = useState<any>([]);
  const { expandedGameClickedId } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openGameProtocol } = protocolReducer.actions;
  const [expanded, setExpanded] = useState<string[]>(
    expandedGameClickedId && expandedGameClickedId.expanded
      ? [expandedGameClickedId.id]
      : [],
  );

  useEffect(() => {
    if (openedTeamPlayer) {
      PlayersAPI.getPlayerRoles(openedTeamPlayer.id).then((response) => {
        const allPositions = [
          {
            value: 'ALL',
            label: t('All positions'),
            clasName: 'select-divider',
          },
          ...response.data.roles.map((role: string) => {
            return { value: role, label: t(role) };
          }),
        ];
        setPositions(allPositions);
        // setSelectedPositions(allPositions);
        const allSchemas: any = [
          { value: 'ALL', label: t('All formations') },
          ...response.data.schemas.map((schema: any) => {
            return {
              value: schema.id,
              label: schema.name,
            };
          }),
        ];
        setPlayedSchemas(allSchemas);
        dispatch(setTopPlayerRole(response.data.top_roles));
      });
    }
  }, [openedTeamPlayer]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [positions, setPositions] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPositions, setSelectedPositions] = useState<string>('ALL');
  const [selectedMinutes, setSelectedMinutes] = useState<string>('ANY');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playedSchemas, setPlayedSchemas] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlayedSchemas, setSelectedPlayedSchemas] =
    useState<string>('ALL');
  const { setMetricsRegistry } = metricsReducer.actions;
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  useEffect(() => {
    EventsAPI.getMetricsRegistry('player', null).then((response) => {
      dispatch(setMetricsRegistry(response.data));
    });
  }, []);
  const MATCHES_PER_PAGE = 1000;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const listenerRef = useRef(false);
  useEffect(() => {
    if (!openedTeamPlayer?.id) {
      return;
    }
    setLoading(true);
    EventsAPI.getPlayerGamesStats(
      null,
      1,
      MATCHES_PER_PAGE,
      selectedMinutes,
      selectedPlayedSchemas,
      selectedPositions,
      openedTeamPlayer.id,
    ).then((matchesStatsResponse) => {
      if (matchesStatsResponse.data) {
        setMatches(matchesStatsResponse.data.results); // TODO add pagination later
        setTotal(matchesStatsResponse.data.total);
      }
      setLoading(false);
    });
  }, [
    selectedMinutes,
    selectedPlayedSchemas,
    selectedPositions,
    openedTeamPlayer,
  ]);

  const matchesRef = useRef<any>();
  return (
    <Flex vertical style={{ width: '100%' }} gap={8}>
      <Flex
        className="block-title"
        justify="flex-start"
        style={{ width: '100%' }}
        gap={16}
      >
        <div style={{ flex: 0 }}>{t('Matches')}</div>
        {positions.length > 0 && (
          <ConfigProvider
            theme={{
              token: {
                colorText:
                  selectedPositions === 'ALL'
                    ? 'rgba(0, 0, 0, 0.65)'
                    : 'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                // colorTextPlaceholder:
                //   'var(--colorTextLabel, rgba(0, 0, 0, 0.65))',
              },
            }}
          >
            <Select
              options={positions}
              placeholder={t('All positions')}
              variant="borderless"
              className={'hasSelection'}
              value={selectedPositions}
              onSelect={setSelectedPositions}
              popupMatchSelectWidth={false}
              optionRender={(option: any) => {
                return <div className="black-text">{option.label}</div>;
              }}
            />
          </ConfigProvider>
        )}
        {playedSchemas.length > 0 && (
          <ConfigProvider
            theme={{
              token: {
                colorText:
                  selectedPlayedSchemas === 'ALL'
                    ? 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))'
                    : 'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                colorTextPlaceholder:
                  'var(--colorTextLabel, rgba(0, 0, 0, 0.65))',
              },
            }}
          >
            <Select
              options={playedSchemas}
              placeholder={t('All formations')}
              variant="borderless"
              value={selectedPlayedSchemas}
              // className={'hasSelection'}
              popupMatchSelectWidth={false}
              onSelect={setSelectedPlayedSchemas}
              optionRender={(option: any) => {
                return <div className="black-text">{option.label}</div>;
              }}
            />
          </ConfigProvider>
        )}
        <ConfigProvider
          theme={{
            token: {
              colorText:
                selectedMinutes === 'ANY'
                  ? 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))'
                  : 'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
              colorTextPlaceholder:
                'var(--colorTextLabel, rgba(0, 0, 0, 0.65))',
            },
          }}
        >
          <Select
            variant="borderless"
            value={selectedMinutes}
            placeholder={t('Played time')}
            popupMatchSelectWidth={false}
            onChange={setSelectedMinutes}
            options={[
              {
                label: t('> 1 min'),
                value: 'ANY',
              },
              {
                label: t('> 15 min'),
                value: '15',
              },
              {
                label: t('> 30 min'),
                value: '30',
              },
              {
                label: t('> 45 min'),
                value: '45',
              },
              {
                label: t('> 60 min'),
                value: '60',
              },
              {
                label: t('> 75 min'),
                value: '75',
              },
              {
                label: t('> 90 min'),
                value: '90',
              },
            ]}
            optionRender={(option) => {
              return <div className="black-text">{option.label}</div>;
            }}
            className={'hasSelection'}
          />
        </ConfigProvider>
      </Flex>
      <Flex vertical gap={8} ref={matchesRef}>
        {!loading &&
          matches.map((match: any) => (
            <MatchRow
              match={match}
              expanded={expanded}
              setExpanded={setExpanded}
              matches={matches}
            />
          ))}
        {loading && <MatchSkeleton />}
        <div ref={matchesRef} style={{ width: '100%', height: 1 }}></div>
      </Flex>
    </Flex>
  );
};
export default PlayerMatchesBlock;
