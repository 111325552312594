import React from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const AverageStatValueTag = (stat: any) => {
  const [t] = useTranslation();
  return (
    <Flex
      className={`${stat.stat?.average === undefined ? 'na-stat' : stat.stat?.average > 0 ? 'average-stat-value-container' : 'stat-value-container-zero'}`}
      style={{ minWidth: 90, minHeight: 32 }}
      align="center"
    >
      <Flex
        className="stat-average"
        justify={stat.stat?.average !== undefined ? 'space-between' : 'center'}
        style={{ width: '100%' }}
        flex={1}
      >
        {stat.stat?.average === undefined ? (
          <span className="disabled-stat-color">{t('n/a')}</span>
        ) : stat.stat.average > 0 ? (
          <span className="stat-average-value">{stat.stat?.average}</span>
        ) : (
          <span className="stat-average-value">0</span>
        )}

        {stat.stat?.percent !== null && stat.stat?.percent !== undefined && (
          <span className={'stat-percent-value'}>{stat.stat?.percent}%</span>
        )}
      </Flex>
    </Flex>
  );
};
export default AverageStatValueTag;
