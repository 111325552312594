import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import './index.css';

export enum StyleOptions {
  confirmation = 'confirmation',
  warning = 'warning',
  information = 'information',
}
interface ConfirmActionModal {
  title: string;
  contentText?: string;
  style?: StyleOptions;
  actionText?: string;
  actionCallback: any;
  isModalOpen: boolean;
  setIsModalOpen: any;
  showCancelButton?: boolean;
  actionClass?: string;
}
const ConfirmActionModal: FC<ConfirmActionModal> = ({
  title,
  contentText,
  style,
  actionText,
  actionCallback,
  isModalOpen,
  setIsModalOpen,
  showCancelButton = true,
}) => {
  const STYLES = {
    warning: 'warning',
    confirmation: 'confirmation',
    information: 'information',
  };
  const [t] = useTranslation();
  const styleClassName = style && STYLES[style];
  return (
    <ReactModal
      overlayClassName="modalOverlay"
      className={`teamsAddModal ${styleClassName} actionModal`}
      isOpen={isModalOpen}
      onRequestClose={() => {
        setIsModalOpen((prev: boolean) => !prev);
      }}
      shouldCloseOnEsc={true}
    >
      <div className="modalTitle">{title}</div>
      {contentText && <span className={'modalContent'}>{contentText}</span>}
      <div className={' actionsContainer'}>
        {showCancelButton && (
          <div
            className={'enabledClickable'}
            onClick={() => {
              setIsModalOpen((prev: boolean | string) =>
                typeof prev === 'boolean' ? false : '',
              );
            }}
          >
            Cancel
          </div>
        )}
        <div
          className={`${styleClassName} enabledClickable actionClass`}
          onClick={actionCallback}
        >
          {actionText || t('Confirm')}
        </div>
      </div>
    </ReactModal>
  );
};
export default ConfirmActionModal;
