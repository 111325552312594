/* eslint-disable indent */
import './index.css';
import React, {
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  // useMemo,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ConfigProvider, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { generateUUID } from 'types/crypto';
import { initTimer } from 'utils/functions';

import { EpisodesNavbar } from './EpisodesNavbar';
import GameVideosContainer from './GameVideosContainer';
import PlaylistCreationControl from './PlaylistCreationControl';
import PlaylistCreationModal from './PlaylistCreationModal';
import PlaylistShareControl from './PlaylistShareControl';
import VerticalListNavigationController from './VerticalVideoListNavigationController';
import {
  AC_SetActiveVideoAction,
  AC_SetAllowPlayFirstAction,
  AC_SetIsPlayingVideoAction,
  // AC_SetPlayedEpisodeAction,
  AC_SetPlayerCommandAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetPlayerLoadingAction,
  AC_setPlayerMode,
  popPlaylist,
} from '../../actions/player.acitons';
import {
  getRootPlaylistsActionV2,
  updatePlaylistAction,
} from '../../actions/playlistV2.async.actions';
import { EventsAPI } from '../../api/events';
import { PlaylistsAPI } from '../../api/playlists';
import { AppStateType } from '../../reducers';
import { useAppDispatch } from '../../store';
import { API_ENDPOINT } from '../../types/constants';
import { OpenedPlaylistEventType } from '../../types/playlist.types';
import {
  GameVideoType,
  PlayCommandType,
  SelectedEpisodesType,
  VideoFileType,
} from '../../types/types';
import FoldersComponent from '../FoldersComponent';
// import PlainButton from '../Library/buttons/PlainButton';
import Loader from '../Loader';
import AddToPlaylistModal from '../modals/AddToPlaylistModal';
import ConfirmActionModal, { StyleOptions } from '../modals/ConfirmActionModal';
import DownloadEpisodesQuestionModal from '../modals/DownloadEpisodesQuestionModal';
import MoveToFolderModal from '../modals/MoveToFolderModal';

export const MODES: any = {
  episodes: 'episodes',
  playlists: 'playlists',
  playlist_view: 'playlist_view',
};

/**
 * @interface  VideoListModeSelectorInterface
 * @property {function} changeMode
 * @property {string} panelMode
 * @property {boolean} hasNewPlaylists
 */
// interface VideoListModeSelectorInterface {
//   changeMode: any;
//   panelMode: string;
//   hasNewPlaylists: boolean;
// }
/**
 * Контроллер переключения режима листинга видео
 * @function
 * @param {VideoListModeSelectorInterface} props - пропсы
 * @returns {JSX.Element} Переключатель режимов
 */
// const VideoListModeSelector: FC<VideoListModeSelectorInterface> = ({
//   changeMode,
//   panelMode,
//   hasNewPlaylists,
// }) => {
//   const showBlueDot = () => {
//     if (hasNewPlaylists) {
//       return <div className={'blueDot'} />;
//     }
//   };
//   const [t] = useTranslation();
//   return (
//     <div className="flex-row j-sb ai-c f-ga-12">
//       <PlainButton
//         callback={() => changeMode(MODES.episodes)}
//         isActive={panelMode === MODES.episodes}
//       >
//         <div>{t('Episodes')}</div>
//       </PlainButton>
//       <PlainButton
//         callback={() => changeMode(MODES.playlists)}
//         isActive={panelMode === MODES.playlists}
//       >
//         <div className={'flex-row f-ga-12 ai-c'}>
//           {t('Playlists')} {showBlueDot()}
//         </div>
//       </PlainButton>
//     </div>
//   );
// };

interface VideoListControllerInterface {
  toggleWideScreen: any;
  isShared: boolean;
  isWideScreenMode: boolean;
}
/**
 * Контейнер хранения панели со списком видео справа
 * @param {Interface} props
 * @param toggleWideScreen
 * @constructor
 */
const VideoListController: FC<any> = forwardRef<
  ForwardedRef<any>,
  VideoListControllerInterface
>(({ toggleWideScreen, isWideScreenMode, isShared = false }, ref: any) => {
  const { style } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const {
    openedPlaylist,
    sharedPlaylist,
    playlistIsLoading,
    playlistOpenError,
  } = useSelector((state: AppStateType) => state.playlistReducerV2);
  const { filteredVideos, playerMode, loadingActions, allowPlayFirst } =
    useSelector((state: AppStateType) => state.playerReducer);
  const navigate = useNavigate();
  const refPlayer = useRef<ReactPlayer>(null);
  const { episodeIdsToAddToPlaylist } = useSelector(
    (state: AppStateType) => state.videosListReducer,
  );
  const { setEpisodeIdsToAddToPlaylist } = videosListReducer.actions;
  useEffect(() => {
    // console.log('shared', sharedPlaylist);
    if (isShared && sharedPlaylist) {
      // console.log(sharedPlaylist);
      if (sharedPlaylist.share_link === 'error') {
        navigate('/login');
      } else {
        dispatch(AC_setPlayerMode(MODES.playlist_view));
      }
    } else {
      if (isShared && playlistOpenError) {
        if (currentUser?.current_team) {
          navigate(`/teams/${currentUser.current_team}`);
        } else if (currentUser?.teams && currentUser?.teams.length > 0) {
          navigate(`/teams/${currentUser.teams[0]}`);
        } else {
          navigate('/login');
        }
      }
    }
  }, [sharedPlaylist, playlistIsLoading]);
  const modesRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const [showPlaylistsSidebar, setShowPlaylistsSidebar] = useState(false);
  const [showPlaylistCreationModal, setShowPlaylistCreationModal] =
    useState(false);
  const [showPlaylistCreationControl, setShowPlaylistCreationControl] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasNewPlaylists, setHasNewPlaylists] = useState(true);

  const [shareLinkControlIsVisible, setShareLinkControlIsVisible] = useState<
    string | null
  >(null);
  const [deletePlaylistId, setDeletePlaylistId] = useState('');
  const deletePlaylist = () => {
    if (deletePlaylistId !== '') {
      PlaylistsAPI.deletePlaylist(deletePlaylistId).then(() => {
        setDeletePlaylistId('');
        setShowPlaylistCreationControl(false);
        setShowDownloadControl('');
        setShareLinkControlIsVisible('');
        setSelectedPlaylistEpisodes({});
        dispatch(popPlaylist(deletePlaylistId));
      });
    }
  };
  const [
    showVideoDownloadConfirmationModal,
    setShowVideoDownloadConfirmationModal,
  ] = useState(false);
  const closeVideoDownloadModal = () => {
    setShowVideoDownloadConfirmationModal(false);
  };
  const [showDownloadControl, setShowDownloadControl] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { intermediateAcitivityIds } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const { statsCustomGameListPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { statsCustomGameList } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );

  const makeFilters = () => {
    const teamId = searchParams.get('teamId')?.toString();
    let authorTeamId: string | undefined = '';
    if (searchParams.get('tid')?.toString()) {
      authorTeamId = searchParams.get('tid')?.toString();
    }
    const eventIds = searchParams.getAll('eid');
    const events = searchParams.getAll('eventId');
    const metric = searchParams.getAll('m');
    const metricId = searchParams.getAll('mid');
    const mSearchType = searchParams.get('mst');
    const opposite = searchParams.getAll('o');
    const lastX = searchParams.getAll('lX');
    const seasons = searchParams.getAll('s');
    let activities = [
      ...new Set([...intermediateAcitivityIds, ...events, ...eventIds]),
    ];
    if (!events.length && statsCustomGameListPlayer.length) {
      activities = [...activities, ...statsCustomGameListPlayer];
    }
    if (!events.length && statsCustomGameList.length) {
      activities = [...activities, ...statsCustomGameList];
    }

    const season = searchParams.get('season');
    const playersIds = searchParams.getAll('pl');
    const markupIds = searchParams.getAll('markupId');
    const customAttributes = searchParams.getAll('p');

    let newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (markupIds.length) {
      newFiltersV2.markup_ids = markupIds;
    }
    if (teamId && activities.length > 0) {
      newFiltersV2.games[teamId] = {
        match: activities || [],
      };
    }
    if (eventIds.length && authorTeamId) {
      newFiltersV2.games[authorTeamId] = {
        match: activities || [],
      };
    }
    if (customAttributes && teamId) {
      newFiltersV2 = {
        ...newFiltersV2,
        custom_attributes: customAttributes,
      };
    }
    if (season && teamId) {
      if (season.includes('-')) {
        newFiltersV2.games[teamId] = {
          season: [season.split('-')[1]],
          tournament: [season.split('-')[0]],
        };
      } else if (season === 'manual') {
        console.log('manual');
      } else {
        newFiltersV2.games[teamId] = {
          lastX: season.split('last')[1],
        };
      }
    }
    if (playersIds.length && teamId) {
      newFiltersV2 = {
        ...newFiltersV2,
        players: {
          [teamId]: playersIds,
        },
      };
    }
    if (lastX.length > 0 && teamId) {
      newFiltersV2.games[teamId] = {
        ...newFiltersV2.games[teamId],
        lastX: lastX[0],
      };
    }
    if (metricId.length > 0 && metric.length === 0) {
      newFiltersV2 = {
        ...newFiltersV2,
        attributes: metricId.map((el: any) => Number(el)),
      };
      if (mSearchType) {
        newFiltersV2 = {
          ...newFiltersV2,
          metrics_search_type: mSearchType,
        };
      }
    } else if (metric.length > 0) {
      if (newFiltersV2.custom_attributes) {
        newFiltersV2 = {
          ...newFiltersV2,
          custom_attributes: [...newFiltersV2.custom_attributes, ...metric],
        };
        if (mSearchType) {
          newFiltersV2 = {
            ...newFiltersV2,
            metrics_search_type: mSearchType,
          };
        }
      } else {
        newFiltersV2 = {
          ...newFiltersV2,
          custom_attributes: metric,
        };
        if (mSearchType) {
          newFiltersV2 = {
            ...newFiltersV2,
            metrics_search_type: mSearchType,
          };
        }
      }
    }

    if (authorTeamId) {
      newFiltersV2.author_team_id = authorTeamId;
    }
    if (opposite.length > 0 && opposite[0] === 'true') {
      newFiltersV2.opposite = true;
    }
    if (seasons.length > 0 && teamId && seasons[0] !== 'manual') {
      newFiltersV2.games[teamId] = {
        ...newFiltersV2.games[teamId],
        season: seasons,
      };
    }
    return newFiltersV2;
  };
  const {
    activeTeam,
    selectedTournaments,
    selectedDateLimits,
    selectedPlayers,
    lastXFilter,
    selectedAttributes,
    selectedGames,
    selectedTimingIntervals,
    showMyEpisdes,
    showPlayerGames,
    showPlayerIntervals,
  } = useSelector((state: AppStateType) => state.filtersReducer);

  const makeExtraFilters = () => {
    const newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (
      activeTeam &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0)
    ) {
      newFiltersV2.games[activeTeam] = {};
      if (selectedTournaments.length > 0) {
        newFiltersV2.games[activeTeam]['tournaments'] = selectedTournaments.map(
          (el) => {
            if (el.id) {
              return el.id;
            }
            return el;
          },
        );
      }
      if (Object.keys(selectedDateLimits).length > 0) {
        const shiftedDateLimits: any = {};
        for (const year of Object.keys(selectedDateLimits)) {
          shiftedDateLimits[year] = [
            selectedDateLimits[year][0] + 1,
            selectedDateLimits[year][1],
          ];
        }
        newFiltersV2.games[activeTeam]['date_limits'] = {
          ...shiftedDateLimits,
        };
      }
      if (lastXFilter) {
        newFiltersV2.games[activeTeam]['lastX'] = lastXFilter;
      }
      if (selectedGames) {
        newFiltersV2.games[activeTeam]['match'] = selectedGames;
      }
      if (Object.keys(selectedTimingIntervals).length > 0) {
        newFiltersV2['match_part'] = selectedTimingIntervals;
      }
      if (showMyEpisdes) {
        newFiltersV2['show_my_episodes'] = showMyEpisdes;
      }
      if (showPlayerGames) {
        newFiltersV2['show_player_games'] = showPlayerGames;
      }
      if (showPlayerIntervals) {
        newFiltersV2['show_player_intervals'] = showPlayerIntervals;
      }
      if (selectedPlayers.length > 0) {
        newFiltersV2.players = {};
        newFiltersV2.players[activeTeam] = selectedPlayers;
      }
      if (selectedAttributes.length > 0) {
        newFiltersV2.attributes = selectedAttributes;
      }
      newFiltersV2['count'] = true;
    }
    return newFiltersV2;
  };
  /*
   * Construct download videos link and send it to server
   */
  const constructDowloadLinkRequest = async (
    choice: boolean,
    addHighlights: boolean,
    add_standards = false,
    asReels = false,
  ) => {
    let episodesFlatList: string[];
    let fullVideosSelected: string[];
    const fullGameVideoIds = filteredVideos.map((video) => {
      if (video?.activity_videos) {
        return video.activity_videos[0].id;
      }
    });
    if (playerMode === MODES.episodes) {
      episodesFlatList = Object.values(selected)
        .map((value) => value.episodes)
        .flat()
        .filter((el) => !fullGameVideoIds.includes(el));
      fullVideosSelected = Object.values(selectedEpisodes)
        .map((value) => value)
        .flat()
        .filter((el) => fullGameVideoIds.includes(el));
    } else {
      episodesFlatList = Object.values(selected)
        .map((value) => value.episodes)
        .flat()
        .filter((el) => !fullGameVideoIds.includes(el));
      fullVideosSelected = Object.values(selectedPlaylistEpisodes)
        .map((value) => value)
        .flat()
        .filter((el) => fullGameVideoIds.includes(el));
    }
    let readyEpisodesIdsList = [];
    if (playerMode !== MODES.episodes) {
      readyEpisodesIdsList = episodesFlatList;
    } else {
      for (const episodeId of episodesFlatList) {
        for (const game of filteredVideos) {
          let ep: any;
          if (episodeId.startsWith('temp')) {
            ep = game.user_episodes?.filter((e: any) => e.id === episodeId)[0];
          } else {
            ep = game.episodes.filter((e) => e.id === episodeId)[0];
          }
          if (ep && !ep.user_timer && !ep.user_event_name && !ep.keyframes) {
            readyEpisodesIdsList.push(episodeId);
          } else if (ep) {
            const timer = ep.user_timer ? ep.user_timer : ep.file_timer;
            const newUserEpisode: any = {
              video: ep.video.id,
              file_timer: {
                started_at: timer?.started_at,
                finished_at: timer?.finished_at,
              },
              game_timer: {
                started_at: initTimer(ep, 'started_at', true),
                finished_at: initTimer(ep, 'finished_at', true),
              },
              user_event_name:
                ep.user_event_name || ep.event_name || ep.event_type.name,
              keyframes: ep.keyframes,
            };
            const response = await EventsAPI.addNewUserEpisode(newUserEpisode);
            readyEpisodesIdsList.push(response.data.id);
          }
        }
      }
    }
    let filters;
    if (playerMode === MODES.episodes) {
      if (searchParams.size) {
        filters = makeFilters();
      } else {
        filters = makeExtraFilters();
      }
    }
    EventsAPI.createDownloadLink(
      readyEpisodesIdsList,
      fullVideosSelected,
      choice,
      filters,
      addHighlights,
      add_standards,
      asReels,
    ).then();
  };
  const [selectedEpisodes, setSelectedEpisodes] =
    useState<SelectedEpisodesType>({});
  const { selected } = useSelector(
    (state: AppStateType) => state.videosListReducer,
  );
  const [selectedPlaylistEpisodes, setSelectedPlaylistEpisodes] =
    useState<SelectedEpisodesType>({});
  const getSelectedEpisodesCount = () => {
    return Object.values(selectedEpisodes)?.reduce(
      (total, values) => total + values?.length,
      0,
    );
  };
  const getSelectedPlaylistEpisodesCount = () => {
    return Object.values(selectedPlaylistEpisodes)?.reduce(
      (total, values) => total + values?.length,
      0,
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayedLink, setDisplayedLink] = useState({
    id: null,
    link: null,
  });
  const { setPlayedVideoSet, setPlayedEpsiodeAction } = playerReducerV2.actions;
  const [t] = useTranslation();
  const { activeVideo, isPlaying } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const oldModeRef = useRef<any>(null);
  useEffect(() => {
    if (oldModeRef.current !== playerMode) {
      oldModeRef.current = playerMode;
      return;
    }
    const playFirstEpisode = async () => {
      if (
        !isPlaying &&
        filteredVideos.length > 0 &&
        filteredVideos[0]?.episodes?.length > 0 &&
        allowPlayFirst
      ) {
        const firstEpisode = filteredVideos[0]?.episodes[0];
        const newActiveVideo: VideoFileType = {
          id: firstEpisode?.id,
          videoId: filteredVideos[0]?.activity_videos[0].id,
          name: firstEpisode?.event_type?.name || '',
          file_path: `${API_ENDPOINT}api/data/videos/${filteredVideos[0]?.activity_videos[0].id}/view/`,
          file_url: filteredVideos[0]?.activity_videos[0].file_url,
          //@ts-ignore
          duration: firstEpisode?.video.meta.video_length,
        };
        await dispatch(AC_SetIsPlayingVideoAction(false));
        if (newActiveVideo.videoId !== activeVideo?.videoId) {
          await dispatch(AC_SetPlayerLoadingAction(true));
        }
        await dispatch(AC_SetActiveVideoAction(newActiveVideo));
        // await dispatch(AC_SetPlayedEpisodeAction(firstEpisode, firstEpisode));
        await dispatch(setPlayedEpsiodeAction(firstEpisode));
        await dispatch(setPlayedVideoSet(firstEpisode));
        await dispatch(AC_SetPlayerCommandAction(PlayCommandType.play));
      }
      dispatch(AC_SetAllowPlayFirstAction(true));
    };
    playFirstEpisode().then();
  }, [filteredVideos[0]?.episodes]);
  const [loadedPages, setLoadedPages] = useState<any>({});

  const [downloadWaitingConfirmationOpen, setDownloadWaitingConfirmationOpen] =
    useState(false);
  const [movePlaylistToFolderOpen, setMovePlaylistToFolderOpen] = useState<
    string | null
  >(null);
  const { showNotification } = notificationsReducer.actions;
  useEffect(() => {
    if (downloadWaitingConfirmationOpen) {
      dispatch(
        showNotification({
          notificationParameters: [
            {
              id: generateUUID(),
              text: t('File added to download queue'),
              callbackName: 'openDownloads',
            },
          ],
        }),
      );
      setDownloadWaitingConfirmationOpen(false);
    }
  }, [downloadWaitingConfirmationOpen]);
  useEffect(() => {
    if (movePlaylistToFolderOpen) {
      dispatch(AC_SetPlayerHotkeysAllowedAction(false));
    } else {
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    }
  }, [movePlaylistToFolderOpen]);
  const movePlaylistToFolderCallback = (destinationFolder: string | null) => {
    if (movePlaylistToFolderOpen) {
      PlaylistsAPI.updatePlaylist(
        movePlaylistToFolderOpen,
        undefined,
        destinationFolder,
      )
        .then(() => {
          dispatch(updatePlaylistAction());
          dispatch(getRootPlaylistsActionV2());
          setMovePlaylistToFolderOpen(null);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };
  const getTotalEpisodesCount = () => {
    let total = 0;
    if (playerMode === 'episodes') {
      filteredVideos?.map((video: any) => {
        if (video.events) {
          total += video.episodes?.length;
        }
        return null;
      });
    } else if (playerMode === 'playlist_view' && !sharedPlaylist) {
      openedPlaylist?.playlist_events?.map((video: any) => {
        if (video.episodes) {
          total += video.episodes?.length;
        }
        return null;
      });
    } else if (playerMode === 'playlist_view' && sharedPlaylist) {
      sharedPlaylist?.playlist_events?.map((video: any) => {
        if (video.episodes) {
          total += video.episodes?.length;
        }
        return null;
      });
    }
    return total;
  };
  const toggleSelectAll = (selectAll = false, elements: any) => {
    if (selectAll) {
      const allVideos: any = {};
      for (const [elementIndex, videoElement] of elements.entries()) {
        let elId = undefined;
        if (openedPlaylist) {
          elId = videoElement.id;
        } else {
          elId = `${videoElement.id}-${elementIndex}`;
        }
        allVideos[elId] = {
          allSelected: true,
          episodes: videoElement.episodes.map((episode: any) => episode.id),
        };
      }
      dispatch(AC_initialzeSelectedEpisodes(allVideos));
    } else {
      dispatch(AC_initialzeSelectedEpisodes({}));
    }
    // setSelectedEpisodes((prev: any) => filtered);
  };
  const prevFilteredEpisodes = useRef<any>();
  const { AC_initialzeSelectedEpisodes } = videosListReducer.actions;
  useEffect(() => {
    if (playerMode === MODES.episodes) {
      if (
        prevFilteredEpisodes?.current !== getTotalEpisodesCount() &&
        filteredVideos
      ) {
        toggleSelectAll(true, filteredVideos);
        prevFilteredEpisodes.current = getTotalEpisodesCount();
      }
    } else if (
      playerMode === MODES.playlist_view &&
      prevFilteredEpisodes?.current !== getTotalEpisodesCount() &&
      openedPlaylist
    ) {
      toggleSelectAll(true, openedPlaylist?.playlist_events);
      prevFilteredEpisodes.current = getTotalEpisodesCount();
    }
  }, [
    filteredVideos,
    openedPlaylist?.playlist_events,
    sharedPlaylist,
    playerMode,
  ]);

  return (
    <>
      {
        <div
          className={`videoFiltersPanelController ${style} ${isWideScreenMode ? 'visible' : 'hidden'}`}
        >
          <div
            className={`playlists-side-bar ${showPlaylistsSidebar ? 'hidden' : 'hidden'}`}
          >
            Playlists
          </div>
          <div>
            <EpisodesNavbar
              ref={modesRef}
              constructDowloadLinkRequest={constructDowloadLinkRequest}
              resultConfirmationModalHandle={setDownloadWaitingConfirmationOpen}
              sharePlaylistCallable={setShareLinkControlIsVisible}
              movePlaylistToFolderCallback={setMovePlaylistToFolderOpen}
              isShared={isShared}
              episodesController={{
                selectedEpisodes: selectedEpisodes,
                setSelectedEpisodes: setSelectedEpisodes,
              }}
              setShowPlaylistsSidebar={setShowPlaylistsSidebar}
            />
            {currentUser && playerMode === MODES.episodes && (
              <>
                <div className={'flex-column f-ga-8 gameListContainer '}>
                  {loadingActions.includes('GET_VIDEO_EPISODES') && <Loader />}
                  {filteredVideos &&
                    filteredVideos.map(
                      (videoElement: GameVideoType, index: number) => {
                        return (
                          <GameVideosContainer
                            key={index}
                            isShared={false}
                            videoElement={videoElement}
                            ref={ref}
                            episodesController={{
                              selectedEpisodes: selectedEpisodes,
                              setSelectedEpisodes: setSelectedEpisodes,
                            }}
                            loadedEpisodesPagesController={{
                              loadedPages: loadedPages,
                              setLoadedPages: setLoadedPages,
                            }}
                            type={'games'}
                            elementIndex={index}
                          />
                        );
                      },
                    )}
                  {(!filteredVideos || filteredVideos.length === 0) &&
                    !openedPlaylist && (
                      <div className={'h100 flex-column j-ce ai-c ga-8'}>
                        <div className={'noEventsTitle'}>
                          {t('No selected events.')}
                        </div>
                        <div className={'noEventsContent'}>
                          {t('You can apply filters to get a list of events.')}
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
            {playerMode === MODES.playlists && (
              <FoldersComponent
                shareEpisodesCallable={setShareLinkControlIsVisible}
                movePlaylistToFolderOpen={movePlaylistToFolderOpen}
                setMovePlaylistToFolderOpen={setMovePlaylistToFolderOpen}
              />
            )}
            {currentUser && playerMode === MODES.playlist_view && (
              <>
                <div className={'flex-column f-ga-8 gameListContainer'}>
                  {loadingActions.includes('GET_VIDEO_EPISODES') && <Loader />}
                  {openedPlaylist &&
                    openedPlaylist.playlist_events?.map(
                      (
                        videoElement: OpenedPlaylistEventType,
                        index: number,
                      ) => {
                        return (
                          <GameVideosContainer
                            key={index}
                            isShared={false}
                            videoElement={videoElement}
                            ref={refPlayer}
                            episodesController={{
                              selectedEpisodes: selectedEpisodes,
                              setSelectedEpisodes: setSelectedEpisodes,
                            }}
                            loadedEpisodesPagesController={{
                              loadedPages: loadedPages,
                              setLoadedPages: setLoadedPages,
                            }}
                            type={'playlist'}
                            elementIndex={index}
                          />
                        );
                      },
                    )}
                  {(!filteredVideos || filteredVideos.length === 0) &&
                    !openedPlaylist && (
                      <div className={'h100 flex-column j-ce ai-c ga-8'}>
                        <div className={'noEventsTitle'}>
                          {t('No selected events.')}
                        </div>
                        <div className={'noEventsContent'}>
                          {t('You can apply filters to get a list of events.')}
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
            {sharedPlaylist && (
              <>
                <div className={'flex-column f-ga-8 gameListContainer'}>
                  {sharedPlaylist.playlist_events.map(
                    (videoElement: OpenedPlaylistEventType, index: number) => {
                      return (
                        <GameVideosContainer
                          key={index}
                          isShared={true}
                          videoElement={videoElement}
                          ref={refPlayer}
                          episodesController={{
                            selectedEpisodes: selectedEpisodes,
                            setSelectedEpisodes: setSelectedEpisodes,
                          }}
                          loadedEpisodesPagesController={{
                            loadedPages: loadedPages,
                            setLoadedPages: setLoadedPages,
                          }}
                          type={'playlist'}
                          elementIndex={index}
                        />
                      );
                    },
                  )}
                </div>
              </>
            )}
          </div>
          {
            <AddToPlaylistModal
              isOpen={episodeIdsToAddToPlaylist.length > 0}
              handleOk={() => {
                dispatch(setEpisodeIdsToAddToPlaylist({ episodeIds: [] }));
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                // setOpenAddToPlaylistModal([]);
              }}
              closeCallable={() => {
                dispatch(setEpisodeIdsToAddToPlaylist({ episodeIds: [] }));
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                // setOpenAddToPlaylistModal([]);
              }}
              episodeIds={episodeIdsToAddToPlaylist}
            />
          }
          {
            <DownloadEpisodesQuestionModal
              constructDowloadLinkRequest={constructDowloadLinkRequest}
              resultConfirmationModalHandle={setDownloadWaitingConfirmationOpen}
            />
          }
          {showPlaylistCreationControl && (
            <PlaylistCreationControl
              selectedCount={
                getSelectedEpisodesCount() | getSelectedPlaylistEpisodesCount()
              }
              sharePlaylistCallable={setShareLinkControlIsVisible}
              downloadLinkCallable={setShowDownloadControl}
              addToPlaylistCallable={() => setShowPlaylistCreationModal(true)}
              cancelButtonShownTo={{
                download: showDownloadControl,
                share: shareLinkControlIsVisible,
              }}
              closeControl={setShowPlaylistCreationControl}
            />
          )}
          {shareLinkControlIsVisible && (
            <PlaylistShareControl
              linkData={{
                episodes:
                  playerMode === MODES.episodes
                    ? selectedEpisodes
                    : selectedPlaylistEpisodes,
                id: displayedLink?.id,
              }}
              shareLink={displayedLink?.link}
              closeCallable={setShareLinkControlIsVisible}
              sharedPlaylistId={shareLinkControlIsVisible}
            />
          )}
          {
            <PlaylistCreationModal
              isOpen={showPlaylistCreationModal}
              setIsOpen={setShowPlaylistCreationModal}
              episodes={
                playerMode === MODES.episodes
                  ? selectedEpisodes
                  : selectedPlaylistEpisodes
              }
              closeControl={setShowPlaylistCreationControl}
            />
          }
          {
            <MoveToFolderModal
              isOpen={movePlaylistToFolderOpen !== null}
              type={'playlist'}
              folderId={movePlaylistToFolderOpen}
              handleOk={movePlaylistToFolderCallback}
              closeCallable={() => setMovePlaylistToFolderOpen(null)}
            />
          }
          <ConfirmActionModal
            title={t('Delete playlist?')}
            contentText={t('You cannot restore playlist.')}
            style={StyleOptions.warning}
            actionText={t('Delete')}
            actionCallback={deletePlaylist}
            isModalOpen={deletePlaylistId !== ''}
            setIsModalOpen={setDeletePlaylistId}
          />
          <ConfirmActionModal
            title={t('Please stand by')}
            contentText={t(
              'You will receive an email with a link to download the archive with selected videos.',
            )}
            style={StyleOptions.information}
            actionText={t('OK')}
            actionCallback={closeVideoDownloadModal}
            isModalOpen={showVideoDownloadConfirmationModal}
            setIsModalOpen={setShowVideoDownloadConfirmationModal}
            showCancelButton={false}
            actionClass={''}
          />
          <Tooltip
            variant={'dark'}
            id={'gameAttributesTooltip'}
            noArrow={false}
            // positionStrategy={'bottom'}
            className={'videoAttributesTooltip'}
            place={'bottom-end'}
          />
        </div>
      }

      <VerticalListNavigationController
        toggleWideScreen={toggleWideScreen}
        playerMode={playerMode}
        setHasNewPlaylists={setHasNewPlaylists}
        setSelectedEpisodes={setSelectedEpisodes}
        setSelectedPlaylistEpisodes={setSelectedPlaylistEpisodes}
        modesRef={modesRef}
        isShared={isShared}
        isWideScreenMode={isWideScreenMode}
      />
    </>
  );
});
export default VideoListController;
