import React from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import { AC_SetDownloadEpisodesAction } from '../../../actions/player.acitons';
import { ReactComponent as DownloadIcon } from '../../../assets/img/icons/faDownloadIcon.svg';
import { useAppDispatch } from '../../../store';

const DownloadEpisodesContext = ({
  constructDowloadLinkRequest,
  resultConfirmationModalHandle,
}: {
  playlist: any;
  constructDowloadLinkRequest: any;
  resultConfirmationModalHandle: any;
}) => {
  const [t] = useTranslation();
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { selected } = useSelector(
    (state: AppStateType) => state.videosListReducer,
  );
  const { filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div onClick={() => downloadCommand('manual')}>
          {t('Simple download')}
        </div>
      ),
      disabled: false,
    },
  ];
  const getSelectedReelsCount = (asNumber: boolean, countReels: boolean) => {
    let reelsCount = 0;
    let simpleCount = 0;
    if (openedPlaylist) {
      Object.keys(selected).forEach((instanceId: string) => {
        const playlist = openedPlaylist.playlist_events.filter(
          (event) => event.id === instanceId,
        )[0];
        if (playlist) {
          playlist.episodes.forEach((episode) => {
            if (
              selected[instanceId].episodes.includes(episode.id) &&
              episode.keyframes?.length
            ) {
              reelsCount += 1;
            }
            if (selected[instanceId].episodes.includes(episode.id)) {
              simpleCount += 1;
            }
          });
        }
      });
    } else {
      if (filteredVideos.length > 0) {
        Object.keys(selected).forEach((instanceId: string) => {
          const video = filteredVideos.filter(
            (video) => video.id === instanceId.slice(0, -2),
          )[0];
          if (video) {
            video.episodes.forEach((episode: any) => {
              if (
                selected[instanceId].episodes.includes(episode.id) &&
                episode.keyframes?.length
              ) {
                reelsCount += 1;
              }
              if (selected[instanceId].episodes.includes(episode.id)) {
                simpleCount += 1;
              }
            });
            video.user_episodes?.forEach((episode: any) => {
              if (
                selected[instanceId].episodes.includes(episode.id) &&
                episode.keyframes?.length
              ) {
                reelsCount += 1;
              }
              if (selected[instanceId].episodes.includes(episode.id)) {
                simpleCount += 1;
              }
            });
          }
        });
      }
    }
    if (countReels) {
      if (reelsCount) {
        if (asNumber) {
          return reelsCount;
        }
        return ` (${reelsCount})`;
      }
    } else {
      if (simpleCount) {
        if (asNumber) {
          return simpleCount;
        }
        return ` (${simpleCount})`;
      }
    }
  };
  if (currentUser?.custom_permissions.includes('can_cut_reels')) {
    items.push(
      {
        key: 2,
        label: (
          <div onClick={() => downloadCommand('highlights')}>
            {t('Highlights')}
          </div>
        ),
        disabled: false,
      },
      {
        key: 3,
        label: (
          <div onClick={() => downloadCommand('standards')}>
            {t('Standards')}
          </div>
        ),
        disabled: false,
      },
      {
        key: 4,
        label: (
          <div onClick={() => downloadCommand('reels')}>
            {t('Reels format')}
            {getSelectedReelsCount(false, true)}
          </div>
        ),
        disabled: getSelectedReelsCount(false, true) === undefined,
      },
    );
  }
  const dispatch = useAppDispatch();
  const downloadCommand = (downloadType: string) => {
    const reelsCount = getSelectedReelsCount(true, true);
    const simpleCount = getSelectedReelsCount(true, false);
    if (reelsCount === undefined && simpleCount === undefined) {
      return;
    }
    switch (downloadType) {
      case 'manual':
        if (Number(simpleCount) > 1) {
          dispatch(AC_SetDownloadEpisodesAction(downloadType));
        } else {
          constructDowloadLinkRequest(true, false, false, false);
          resultConfirmationModalHandle(true);
        }
        break;
      case 'highlights':
        if (Number(simpleCount) > 1) {
          dispatch(AC_SetDownloadEpisodesAction(downloadType));
        } else {
          constructDowloadLinkRequest(true, true, false, false);
          resultConfirmationModalHandle(true);
        }
        break;
      case 'standards':
        if (Number(simpleCount) > 1) {
          dispatch(AC_SetDownloadEpisodesAction(downloadType));
        } else {
          constructDowloadLinkRequest(true, false, true, false);
          resultConfirmationModalHandle(true);
        }
        break;
      case 'reels':
        if (Number(reelsCount) > 1) {
          dispatch(AC_SetDownloadEpisodesAction(downloadType));
        } else {
          constructDowloadLinkRequest(true, false, false, true);
          resultConfirmationModalHandle(true);
        }
        break;
      default:
        return;
    }
  };
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <Dropdown
          className="enabledClickable"
          menu={{ items }}
          trigger={['click']}
          overlayStyle={{ width: 'max-content' }}
          placement="bottomRight"
        >
          <DownloadIcon style={{ display: 'block', margin: '4 2 4 2' }} />
        </Dropdown>
      </ConfigProvider>
    </>
  );
};
export default DownloadEpisodesContext;
