import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Checkbox, ConfigProvider, Dropdown, MenuProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  AC_SetActiveVideoAction,
  AC_SetFilteredVideosAction,
  AC_SetIsPlayingVideoAction,
  // AC_SetPlayedEpisodeAction,
  AC_SetPlayerCommandAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetPlayerLoadingAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import { PlaylistsAPI } from 'api/playlists';
import { ReactComponent as ElipsisVertical } from 'assets/img/icons/faElipsisV.svg';
import { ReactComponent as PauseCircle } from 'assets/img/icons/faPauseCircle.svg';
import { ReactComponent as PlayCircleFilled } from 'assets/img/icons/faPlayCircleFilled.svg';
import './index.css';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import { API_ENDPOINT, WINDOW_MODE_WIDTH } from 'types/constants';
import { generateUUID } from 'types/crypto';
import {
  convertTimeToMilliseconds,
  filterAndIndex,
  formatMillisecondsToTime,
} from 'types/functions';
import { EpisodeTypes, PlayCommandType, VideoFileType } from 'types/types';
import { initTimer } from 'utils/functions';

import ItemWrapper from './ItemWrapper/itemWrapper';
import RenameController from './RenameController';
import { MODES } from '..';

const VideoListElement = forwardRef((props: any) => {
  const {
    videosListEditMode,
    activeVideo,
    isPlaying,
    filteredVideos,
    playerMode,
    playCommand,
    editedEpisodeRange,
  } = useSelector((state: AppStateType) => state.playerReducer);
  const { playedEpisode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { openedPlaylistId, openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { selected } = useSelector(
    (state: AppStateType) => state.videosListReducer,
  );
  const getTooltipContent = () => {
    if (Object.keys(props.episode).includes('players')) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: props.episode.players?.join('<br>'),
          }}
        />
      );
    }
    if (
      Object.keys(props.episode).includes('players_names') &&
      props.episode.players_names.length > 0
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: props.episode.players_names?.join('</br>'),
          }}
        />
      );
    }
    if (Object.keys(props.episode).includes('event_type')) {
      return props.episode.event_type?.name;
    }
    if (
      Object.keys(props.episode).includes('attributes') &&
      props.episode.attributes
    ) {
      return props.episode.attributes.name;
    }
  };
  const dispatch = useAppDispatch();
  const initTimerWithFormat = (
    timerType: string,
    useGameTimer = false,
    useFileTimer = false,
  ) => {
    let timer = 0;
    if (useFileTimer) {
      timer = props.episode.file_timer[timerType];
    } else {
      if (useGameTimer) {
        timer = props.episode?.game_timer[timerType];
      } else if (
        props.episode.file_timer &&
        props.episode.file_timer[timerType] >= 0
      ) {
        timer = props.episode.file_timer[timerType];
      }
    }
    const hasUserTimer = Boolean(props.episode?.user_timer);
    let windowModeOffset = WINDOW_MODE_WIDTH;
    if (props.episode.episode_type === EpisodeTypes.period) {
      windowModeOffset = 0;
    }
    if (
      props.episode.user_timer ||
      props.episode.episode_type === EpisodeTypes.user_episode
    ) {
      windowModeOffset = 0;
    }
    if (timerType === 'started_at') {
      if (!hasUserTimer) {
        if (timer >= windowModeOffset * 1000) {
          timer -= windowModeOffset * 1000;
        } else {
          timer = 0;
        }
      }
    } else {
      if (!hasUserTimer) {
        if (
          Number(props.episode.video.meta.video_length) * 1000 - timer >
          windowModeOffset * 1000
        ) {
          timer += windowModeOffset * 1000;
        } else {
          timer = Number(props.episode.video.meta.video_length) * 1000;
        }
      }
    }
    return formatMillisecondsToTime(timer);
  };
  useEffect(() => {
    setStartedAtTimeUserValue(initTimerWithFormat('started_at', true));
    setFinishedAtTimeUserValue(initTimerWithFormat('finished_at', true));
    setStartedAtGameValue(initTimerWithFormat('started_at', true));
    setFinishedAtGameValue(initTimerWithFormat('finished_at', true));
  }, [props?.episode]);
  const [startedAtTimeUserValue, setStartedAtTimeUserValue] = useState<string>(
    initTimerWithFormat('started_at'),
  );
  const [startedAtGameValue, setStartedAtGameValue] = useState<string>(
    initTimerWithFormat('started_at', true),
  );
  const [finishedAtGameValue, setFinishedAtGameValue] = useState<string>(
    initTimerWithFormat('finished_at', true),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startedAtIsValid, setStartedAtIsValid] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finishedAtIsValid, setFinishedAtIsValid] = useState(true);
  const [finishedAtTimeUserValue, setFinishedAtTimeUserValue] =
    useState<string>(initTimerWithFormat('finished_at'));
  const VALUE_VALIDATOR: any = {
    started_at: setStartedAtIsValid,
    finished_at: setFinishedAtIsValid,
  };
  const validateTimes = (value: string, timerType: string) => {
    if (timerType === 'started_at') {
      return (
        convertTimeToMilliseconds(value) <
        convertTimeToMilliseconds(finishedAtTimeUserValue)
      );
    } else {
      return (
        convertTimeToMilliseconds(value) >
        convertTimeToMilliseconds(startedAtTimeUserValue)
      );
    }
  };
  const {
    setEditedEpisodeRange,
    setEditedEpisodeFileRange,
    setVideoListEditMode,
    resetEditMode,
  } = playerReducerV2.actions;
  useEffect(() => {
    const startAtIsValid = validateTimes(startedAtTimeUserValue, 'started_at');
    const finishedAtIsValid = validateTimes(
      finishedAtTimeUserValue,
      'finished_at',
    );
    VALUE_VALIDATOR['started_at'](startAtIsValid);
    VALUE_VALIDATOR['finished_at'](finishedAtIsValid);
    if (startAtIsValid && finishedAtIsValid && videosListEditMode) {
      const eventId = props.episode.id;
      const range = [
        convertTimeToMilliseconds(startedAtTimeUserValue),
        convertTimeToMilliseconds(finishedAtTimeUserValue),
      ];
      const fileRange = [
        initTimer(props.episode, 'started_at', false, true),
        initTimer(props.episode, 'finished_at', false, true),
      ];
      dispatch(setEditedEpisodeRange(range));
      dispatch(setEditedEpisodeFileRange(fileRange));
      dispatch(setVideoListEditMode(eventId));
    }
  }, [finishedAtTimeUserValue, startedAtTimeUserValue]);
  const { setPlayedVideoSet, setPlayedEpsiodeAction, setCreateEpisodeMode } =
    playerReducerV2.actions;
  const { resetReelsState } = reelsReducer.actions;
  const playEpisode = async () => {
    if (isPlaying && props.episode.id === playedEpisode?.id) {
      dispatch(AC_SetIsPlayingVideoAction(false));
      dispatch(AC_SetPlayerCommandAction(PlayCommandType.pause));
    } else {
      const newActiveVideo: VideoFileType = {
        id: props.episode.id,
        videoId: props.episode.video.id,
        name: props.videoElement.name,
        file_path: `${API_ENDPOINT}api/data/videos/${props.episode.video.id}/view/`,
        file_url: props.episode.video.file_url,
        duration: props.episode.video.meta.video_length,
      };
      if (
        props.episode.id === playedEpisode?.id &&
        playCommand === PlayCommandType.pause
      ) {
        dispatch(AC_SetPlayerCommandAction(PlayCommandType.resume));
      } else {
        dispatch(AC_SetIsPlayingVideoAction(false));
        if (newActiveVideo.videoId !== activeVideo?.videoId) {
          dispatch(AC_SetPlayerLoadingAction(true));
        }
        dispatch(AC_SetActiveVideoAction(newActiveVideo));
        // dispatch(AC_SetPlayedEpisodeAction(props.episode, props.videoElement));
        dispatch(setPlayedEpsiodeAction(props.episode));
        dispatch(setPlayedVideoSet(props.videoElement));
        dispatch(resetReelsState());
        dispatch(AC_SetPlayerCommandAction(PlayCommandType.play));
      }
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
      dispatch(AC_SetVisibleRangeAction([]));
      if (
        editedEpisodeRange.length > 0 &&
        props.episode.id !== playedEpisode?.id
      ) {
        dispatch(resetEditMode());
        dispatch(setCreateEpisodeMode(false));
      }
    }
  };
  const localRef = useRef<any>(null);
  useEffect(() => {
    let timerType = 'system';
    let timer;
    if (props.episode.user_timer !== null) {
      timer = props.episode.user_timer;
      timerType = 'user';
    } else {
      timer = props.episode.file_timer;
    }
    if (
      activeVideo &&
      activeVideo.videoId === props.episode.video?.id &&
      activeVideo?.id === props.episode?.id &&
      timer?.started_at !== undefined
      // && !isLoading
    ) {
      // console.log('SEEK', props.episode.user_timer.started_at / 1000)
      let targetTime = Number(timer.started_at / 1000);
      if (timerType === 'system') {
        if (targetTime >= 5) {
          targetTime = targetTime - WINDOW_MODE_WIDTH;
        } else {
          targetTime = 0;
        }
      }
      // dispatch(AC_SetPlayedEpisodeAction(props.episode, props.videoElement));
      dispatch(setPlayedEpsiodeAction(props.episode));
      dispatch(setPlayedVideoSet(props.videoElement));
      localRef &&
        localRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      // dispatch(AC_SetIsPlayingVideoAction(true));
      // TODO тут прокинуть данные проигрываемого эпизода в редакс, затем проверять их в хендлере
    }
  }, [activeVideo]);
  const renderTimeElement = (
    value: string,
    // isValid: boolean,
    // timerElementType: string,
  ) => {
    if (props.episode.event_type?.activity_type !== null) {
      return <span>{value}</span>;
    }
    return value;
  };
  const getElementName = () => {
    if (props.episode.user_event_name) {
      return (
        <div className="oy-h">
          <div className={'elipsis-text'}>{props.episode.user_event_name}</div>
        </div>
      );
    }
    if (props.episode.event_type && props.episode.event_type.name) {
      return (
        <div className="oy-h">
          <div className={'elipsis-text '}>
            {activeVideo?.id === props.episode.id
              ? getTooltipContent()
              : props.episode.event_type.name}
          </div>
        </div>
      );
    }
    if (
      props.episode?.attributes &&
      props.episode?.attributes[0]?.activity_type === 'game' &&
      props.episode?.attributes[0]?.name
    ) {
      return (
        <div className="oy-h">
          <div className={'elipsis-text '}>
            {props.episode.attributes[0]?.name}
          </div>
        </div>
      );
    }
  };
  const getPlaylistElementName = () => {
    if (props.episode.user_event_name) {
      return (
        <div className="oy-h">
          <Tooltip title={props.episode.user_event_name} arrow={false}>
            <div className={'elipsis-text'}>
              {props.episode.user_event_name}
            </div>
          </Tooltip>
        </div>
      );
    }
    return (
      <div className={'flex-column'}>
        <div className={'no-wrap'}>
          {activeVideo?.id === props.episode.id
            ? getTooltipContent()
            : props.episode?.attributes?.name}
        </div>
      </div>
    );
  };
  const turnEditMode = () => {
    const eventId = props.episode.id;
    const range = [
      initTimer(props.episode, 'started_at', true, false),
      initTimer(props.episode, 'finished_at', true, false),
    ];
    const fileRange = [
      initTimer(props.episode, 'started_at', false, true),
      initTimer(props.episode, 'finished_at', false, true),
    ];

    const newActiveVideo: VideoFileType = {
      id: props.episode.id,
      videoId: props.episode.video.id,
      name: props.videoElement.name,
      file_path: `${API_ENDPOINT}api/data/videos/${props.episode.video.id}/view/`,
      file_url: props.episode.video.file_url,
      duration: props.episode.video.meta.video_length,
    };
    if (newActiveVideo.videoId !== activeVideo?.videoId) {
      dispatch(AC_SetPlayerLoadingAction(true));
    }
    dispatch(AC_SetActiveVideoAction(newActiveVideo));
    dispatch(setPlayedEpsiodeAction(props.episode));
    dispatch(setPlayedVideoSet(props.videoElement));
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
    dispatch(AC_SetPlayerCommandAction(PlayCommandType.scrollTo));
    dispatch(setEditedEpisodeRange(range));
    dispatch(setEditedEpisodeFileRange(fileRange));
    dispatch(setVideoListEditMode(eventId));
  };
  const [t] = useTranslation();
  const isChecked = (episode: any) => {
    if (playerMode === 'episodes') {
      return selected[
        `${props.videoElement.id}-${props.elementIndex}`
      ]?.episodes?.includes(episode.id);
    } else {
      return selected[`${props.videoElement.id}`]?.episodes?.includes(
        episode.id,
      );
    }
  };
  const [renameMode, setRenameMode] = useState(false);
  const turnRenameMode = () => {
    setRenameMode(true);
    setNewEpisodeName(
      props.episode?.user_event_name ||
        props.episode?.event_type?.name ||
        props.episode.user_event_name ||
        props.episode.attributes.name ||
        '',
    );
    dispatch(AC_SetPlayerHotkeysAllowedAction(false));
  };
  const revertEpisodeChanges = () => {
    const updatedVideo = filterAndIndex(
      filteredVideos,
      props.videoElement?.id,
      'id',
    );
    let editedEpisode;
    let updatedFilteredVideos;
    if (props.episode.episode_type === EpisodeTypes.episode) {
      editedEpisode = filterAndIndex(
        updatedVideo?.element?.episodes,
        props.episode.id,
        'id',
      );
      console.log('editedEpisode');
      console.log(editedEpisode);
      updatedFilteredVideos = [
        ...filteredVideos.slice(0, updatedVideo.index),
        {
          ...updatedVideo.element,
          episodes: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...updatedVideo?.element.episodes?.slice(0, editedEpisode.index),
            {
              ...editedEpisode.element,
              game_timer:
                editedEpisode.element.initial_game_timer ||
                editedEpisode.element.game_timer,
              isTemporary: undefined,
              user_timer: undefined,
              user_event_name: undefined,
              initial_game_timer: undefined,
              keyframes: undefined,
            },
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...updatedVideo?.element.episodes?.slice(editedEpisode.index + 1),
          ],
        },
        ...filteredVideos.slice(updatedVideo.index + 1),
      ];
    } else if (props.episode.episode_type === EpisodeTypes.user_episode) {
      editedEpisode = filterAndIndex(
        updatedVideo?.element?.user_episodes,
        props.episode.id,
        'id',
      );
      updatedFilteredVideos = [
        ...filteredVideos.slice(0, updatedVideo.index),
        {
          ...updatedVideo.element,
          user_episodes: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...updatedVideo?.element.user_episodes?.slice(
              0,
              editedEpisode.index,
            ),
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...updatedVideo?.element.user_episodes?.slice(
              editedEpisode.index + 1,
            ),
          ],
        },
        ...filteredVideos.slice(updatedVideo.index + 1),
      ];
    }
    dispatch(AC_SetFilteredVideosAction(updatedFilteredVideos));
    setRenameMode(false);
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
  };
  const { setEpisodeIdsToAddToPlaylist } = videosListReducer.actions;
  const getEpisodeContextItems = (episode: any) => {
    let items: MenuProps['items'] = [
      {
        key: 1,
        label: <div onClick={turnRenameMode}>{t('Rename')}</div>,
        disabled: false,
      },
      {
        key: 2,
        label: (
          <div
            onClick={() => {
              if (episode.id !== videosListEditMode) {
                turnEditMode();
              }
            }}
          >
            {t('Change timeline')}
          </div>
        ),
        disabled: episode.id === videosListEditMode,
      },
      {
        key: 3,
        label: <div onClick={handleSingleEpisodeDownload}>{t('Download')}</div>,
        disabled: false,
      },
      {
        key: 5,
        label: (
          <div
            onClick={() => {
              dispatch(
                setEpisodeIdsToAddToPlaylist({
                  episodeIds: [props.episode.id],
                }),
              );
              dispatch(AC_SetIsPlayingVideoAction(false));
              dispatch(AC_SetPlayerHotkeysAllowedAction(false));
              // props.setOpenAddToPlaylistModal([props.episode.id]);
            }}
          >
            {t('Add to playlist')}
          </div>
        ),
      },
      { key: 6, label: <div>{t('Paint')}</div>, disabled: true },
    ];
    if (
      episode.user_event_name ||
      episode?.user_timer?.started_at !== undefined
    ) {
      items.push({
        key: 7,
        label: (
          <div onClick={() => revertEpisodeChanges()}>
            {t('Revert all changes')}
          </div>
        ),
        disabled: false,
      });
    }
    if (episode.episode_type === EpisodeTypes.period) {
      items = items.filter((item: any) => item.key !== 1 && item.key !== 2);
    }
    return items;
  };
  const { AC_setOpenedPlaylist, AC_removeEpisodeFromGame } =
    playlistReducerV2.actions;
  const { showNotification } = notificationsReducer.actions;
  const removePlaylistEpisodeHandler = (
    playlistEpisodeId: string,
    // gameId: string,
  ) => {
    if (openedPlaylist) {
      PlaylistsAPI.removePlaylistElement(
        openedPlaylist.id,
        playlistEpisodeId,
      ).then(() => {
        dispatch(
          AC_removeEpisodeFromGame({
            episodeId: playlistEpisodeId,
            gameId:
              playerMode === 'episodes'
                ? `${props.videoElement.id}-${props.elementIndex}`
                : props.videoElement.id,
            elementIndex: props.elementIndex,
          }),
        );
        props.toggleEpisodeSelection(
          false,
          props.episode.id,
          playerMode === 'episodes'
            ? `${props.videoElement.id}-${props.elementIndex}`
            : props.videoElement.id,
          props.videoElement.episodes.length,
        );
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: t('Episode was removed'),
              },
            ],
          }),
        );
      });
    }
  };
  const { intermediateAcitivityIds } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const { statsCustomGameListPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { statsCustomGameList } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const makeFilters = () => {
    const teamId = searchParams.get('teamId')?.toString();
    let authorTeamId: string | undefined = '';
    if (searchParams.get('tid')?.toString()) {
      authorTeamId = searchParams.get('tid')?.toString();
    }
    const eventIds = searchParams.getAll('eid');
    const events = searchParams.getAll('eventId');
    const metric = searchParams.getAll('m');
    const metricId = searchParams.getAll('mid');
    const mSearchType = searchParams.get('mst');
    const opposite = searchParams.getAll('o');
    const lastX = searchParams.getAll('lX');
    const seasons = searchParams.getAll('s');
    let activities = [
      ...new Set([...intermediateAcitivityIds, ...events, ...eventIds]),
    ];
    if (!events.length && statsCustomGameListPlayer.length) {
      activities = [...activities, ...statsCustomGameListPlayer];
    }
    if (!events.length && statsCustomGameList.length) {
      activities = [...activities, ...statsCustomGameList];
    }

    const season = searchParams.get('season');
    const playersIds = searchParams.getAll('pl');
    const markupIds = searchParams.getAll('markupId');
    const customAttributes = searchParams.getAll('p');

    let newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (markupIds.length) {
      newFiltersV2.markup_ids = markupIds;
    }
    if (teamId && activities.length > 0) {
      newFiltersV2.games[teamId] = {
        match: activities || [],
      };
    }
    if (eventIds.length && authorTeamId) {
      newFiltersV2.games[authorTeamId] = {
        match: activities || [],
      };
    }
    if (customAttributes && teamId) {
      newFiltersV2 = {
        ...newFiltersV2,
        custom_attributes: customAttributes,
      };
    }
    if (season && teamId) {
      if (season.includes('-')) {
        newFiltersV2.games[teamId] = {
          season: [season.split('-')[1]],
          tournament: [season.split('-')[0]],
        };
      } else if (season === 'manual') {
        console.log('manual');
      } else {
        newFiltersV2.games[teamId] = {
          lastX: season.split('last')[1],
        };
      }
    }
    if (playersIds.length && teamId) {
      newFiltersV2 = {
        ...newFiltersV2,
        players: {
          [teamId]: playersIds,
        },
      };
    }
    if (lastX.length > 0 && teamId) {
      newFiltersV2.games[teamId] = {
        ...newFiltersV2.games[teamId],
        lastX: lastX[0],
      };
    }
    if (metricId.length > 0 && metric.length === 0) {
      newFiltersV2 = {
        ...newFiltersV2,
        attributes: metricId.map((el: any) => Number(el)),
      };
      if (mSearchType) {
        newFiltersV2 = {
          ...newFiltersV2,
          metrics_search_type: mSearchType,
        };
      }
    } else if (metric.length > 0) {
      if (newFiltersV2.custom_attributes) {
        newFiltersV2 = {
          ...newFiltersV2,
          custom_attributes: [...newFiltersV2.custom_attributes, ...metric],
        };
        if (mSearchType) {
          newFiltersV2 = {
            ...newFiltersV2,
            metrics_search_type: mSearchType,
          };
        }
      } else {
        newFiltersV2 = {
          ...newFiltersV2,
          custom_attributes: metric,
        };
        if (mSearchType) {
          newFiltersV2 = {
            ...newFiltersV2,
            metrics_search_type: mSearchType,
          };
        }
      }
    }

    if (authorTeamId) {
      newFiltersV2.author_team_id = authorTeamId;
    }
    if (opposite.length > 0 && opposite[0] === 'true') {
      newFiltersV2.opposite = true;
    }
    if (seasons.length > 0 && teamId && seasons[0] !== 'manual') {
      newFiltersV2.games[teamId] = {
        ...newFiltersV2.games[teamId],
        season: seasons,
      };
    }
    return newFiltersV2;
  };
  const {
    activeTeam,
    selectedTournaments,
    selectedDateLimits,
    selectedPlayers,
    lastXFilter,
    selectedAttributes,
    selectedGames,
    selectedTimingIntervals,
    showMyEpisdes,
    showPlayerGames,
    showPlayerIntervals,
  } = useSelector((state: AppStateType) => state.filtersReducer);
  const makeExtraFilters = () => {
    const newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (
      activeTeam &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0)
    ) {
      newFiltersV2.games[activeTeam] = {};
      if (selectedTournaments.length > 0) {
        newFiltersV2.games[activeTeam]['tournaments'] = selectedTournaments.map(
          (el) => {
            if (el.id) {
              return el.id;
            }
            return el;
          },
        );
      }
      if (Object.keys(selectedDateLimits).length > 0) {
        const shiftedDateLimits: any = {};
        for (const year of Object.keys(selectedDateLimits)) {
          shiftedDateLimits[year] = [
            selectedDateLimits[year][0] + 1,
            selectedDateLimits[year][1],
          ];
        }
        newFiltersV2.games[activeTeam]['date_limits'] = {
          ...shiftedDateLimits,
        };
      }
      if (lastXFilter) {
        newFiltersV2.games[activeTeam]['lastX'] = lastXFilter;
      }
      if (selectedGames) {
        newFiltersV2.games[activeTeam]['match'] = selectedGames;
      }
      if (Object.keys(selectedTimingIntervals).length > 0) {
        newFiltersV2['match_part'] = selectedTimingIntervals;
      }
      if (showMyEpisdes) {
        newFiltersV2['show_my_episodes'] = showMyEpisdes;
      }
      if (showPlayerGames) {
        newFiltersV2['show_player_games'] = showPlayerGames;
      }
      if (showPlayerIntervals) {
        newFiltersV2['show_player_intervals'] = showPlayerIntervals;
      }
      if (selectedPlayers.length > 0) {
        newFiltersV2.players = {};
        newFiltersV2.players[activeTeam] = selectedPlayers;
      }
      if (selectedAttributes.length > 0) {
        newFiltersV2.attributes = selectedAttributes;
      }
      newFiltersV2['count'] = true;
    }
    return newFiltersV2;
  };
  const handleSingleEpisodeDownload = async () => {
    const readyEpisodesIdsList = [];
    const episodeId = props.episode.id;
    if (
      episodeId.startsWith('temp') ||
      props.episode.user_timer ||
      props.episode.user_event_name ||
      props.episode.keyframes
    ) {
      const timer = props.episode.user_timer
        ? props.episode.user_timer
        : props.episode.file_timer;
      const offset =
        props.episode?.user_timer?.started_at -
        props.episode?.game_timer?.started_at;
      const newUserEpisode: any = {
        video: props.episode.video.id,
        file_timer: {
          started_at: timer?.started_at,
          finished_at: timer?.finished_at,
        },
        game_timer: {
          started_at: initTimer(props.episode, 'started_at', true) + offset,
          finished_at: initTimer(props.episode, 'finished_at', true) + offset,
        },
        user_event_name:
          props.episode.user_event_name ||
          props.episode.event_name ||
          props.episode.event_type.name,
        keyframes: props.episode.keyframes,
      };
      const response = await EventsAPI.addNewUserEpisode(newUserEpisode);
      readyEpisodesIdsList.push(response.data.id);
    } else {
      readyEpisodesIdsList.push(episodeId);
    }
    let filters;
    if (playerMode === MODES.episodes) {
      if (searchParams.size) {
        filters = makeFilters();
      } else {
        filters = makeExtraFilters();
      }
    }
    await EventsAPI.createDownloadLink(
      readyEpisodesIdsList,
      [],
      false,
      filters,
      false,
      false,
      false,
    );
    dispatch(
      showNotification({
        notificationParameters: [
          {
            id: generateUUID(),
            text: t('File added to download queue'),
            callbackName: 'openDownloads',
          },
        ],
      }),
    );
  };
  const playlistContextItems: MenuProps['items'] = [
    {
      key: 1,
      label: <div onClick={handleSingleEpisodeDownload}>{t('Download')}</div>,
      disabled: false,
    },
    {
      key: 2,
      label: <div onClick={turnRenameMode}>{t('Rename')}</div>,
      disabled: false,
    },
    {
      key: 3,
      label: <div onClick={turnEditMode}>{t('Change timeline')}</div>,
      disabled: false,
    },
    {
      key: 4,
      label: (
        <div
          onClick={() => {
            removePlaylistEpisodeHandler(
              props.episode.id,
              // props.episode.activity.id,
            );
          }}
        >
          {t('Remove from playlist')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div
          onClick={() => {
            dispatch(
              setEpisodeIdsToAddToPlaylist({ episodeIds: [props.episode.id] }),
            );
            dispatch(AC_SetIsPlayingVideoAction(false));
          }}
        >
          {t('Add to playlist')}
        </div>
      ),
    },
    { key: 6, label: <div>{t('Paint')}</div>, disabled: true },
  ];
  const fullMatchContextItems: MenuProps['items'] = [
    { key: 1, label: <div>{t('Download')}</div>, disabled: false },
    { key: 6, label: <div>{t('Paint')}</div>, disabled: true },
  ];
  const getContextMenuItems = () => {
    if (openedPlaylistId !== null) {
      return { items: playlistContextItems };
    }
    if (!props.episode?.attributes) {
      return { items: getEpisodeContextItems(props.episode) };
    } else {
      return { items: fullMatchContextItems };
    }
  };
  const [newEpisodeName, setNewEpisodeName] = useState(
    props.episode?.user_event_name || props.episode?.event_type?.name || '',
  );
  const renameEpisodeHandler = () => {
    let updatedVideo;
    if (openedPlaylistId && openedPlaylist) {
      const editedPlaylistGame = filterAndIndex(
        openedPlaylist?.playlist_events,
        props.activityId,
        'id',
      );
      const editedEpisodeGame = filterAndIndex(
        editedPlaylistGame.element.episodes,
        props.episode.id,
        'id',
      );
      const updatedPlaylists = {
        ...openedPlaylist,
        playlist_events: [
          ...openedPlaylist.playlist_events.slice(0, editedPlaylistGame.index),
          {
            ...editedPlaylistGame.element,
            episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...editedPlaylistGame?.element.episodes.slice(
                0,
                editedEpisodeGame.index,
              ),
              {
                ...editedEpisodeGame.element,
                user_event_name: newEpisodeName,
                isTemporary: true,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...editedPlaylistGame?.element.episodes.slice(
                editedEpisodeGame.index + 1,
              ),
            ],
          },
          ...openedPlaylist.playlist_events.slice(editedPlaylistGame.index + 1),
        ],
      };
      dispatch(AC_setOpenedPlaylist(updatedPlaylists));
      const updatedPayload = {
        user_event_name: newEpisodeName,
      };
      PlaylistsAPI.updatePlaylistElement(
        openedPlaylistId,
        props.episode.id,
        updatedPayload,
      ).then(() => {
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: t('Episode was edited'),
                callbackName: 'addPlaylistEpisodeToAnotherPlaylist',
                userEpsiodeId: props.episode.id,
              },
            ],
          }),
        );
      });
    } else {
      updatedVideo = filterAndIndex(
        filteredVideos,
        props.videoElement?.id,
        'id',
      );
      let editedEpisode;
      let updatedFilteredVideos: any;
      if (props.episode.id.includes('temp-')) {
        editedEpisode = filterAndIndex(
          updatedVideo?.element?.user_episodes,
          props.episode.id,
          'id',
        );
        updatedFilteredVideos = [
          ...filteredVideos.slice(0, updatedVideo.index),
          {
            ...updatedVideo.element,
            user_episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.user_episodes?.slice(
                0,
                editedEpisode.index,
              ),
              {
                ...editedEpisode.element,
                user_event_name: newEpisodeName,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.user_episodes?.slice(
                editedEpisode.index + 1,
              ),
            ],
          },
          ...filteredVideos.slice(updatedVideo.index + 1),
        ];
      } else {
        editedEpisode = filterAndIndex(
          updatedVideo?.element?.episodes,
          props.episode.id,
          'id',
        );
        updatedFilteredVideos = [
          ...filteredVideos.slice(0, updatedVideo.index),
          {
            ...updatedVideo.element,
            episodes: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.episodes?.slice(0, editedEpisode.index),
              {
                ...editedEpisode.element,
                user_event_name: newEpisodeName,
              },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...updatedVideo?.element.episodes?.slice(editedEpisode.index + 1),
            ],
          },
          ...filteredVideos.slice(updatedVideo.index + 1),
        ];
        dispatch(
          showNotification({
            notificationParameters: [
              {
                id: generateUUID(),
                text: t(
                  'Episode was changed, but changes will be removed after closing or updating episodes list. To keep it add the epside to a playlist',
                ),
                callbackName: 'addToPlaylist',
                userEpsiodeId: props.episode.id,
              },
            ],
          }),
        );
      }
      dispatch(AC_SetFilteredVideosAction(updatedFilteredVideos));
    }
    setRenameMode(false);
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
  };
  const renderPeriodName = (name: string) => {
    if (name.slice(-1) === '1' || name.slice(-1) === '2') {
      return (
        <>
          {Array.from(name)[0]}
          <sup style={{ fontSize: 7, lineHeight: '10px' }}>{name.slice(1)}</sup>
        </>
      );
    }
    return name;
  };
  return (
    <ItemWrapper
      id={props.id}
      className={`episodeElement enabledClickable flex-row ${renameMode ? 'episode-rename-mode-warpper' : ''}
                ${playedEpisode && (playedEpisode?.id === props.episode.id || props?.epsiode?.id === videosListEditMode) && 'played'}`}
    >
      {renameMode && (
        <RenameController
          setNewEpisodeName={setNewEpisodeName}
          newEpisodeName={newEpisodeName}
          setRenameMode={setRenameMode}
          renameEpisodeHandler={renameEpisodeHandler}
        />
      )}
      {!renameMode && props.episode?.user_timer?.started_at ? (
        <div
          ref={localRef}
          className={`baseIconWrapper 
            ${
              props.episode?.keyframes?.length
                ? 'red-dot'
                : (props.episode?.user_timer?.started_at !== undefined &&
                      props.episode?.user_timer?.started_at !== null) ||
                    (props.episode?.user_event_name !== undefined &&
                      props.episode?.user_event_name !== null)
                  ? 'blue-dot'
                  : 'd'
            }`}
          onClick={() => playEpisode()}
          style={{ position: 'relative' }}
        >
          {playedEpisode && playedEpisode?.id === props.id && isPlaying ? (
            <PauseCircle />
          ) : (
            <PlayCircleFilled onClick={() => playEpisode()} />
          )}
        </div>
      ) : (
        !renameMode && (
          <div
            ref={localRef}
            className={`baseIconWrapper 
              ${
                props.episode?.keyframes?.length
                  ? 'red-dot'
                  : props.episode?.user_timer?.started_at !== undefined ||
                      (props.episode?.user_event_name !== undefined &&
                        props.episode?.user_event_name !== null)
                    ? 'blue-dot'
                    : 'd'
              }`}
            onClick={() => playEpisode()}
            style={{ position: 'relative' }}
          >
            {playedEpisode && playedEpisode?.id === props.id && isPlaying ? (
              <PauseCircle />
            ) : (
              <PlayCircleFilled onClick={() => playEpisode()} />
            )}
          </div>
        )
      )}
      {!renameMode && (
        <>
          <div
            className={'flex-row f-ga-8 ai-c'}
            onClick={() => playEpisode()}
            style={{ alignSelf: 'stretch', width: '100%', overflow: 'hidden' }}
          >
            <div className={'flex-row ai-c f-ga-4'}>
              <div className="period-name">
                {renderPeriodName(t(props.episode.period_name))}
              </div>
              {renderTimeElement(startedAtGameValue)}
              &nbsp;-&nbsp;
              {renderTimeElement(finishedAtGameValue)}
            </div>
            {props.type === 'games'
              ? getElementName()
              : getPlaylistElementName()}
          </div>
          <div className={'flex-row f-ga-8 ai-c'}>
            {!props.isShared && (
              <>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgElevated: 'var(--colorBgSpotlight)',
                      colorText: 'var(--colorText)',
                    },
                  }}
                >
                  <Dropdown
                    menu={getContextMenuItems()}
                    trigger={['click']}
                    overlayStyle={{ maxWidth: '183px', width: 183 }}
                    placement="bottomRight"
                  >
                    <ElipsisVertical width={24} />
                  </Dropdown>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgContainer: '#141414',
                      colorBorder: '#424242',
                      lineWidth: 1,
                    },
                  }}
                >
                  <Checkbox
                    checked={isChecked(props.episode)}
                    onChange={(e) =>
                      props.toggleEpisodeSelection(
                        e.target.checked,
                        props.episode.id,
                        playerMode === 'episodes'
                          ? `${props.videoElement.id}-${props.elementIndex}`
                          : props.videoElement.id,
                        props.videoElement.episodes.length,
                      )
                    }
                  />
                </ConfigProvider>
              </>
            )}
          </div>
        </>
      )}
    </ItemWrapper>
  );
});
export default VideoListElement;
