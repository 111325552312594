import React from 'react';

import { Button, ConfigProvider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AC_SetDownloadEpisodesAction } from '../../../actions/player.acitons';
import { ReactComponent as CloseCross } from '../../../assets/img/icons/faCloseCross.svg';
import { AppStateType } from '../../../reducers';
import { useAppDispatch } from '../../../store';

const DownloadEpisodesQuestionModal = ({
  constructDowloadLinkRequest,
  resultConfirmationModalHandle,
}: {
  constructDowloadLinkRequest: any;
  resultConfirmationModalHandle: any;
}) => {
  const { downloadEpisodesModalMode } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const close = () => {
    dispatch(AC_SetDownloadEpisodesAction(null));
  };
  const noMerge = () => {
    constructDowloadLinkRequest(
      false,
      false,
      false,
      downloadEpisodesModalMode === 'reels',
    );
    close();
    resultConfirmationModalHandle(true);
  };
  const mergeVideos = () => {
    constructDowloadLinkRequest(
      true,
      false,
      false,
      downloadEpisodesModalMode === 'reels',
    );
    close();
    resultConfirmationModalHandle(true);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'var(--colorBgLayout)',
            headerBg: 'var(--colorBgLayout)',

            titleColor: 'var(--colorText)',
            colorText: 'var(--colorText)',
            paddingContentHorizontal: 12,
          },
        },
      }}
    >
      <Modal
        title={t('Combine videos to single file?')}
        open={downloadEpisodesModalMode !== null}
        closable
        width={400}
        style={{ maxHeight: '212px' }}
        closeIcon={<CloseCross />}
        onCancel={close}
        className="p32"
        styles={{
          content: {
            padding: 32,
            width: 400,
          },
        }}
        footer={[
          <div className="flex-row f-ga-8 mt32">
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: 'var(--colorBgContainer)',
                    colorText: 'var(--colorText)',
                    colorBorder: 'var(--colorBorder)',
                    defaultHoverBg: 'var(--colorBgContainer)',
                    defaultActiveBg: 'var(--colorBgContainer)',
                  },
                },
              }}
            >
              <Button style={{ flex: 1 }} type="default" onClick={noMerge}>
                {t('Do not combine')}
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorTextDisabled: 'var(--colorTextDisabled)',
                    borderColorDisabled: 'var(--colorBorder)',
                  },
                },
              }}
            >
              <Button style={{ flex: 1 }} type="primary" onClick={mergeVideos}>
                {t('Yes, combine')}
              </Button>
            </ConfigProvider>
          </div>,
        ]}
      >
        <>
          {t(
            'If you choose not to combine, each episode will be downloaded as a single file.',
          )}
        </>
      </Modal>
    </ConfigProvider>
  );
};
export default DownloadEpisodesQuestionModal;
