/* eslint-disable quotes */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  en: {
    translation: {
      '2D projection': '2D projection',
      A: 'A',
      'M-header': 'M',
      'W-header': 'W',
      'D-header': 'D',
      'L-header': 'L',
      'G-header': 'G',
      'GD-header': 'GD',
      'P-header': 'P',
      // eslint-disable-next-line quotes
      '1st period': "1'",
      '2nd period': "2'",
      '1st half extra time': 'E1',
      '2nd half extra time': 'E2',
      'Penalties period': 'P',
      'A new playlist will be created:': 'A new playlist will be created:',
      Add: 'Add',
      'Add episode': 'Add episode',
      'Add new team': 'Add new team',
      'Additional time': 'Additional time',
      'Additional time 1': 'Additional time 1',
      'Additional time 2': 'Additional time 2',
      'Already have an account?': 'Already have an account?',
      'Another email': 'Another email',
      'Another event is already planned for this time. Please select another time.':
        'Another event is already planned for this time. Please select another time.',
      'Apply filter': 'Apply filter',
      April: 'April',
      'April-a': 'April',
      'Are you sure you want to delete this playlist?':
        'Are you sure you want to delete this playlist?',
      August: 'August',
      'August-a': 'August',
      Away: 'Away',
      Camera: 'Camera',
      'Duration downloads': 'Duration',
      'Camera check': 'Camera check',
      'Camera is not avaliable': 'Camera is not avaliable',
      Cancel: 'Cancel',
      'Cancel ss': 'Cancel',
      'Capital latin letters': 'Capital latin letters',
      'Check camera': 'Check camera',
      City: 'City',
      Clear: 'Clear',
      'Combine videos to single file?': 'Combine videos to single file?',
      Confirm: 'Confirm',
      Consecutive: 'Consecutive',
      Counterpart: 'Counterpart',
      Highlights: 'Highlights',
      'Create link': 'Create link',
      Dashboard: 'Dashboard',
      December: 'December',
      'December-a': 'December',
      Defence: 'Defence',
      Defences: 'Defences',
      Delete: 'Delete',
      'File {} is ready for download': 'File {}" is ready for download',
      'Error while processing file download {}':
        'Error while processing file download "{}"',
      'Delete event?': 'Delete event?',
      'Delete playlist?': 'Delete playlist?',
      'Delete record?': 'Delete record?',
      Description: 'Description',
      Digits: 'Digits',
      'Disable link': 'Disable link',
      Done: 'Done',
      'Download match?': 'Download match?',
      Draw: 'Draw',
      'Delete selected from playlist': 'Delete selected from playlist',
      'Edit event': 'Edit event',
      'Email has been sent': 'Письмо отправлено',
      Enter: 'Enter',
      'Enter email:': 'Enter email:',
      'Enter password:': 'Enter password:',
      'Enter playlist name': 'Enter playlist name',
      'Enter team name': 'Enter team name',
      'Entire match': 'Entire match',
      Episodes: 'Episodes',
      'Episode name': 'Episode name',
      'episodes-1': 'episode',
      'episodes-2': 'episodes',
      'episodes-other': 'episodes',
      'episodes plural': 'episodes',
      Events: 'Events',
      February: 'February',
      'File added to download queue': 'Файл добавлен в очередь на обработку',
      'February-a': 'February',
      'File size should not exceed ': 'File size should not exceed ',
      'File with this name already uploaded to this activity':
        'File with this name already uploaded to this activity',
      'Finish time': 'Finish time',
      'First period': 'First period',
      'Forgot password?': 'Forgot password?',
      Fr: 'Fr',
      'Full screen mode': 'Full screen mode',
      Goalkeeper: 'Goalkeepers',
      Goalkeepers: 'Goalkeepers',
      H: 'H',
      'Half-back': 'Half-back',
      'Half-backs': 'Half-backs',
      Help: 'Help',
      Hide: 'Hide',
      Home: 'Home',
      'Home / away': 'Home / away',
      'I agree that my': 'Make a new password:',
      'If you were registered with your email':
        'If you were registered with your email',
      'If you choose not to combine, each episode will be downloaded as a single file.':
        'If you choose not to combine, each episode will be downloaded as a single file.',
      January: 'January',
      'January-a': 'January',
      July: 'July',
      'July-a': 'July',
      June: 'June',
      'June-a': 'June',
      'Last 10 matches': 'Last 10 matches',
      'Last 5 matches': 'Last 5 matches',
      'Last match': 'Last match',
      'left leg': 'left',
      'right leg': 'right',
      'both leg': 'both',

      'Link has expired':
        'Link has expired. Please, ask for a new password recovery link.',
      'Link was copied': 'Link was copied',
      'Log in': 'Log in',
      'Login or password is wrong': 'Login or password is wrong',
      Loss: 'Loss',
      'Make a new password:': 'Make a new password:',
      March: 'March',
      'March-a': 'March',
      Markup: 'Markup',
      Match: 'Match',
      'Match markup': 'Match markup',
      'Match record': 'Match record',
      Matches: 'Matches',
      'matches-1': 'match',
      'matches-2': 'matches',
      'matches-other': 'matches',
      'Matches and trainings': 'Matches and trainings',
      May: 'May',
      'May-a': 'May',
      Mb: 'Mb',
      Metrics: 'Metrics',
      metrics: 'Metrics',
      Mo: 'Mo',
      'NOT FOUND!': '404. Page not found',
      'New event': 'New event',
      'Next filter': 'Next filter',
      No: 'No',
      'Not chosen': 'Not chosen',
      'Not less than 8 symbols': 'Not less than 8 symbols',
      'Not selected': 'Not selected',
      'No selected events.': 'No selected events.',
      'Not unique playlist name': 'Not unique playlist name',
      November: 'November',
      'November-a': 'November',
      OK: 'OK',
      October: 'October',
      'October-a': 'October',
      Offence: 'Offence',
      Offences: 'Offences',
      'Panoramic view': 'Panoramic view',
      'Password was changed': 'Password was changed',
      'Passwords do not match': 'Passwords do not match',
      Players: 'Players',
      'Players matching': 'Players matching',
      Playlist: 'Playlist',
      Playlists: 'Playlists',
      'Please enter valid email': 'Please enter valid email',
      'Please stand by': 'Please stand by',
      Protocol: 'Protocol',
      'Record event': 'Record event',
      Recover: 'Recover',
      'Recover password': 'Recover password',
      Register: 'Register',
      Registration: 'Registration',
      'Repeat password': 'Repeat password:',
      'Required field': 'Required field',
      Results: 'Results',
      Sa: 'Sa',
      Save: 'Save',
      Season: 'Season',
      'Second period': 'Second period',
      Select: 'Select',
      Standards: 'Standards',
      'Select a team or add new': 'Select a team or add new',
      'Select all': 'Select all',
      'Select another time or camera': 'Select another time or camera',
      'Select current team to create an event':
        'Select current team to create an event',
      'Select episode name or add new': 'Select episode name or add new',
      'Select playlist': 'Select playlist',
      'Select team': 'Select team',
      'Select tournament': 'Select tournament',
      September: 'September',
      'September-a': 'September',
      'Setup in application': 'Setup in application',
      'Show more': 'Show more',
      'Small latin letters': 'Small latin letters',
      Speed: 'Speed',
      'Start recording': 'Start recording',
      'Start time': 'Start time',
      Statictics: 'Statictics',
      'unset leg': 'no data',
      Statistics: 'Statistics',
      Stop: 'Stop',
      'Stop video recording?': 'Stop video recording?',
      Su: 'Su',
      'Teams and players': 'Teams and players',
      Th: 'Th',
      'Time is occupied': 'Time is occupied',
      'Time zone': 'Time zone',
      Timing: 'Timing',
      'To playlist': 'To playlist',
      Today: 'Today',
      Tournaments: 'Tournaments',
      Training: 'Training',
      Trainings: 'Trainings',
      Tu: 'Tu',
      'Upload video or markup': 'Upload video or markup',
      Watch: 'Watch',
      We: 'We',
      Win: 'Win',
      Year: 'Year',
      'Yes, combine': 'Yes, combine',
      'Yes, download': 'Yes, download',
      'You can apply filters to get a list of events.':
        'You can apply filters to get a list of events.',
      'You cannot restore event.': 'You cannot restore event.',
      'You cannot restore playlist.': 'You cannot restore playlist',
      'You cannot restore record.': 'You cannot restore record.',
      'You cannot resume record.': 'You cannоt resume record.',
      'You do not have active club set. Please contact technical support.':
        'You do not have active club set. Please contact technical support.',
      'You will receive an email with a link to download the archive with selected videos.':
        'You will receive an email with a link to download the archive with selected videos.',
      'Your episodes': 'Your episodes',
      apr: 'apr',
      forward__: 'forward',
      'as of': 'as of',
      aug: 'aug',
      dec: 'dec',
      events: 'events',
      feb: 'feb',
      from: 'from',
      here: 'here',
      'is busy': 'is busy',
      jan: 'jan',
      jul: 'jul',
      jun: 'jun',
      mar: 'mar',
      may: 'may',
      month: 'month',
      more: 'more',
      nov: 'nov',
      oct: 'oct',
      years_many: 'years',
      years_one: 'year',
      years_two: 'years',
      years_three: 'years',
      years_four: 'years',
      years_few: 'years',
      years_other: 'years',
      'Apply a': 'Apply',
      'personal data will be processed': ' personal data will be processed',
      s: 's',
      sep: 'sep',
      to: 'to',
      'we shall send you a message with password recover link.':
        'we shall send you a message with password recover link.',
      year: 'year',
      'We need to wait': 'We need to wait',
      goalkeeper: 'GK',
      forward: 'FW',
      'centre forward': 'CF',
      defender: 'DEF',
      'left defender': 'LD',
      'right defender': 'RD',
      'central defender': 'CD',
      midfielder: 'MDF',
      'central midfielder': 'CM',
      'defensive midfielder': 'DM',
      'left midfielder': 'LM',
      'right midfielder': 'RM',
      'attacking midfielder': 'AM',
    },
  },
  ru: {
    translation: {
      '2D projection': '2D проекция',
      '10 last games': '10 последних матчей',
      '5 last games': '5 последних матчей',
      '1st period': "1'",
      '2nd period': "2'",
      '1st half extra time': 'Д1',
      '2nd half extra time': 'Д2',
      'Penalties period': 'П',
      A: 'Г',
      'M-header': 'М',
      'W-header': 'В',
      'D-header': 'Н',
      'L-header': 'П',
      'G-header': 'Г',
      'GD-header': 'РГ',
      'P-header': 'О',
      'A new playlist will be created:': 'Будет создан:',
      Add: 'Добавить',
      'All actions': 'Все действия',
      'Add to favorites': 'Добавить в избранное',
      'Add episode': 'Добавить событие',
      'Add new team': 'Добавить команду',
      'Additional time': 'Дополнительное время',
      'Additional time 1': 'Дополнительное время 1',
      'Additional time 2': 'Дополнительное время 2',
      'Already have an account?': 'Уже есть аккаунт?',
      'Add key frame': 'Добавить ключевой кадр',
      'Add to playlist': 'Добавить в плейлист',
      'Add to another playlist': 'Добавить в другой плейлист',
      'Add selected to playlist': 'Добавить выбранные в плейлист',
      'All playlists from this folder will be saved to root':
        'Все плейлисты из этой папки будут сохранены',
      'Another email': 'Другая почта',
      'Another event is already planned for this time. Please select another time.':
        'На это время уже запланировано событие. Выберите другое время.',
      'Apply filter': 'Применить фильтр',
      'Apply a': 'Применить',
      April: 'Апрель',
      Age: 'Возраст',
      'April-a': 'Апреля',
      'Are you sure you want to delete this playlist?':
        'Вы уверены что хотите удалить этот плейлист?',
      'Are you sure you want to delete this download?':
        'Вы уверены что хотите удалить эту выгрузку?',
      'All events': 'Все события',
      'Goals + Assists': 'Гол + Пас',
      August: 'Август',
      'August-a': 'Августа',
      Away: 'В гостях',
      'Ball in game': 'Мяч в игре',
      'Best players': 'Лучшие игроки',
      Camera: 'Камера',
      '> 1 min': '> 1 мин',
      'Camera check': 'Проверить камеру',
      'Camera is not avaliable': 'Камера в выбранное время недоступна',
      'not called up': 'не вызывается',
      'Cancel ss': 'Отменить',
      Cancel: 'Отмена',
      Birthday: 'Дата рождения',
      'Capital latin letters': 'Большие латинские буквы',
      'Check camera': 'Проверить камеру',
      'Change time timeline': 'Изменить временные границы',
      City: 'Город',
      'Contract expires': 'Контракт до',
      Clear: 'Сбросить',
      'Combine videos to single file?':
        'Объединить выбранные видео в один файл?',
      Confirm: 'Подтвердить',
      'Contact us': 'Контакты',
      Consecutive: 'Подряд',
      Corners: 'Угловые',
      Counterpart: 'Соперник',
      'Created date': ' Создано',
      'Delete selected from playlist': 'Удалить выбранные из плейлиста',
      Highlights: 'Хайлайты',
      'Create link': 'Создать ссылку',
      'Create episode': 'Создать эпизод',
      'Change timeline': 'Изменить временные границы',
      Dashboard: 'Панель администратора',
      December: 'Декабрь',
      'December-a': 'Декабря',
      Defence: 'Защитник',
      Defences: 'Защитники',
      Delete: 'Удалить',
      'Delete event?': 'Удалить событие?',
      'Delete folder': 'Удалить папку',
      'Delete playlist?': 'Удалить плейлист?',
      'Delete record?': 'Удалить запись?',
      Description: 'Описание',
      Digits: 'Цифры',
      'Disable link': 'Отключить ссылку',
      Done: 'Готово',
      done: 'Готово',
      expired: 'Истекло',
      'unset leg': 'нет данных',
      'Do not combine': 'Не объединять',
      Leg: 'Нога',
      Download: 'Скачать',
      Downloads: 'Выгрузки',
      highlights: 'В формате хайлайтов',
      reels: 'В формате Reels',
      'single file': 'Объединить в один файл',
      'one episode': 'Один эпизод',
      'separate files': 'Каждый эпизод отдельным файлом',
      'Download match?': 'Скачать матч: ?',
      Draw: 'Ничья',
      'Direct free kicks': 'Штрафные прямые',
      'Indirect free kicks': 'Штрафные с розыгрышем',
      Dribbling: 'Обводки',
      Dribbles: 'Обводки',
      'Delete download?': 'Удалить выгрузку?',
      'Duel ground': 'Ед. внизу',
      'Duel aerial': 'Ед. вверху',
      'Duel ground offence': 'Едб. в атаке внизу 	',
      'Duel ground defence': 'Едб. в обороне внизу 	',
      'Duel aerial offence': 'Едб. в атаке вверху	',
      'Duel aerial defence': 'Едб. в обороне вверху	',
      Duration: 'Длительность',
      'Duration downloads': 'Длина',
      Duels: 'Единоборства',
      'Edit event': 'Редактировать событие',
      'Email has been sent': 'Письмо отправлено',
      Enter: 'Войти',
      'Enter email:': 'Введите почту:',
      'Enter password:': 'Введите пароль:',
      'Enter playlist name': 'Ведите название плейлиста',
      'Enter team name': 'Ведите название команды',
      'Entire match': 'Полный матч',
      Episodes: 'Эпизоды',
      'Episode was added to the playlist': 'Эпизод добавлен в плейлист',
      'Episode was removed': 'Эпизод удален',
      'Episode was edited': 'Эпизод отредактирован',
      'Episode was added to playlist': 'Эпизод добавлен в плейлист',
      'Episode was added to': 'Эпизод добавлен в',
      'Episode was created, but will be removed after closing or updating episodes list. To keep it add the epside to a playlist':
        'Эпизод создан, но будет удален после закрытия списка эпизодов. Чтобы сохранить его, добавьте его в плейлист',
      'Episode was changed, but changes will be removed after closing or updating episodes list. To keep it add the epside to a playlist':
        'Эпизод изменен, но правки будут удалены после закрытия списка эпизодов. Чтобы сохранить их, добавьте его в плейлист',
      'episodes-1': 'эпизод',
      'episodes-2': 'эпизода',
      'episodes-other': 'эпизодов',
      'episodes plural': 'эпизодов',
      'Episode name': 'название события',
      error: 'Ошибка',
      Error: 'Грубые ошибки',
      Events: 'События',
      'Episodes search': 'Поиск эпизодов',
      February: 'Февраль',
      'February-a': 'Февраля',
      'Fast attacks': 'Быстрые атаки',
      'File size should not exceed ': 'Файл не должен превышать',
      'File with this name already uploaded to this activity':
        'Файл с таким названием уже загружен в это событие',
      'Finish time': 'время окончания',
      'First period': 'Первый тайм',
      'Folder was deleted': 'Папка удалена',
      'Forgot password?': 'Забыли пароль?',
      Fouls: 'Фолы',
      'Fouls suffered': 'Фолы заработанные',
      Fr: 'Пт',
      'Free kicks': 'Штрафные',
      'Full screen mode': 'Полноэкранный режим',
      Goalkeeper: 'Вратарь',
      Goalkeepers: 'Вратари',
      Goals: 'Голы',
      'Go to downloads': 'Перейти к загрузкам',
      'File {} is ready for download': 'Файл {}" готов к загрузке',
      'Error while processing file download {}':
        'Ошибка подготовки к загрузке файла "{}"',
      'ground defensive': 'в обороне внизу',
      'ground offensive': 'в атаке внизу',
      'aerial defensive': 'в обороне вверху',
      'aerial offensive': 'в атаке вверху',
      H: 'Д',
      'inaccurate passes': 'неточные передачи',
      'bad 1st touches': 'неудачные обработки',
      'unsuccessful dribbles': 'неудачные обводки',
      "opponent's tackles": 'отборы оппонента',
      'Half-back': 'Полузащитник',
      'Half-backs': 'Полузащитники',
      Help: 'Справка',
      Height: 'Рост',
      Hide: 'Скрыть',
      Home: 'Дома',
      'in queue': 'В очереди',
      'out of quota': 'Лимит исчерпан',
      'Home / away': 'Дома/в гостях',
      'I agree that my': 'Я согласен на ',
      'If you were registered with your email':
        'Если вы были зарегистрированы по почте',
      'If you choose not to combine, each episode will be downloaded as a single file.':
        'Если не объединять, эпизоды будут скачаны отдельными файлами',
      Interception: 'Перехват',
      Interceptions: 'Перехваты',
      January: 'Январь',
      'January-a': 'Января',
      July: 'Июль',
      'July-a': 'Июля',
      June: 'Июнь',
      'June-a': 'Июня',
      Create: 'Создать',
      'New episode': 'Новый эпизод',
      'Key passes': 'Ключ. передачи',
      key: 'ключевые',
      'Last 10 matches': 'Последние 10 матчей',
      'Last 5 matches': 'Последние 5 матчей',
      'Last match': 'Последний матч',
      'Link has expired':
        'Срок действия ссылки истек или ссылка невалидна. Запросите ссылку на восстановление пароля повторно',
      'Link was copied': 'Ссылка скопирована',
      'List of top 5 players will be here as soon as the team starts scoring':
        'Список из 5 лучших игроков появится как только команда начнет забивать',
      'List of top 5 players will be here as soon as we load the data':
        'Список из 5 лучших игроков появится, когда у нас будут данные по забитым голам',
      'Log in': 'Войти',
      Logout: 'Выйти',
      'Login or password is wrong': 'Указаные логин или пароль не верные',
      Loss: 'Поражение',
      Losses: 'Потери',
      'Make a new password:': 'Придумайте пароль:',
      'Manual ordering': 'Вручную',
      March: 'Март',
      'March-a': 'Марта',
      Markup: 'Разметка',
      Match: 'Матч',
      'Match markup': 'Разметка матча',
      'Match record': 'Запись матча',
      Matches: 'Матчи',
      matches: 'Матчи',
      'matches-1': ' матч',
      'matches-2': ' матча',
      'matches-other': ' матчей',
      'Matches and trainings': 'Матчи и тренировки',
      May: 'Май',
      'May-a': 'Мая',
      Mb: 'Мб',
      Metrics: 'Метрики',
      metrics: 'Метрики',
      Mistakes: 'Грубые ошибки',
      Mo: 'Пн',
      'left leg': 'левая',
      'right leg': 'правая',
      'both leg': 'обе',
      Move: 'Переместить',
      'Move outside folders': 'Поместить вне папок',
      'My episodes': 'Мои эпизоды',
      'n/a': 'н/д',
      Name: 'Название',
      'National team': 'Сборная',
      'NOT FOUND!': '404. sСтраница не найдена',
      'New event': 'Новое событие',
      No: 'Нет',
      'no data': 'нет данных',
      'Not chosen': 'Не выбрано',
      'Not less than 8 symbols': 'Не менее 8 знаков',
      'Not selected': 'Не выбрано',
      'Not unique playlist name': 'Не уникальное название плейлиста',
      'No selected events.': 'События не выбраны.',
      'Nothing is being played': 'Ничего не воспроизводится',
      'Nothing found.': 'Ничего не найдено.',
      'Try to change search request.': 'Попробуйте изменить поисковой запрос.',
      'per 90 min': 'В ср. на 90 мин.',
      'Turn the necessary episode on the right panel, or select several episodes':
        'Включите нужный фрагмент в панели справа, или выберите несколько.',
      'Select the episode to play on the panel on the right':
        'Добавляйте фрагменты в список воспроизведения чтобы настроить очередность',
      'Next filter': 'След. фильтр',
      'Move to folder': 'Переместить в папку',
      November: 'Ноябрь',
      'No data on weight': 'Нет данных о весе',
      'No data on height': 'Нет данных о росте',
      'No data about role': 'Нет данных о роли',
      'No data about age': 'Нет данных о возрасте',
      'No data on national team': 'Нет данных об участии в сборной',
      'No data about dominant leg': 'Нет данных ведущей ноге',
      'New folder': 'Новая папка',
      'New playlist': 'Новый плейлист',
      'November-a': 'Ноября',
      OK: 'Хорошо',
      October: 'Октябрь',
      'October-a': 'Октября',
      Offence: 'Защитник',
      Offences: 'Защитники',
      Opponents: 'Оппоненты',
      Overview: 'Обзор',
      'Throw-ins': 'Ауты',
      'Panoramic view': 'Панорама',
      'Password was changed': 'Пароль успешно изменен',
      'Passwords do not match': 'Введенные пароли не совпадают',
      'Pass for goal': 'Гол. передачи',
      Passes: 'Передачи',
      forward__: 'вперед',
      'Pass progressive': 'Продвиг. передачи	',
      'Pass to 3rd': 'Передачи в фин. треть',
      'to 3/3': 'в фин. треть',
      'Pass to box': 'Передачи в штрафную',
      'to pen. area': 'в штрафную',
      'Pass Cross': 'Кроссы',
      crosses: 'кроссы',
      long: 'длинные',
      Assists: 'Голевые передачи',
      Paint: 'Рисовать',
      Penalties: 'Пенальти',
      Players: 'Игроки',
      players: 'Игроки',
      'Played time': 'Сыгранное время',
      'Players matching': 'Привязка игроков',
      Playlist: 'Плейлист',
      Playlists: 'Плейлисты',
      playlists_one: 'плейлист',
      playlists_two: 'плейлиста',
      playlists_few: 'плейлиста',
      playlists_other: 'плейлистов',
      playlists_many: 'плейлистов',
      'Playlist with this name already exists':
        'Плейлист с таким названием уже существует',
      'Please enter valid email': 'Введите настояющую почту',
      'Please stand by': 'Необходимо немного подождать',
      Protocol: 'Протокол',
      progressive: 'продвигающие',
      'Positional attacks': 'Позиционные атаки',
      Possession: 'Владение',
      Position: 'Позиция',
      'Playlist was changed': 'Плейлист изменен',
      'Previous episode CTRL + <-': 'Предыдущий эпизод CTRL + <-',
      'Previous episode CMD + <-': 'Предыдущий эпизод CMD + <-',
      'Previous keyframe CTRL + <-': 'Предыдущий кадр CTRL + <-',
      'Previous keyframe CMD + <-': 'Предыдущий кадр CMD + <-',
      'New first': 'Сначала новые',
      'Next episode CTRL + ->': 'Следующий эпизод CTRL + ->',
      'Next episode CMD + ->': 'Следующий эпизод CMD + ->',
      'Next keyframe CTRL + ->': 'Следующий кадр CTRL + ->',
      'Next keyframe CMD + ->': 'Следующий кадр CMD + ->',
      Quality: 'Качество',
      RC: 'КК',
      'Record event': 'Записать событие',
      Recover: 'Восстановить',
      '2nd balls': 'Подборы',
      'Recover password': 'Восстановить пароль',
      'Reels format': 'В формате Reels',
      Register: 'Зарегистрироваться',
      Registration: 'Регистрация',
      'Repeat password': 'Повторите пароль:',
      'Required field': 'Обязательное поле',
      Results: 'Результаты',
      Rename: 'Переименовать',
      'Rewind step': 'Шаг перемотки',
      'Rewind step 1s': 'Шаг перемотки 1c',
      'Rewind step 2s': 'Шаг перемотки 2c',
      'Rewind step 3s': 'Шаг перемотки 3c',
      'Rewind step 4s': 'Шаг перемотки 4c',
      'Rewind step 5s': 'Шаг перемотки 5c',
      'Rewind step 10s': 'Шаг перемотки 10c',
      'Revert all changes': 'Отменить все изменения',
      'Reorder by time': 'Сортировать эпизоды по времени',
      'Remove from playlist': 'Удалить из плейлиста',
      Role: 'Роль',
      Sa: 'Сб',
      Save: 'Сохранить',
      'Simple download': 'Скачать',
      Season: 'Сезон',
      Standards: 'Стандарты',
      'Start lineup': 'Стартовый состав',
      'Search for teams and players': 'Поиск игроков и команд',
      'Second period': 'Второй тайм',
      sec: 'сек',
      Select: 'Выбрать',
      'Select matches': 'Выбрать матчи',
      'Select a team or add new': 'Выбрать команду или добавить новую',
      'Select all': 'Выбрать всех',
      'Select another time or camera': 'Выберите другое время или камеру.',
      'Select current team to create an event':
        'Выберите текущую команду для создания события',
      'Select episode name or add new': 'Выберите событие или создайте новое',
      'Select playlist': 'Выбрать плейлист',
      'Select team': 'Выберите команду',
      'Select tournament': 'Выберите турнир',
      September: 'Сентябрь',
      'September-a': 'Сентября',
      'Setup in application': 'Настройка в приложении',
      Share: 'Поделиться',
      'Show all actions': 'Показать точки всех ТТД',
      'Share selected': 'Поделиться выбранными',
      'Shots on goal': 'Удары в створ',
      'Show more': 'Еще',
      'Show all players': 'Показать всех игроков',
      Shots: 'Удары',
      'Shots blocked': 'Удары заблокированные',
      'Small latin letters': 'Маленькие латинские буквы',
      'Sorting updated to "Manual"': 'Сортировка изменена на "Ручная"',
      'Sorting updated to "New first"': 'Сортировка изменена "Сначала новые"',
      Speed: 'Скорость',
      'SOTA Platform': 'Платформа SOTA',
      'Start recording': 'Начать съемку сейчаc',
      'Start time': 'время начала',
      Statictics: 'Статистика',
      'Stand.': 'Станд.',
      Statistics: 'Статистика',
      Stop: 'Остановить',
      'Stop video recording?': 'Остановить запись события?',
      Su: 'Вс',
      'Substitution lineup': 'Запасные игроки',
      Tackle: 'Отбор',
      Tackles: 'Отборы',
      'Tackle 1-1': 'Отборы 1в1',
      'Tackles 1v1': 'Отборы 1в1',
      Team: 'Команда',
      'Teams and players': 'Команды и игроки',
      Teams: 'Команды',
      Th: 'Чт',
      'This metric calculation in planned for future releases':
        'Отображение данной метрики запланировано в следующих релизах',
      'Time is occupied': 'Время занято',
      'Time zone': 'Часовой пояс',
      Timing: 'Время',
      timing: 'Время',
      'Time played': 'Сыграно',
      'To playlist': 'В плейлист',
      Today: 'Сегодня',
      Total: 'Всего',
      'Total selected Reels duration exceeds 60 seconds':
        'Общая продолжительность эпизодов в формате Reels больше 60 секунд ',
      Tournaments: 'Турниры',
      Training: 'Тренировка',
      Trainings: 'Тренировки',
      Tu: 'Вт',
      'Type player, team or tournament name':
        'Начните вводить имя игрока, название команды или турнира',
      'Upload video or markup': 'Загрузите видео или разметку',
      uploading: 'Загрузка',
      'View episodes': 'Показать эпизоды',
      We: 'Ср',
      Watch: 'Смотреть',
      Win: 'Победа',
      YC: 'ЖК',
      Year: 'Год',
      'Yes, combine': 'Да, объединить',
      'Yes, download': 'Да, скачать',
      'You can apply filters to get a list of events.':
        'Вы можете настроить фильтры, чтобы получить список событий',
      'You cannot restore event.': 'Восстановить событие будет невозможно.',
      'You cannot restore playlist.': 'Восстановить плейлист будет невозможно.',
      'You cannot restore record.': 'Восстановить запись будет невозможно.',
      'You cannot resume record.': 'Продолжить запись будет невозможно.',
      'You do not have active club set. Please contact technical support.':
        'У вас не установлен активный клуб. Обратитесь в техническую поддержку',
      'You will receive an email with a link to download the archive with selected videos.':
        'На вашу почту придет ссылка для скачивания выбранных видеоэпизодов',
      'Your episodes': 'Ваши эпизоды',
      Weight: 'Вес',
      years_one: 'год',
      years_two: 'года',
      years_three: 'года',
      years_four: 'года',
      years_few: 'лет',
      years_other: 'лет',
      years_many: 'лет',

      apr: 'апр',
      'as of': 'на',
      aug: 'авг',
      cm: 'см',
      kg: 'кг',
      dec: 'дек',
      events: 'событий',
      feb: 'фев',
      from: 'от',
      here: 'здесь',
      'is busy': 'занята',
      jan: 'янв',
      jul: 'июль',
      jun: 'июнь',
      mar: 'март',
      may: 'май',
      month: 'месяц',
      more: 'еще',
      nov: 'нояб',
      oct: 'окт',
      'personal data will be processed': '',
      s: 'с',
      sep: 'сент',
      to: 'до',
      'we shall send you a message with password recover link.':
        'то мы отправим вам письмо с ссылкой для восстановления пароля.',
      year: 'год',
      'We need to wait': 'Необходимо немного подождать',
      'You will receive and email to with a link to download the selected episodes':
        'На вашу почту придет ссылка для скачивания выбранных видеоэпизодов',
      '> 15 min': '> 15 мин',
      '> 30 min': '> 30 мин',
      '> 45 min': '> 45 мин',
      '> 60 min': '> 60 мин',
      '> 75 min': '> 75 мин',
      '> 90 min': '> 90 мин',
      'All formations': 'Все схемы',
      'All positions': 'Все позиции',
      Actions: 'ТТД',

      goalkeeper: 'ВР',
      forward: 'Н',
      'centre forward': 'ЦН',
      defender: 'ЗАЩ',
      'left defender': 'ЛЗ',
      'right defender': 'ПЗ',
      'central defender': 'ЦЗ',
      midfielder: 'ПЗ',
      'central midfielder': 'ЦП',
      'defensive midfielder': 'ОП',
      'left midfielder': 'ЛП',
      'right midfielder': 'ПП',
      'attacking midfielder': 'АП',
    },
  },
  pt: {
    translation: {
      '2D projection': 'Projeção 2D',
      A: 'F',
      'A new playlist will be created:': 'Uma nova playlist será criada',
      Add: 'Adicionar',
      'Add episode': 'Add episode',
      'Add new team': 'Adicionar uma nova equipe',
      'Additional time': 'Prorrogação',
      'Additional time 1': 'Prorrogação 1',
      'Additional time 2': 'Prorrogação 2',
      'Already have an account?': 'Você já tem uma conta?',
      'Another email': 'Outro e-mail',
      'Another event is already planned for this time. Please select another time.':
        'Há outro evento já agendado para este horário. Por favor, escolha outro horário',
      'Apply filter': 'Aplicar filtro',
      April: 'Abril',
      'April-a': 'Abril',
      August: 'Agosto',
      'August-a': 'Agosto',
      Away: 'Fora de casa',
      Camera: 'Câmera está',
      'Camera check': 'Verificação da câmera',
      'Camera is not avaliable': 'Câmera não disponível',
      Cancel: 'Cancelar',
      'Capital latin letters': 'Letras latinas maiúsculas',
      'Check camera': 'Verificar a câmera',
      City: 'Cidade',
      Clear: 'Reiniciar',
      'Combine videos to single file?': 'Combine videos to single file?',
      Confirm: 'Confirmar',
      Consecutive: 'Sequência',
      Counterpart: 'Contraparte',
      'Create link': 'Criar link',
      Dashboard: 'Barra Ferramentas',
      December: 'Dezembro',
      'December-a': 'Dezembro',
      Defence: 'Defensivo',
      Defences: 'Defensivos',
      Delete: 'Remover',
      'Delete event?': 'Apagar o evento?',
      'Delete playlist?': 'Apagar a lista de reprodução?',
      'Delete record?': 'Apagar a gravação?',
      Description: 'Descrição',
      Digits: 'Números',
      'Disable link': 'Desativar link',
      Done: 'Pronto!',
      'Download match?': 'Download match?',
      Draw: 'Empate',
      'Edit event': 'Editar evento',
      'Email has been sent': 'E-mail enviado',
      Enter: 'Entrar',
      'Enter email:': 'Inserir e-mail',
      'Enter password:': 'Digite sua senha',
      'Enter playlist name': 'Introduzir o nome da lista de reprodução',
      'Enter team name': 'Introduzir o nome da equipe',
      'Entire match': 'Jogo inteiro',
      Episodes: 'Episódios',
      'episodes-1': 'episódio',
      'episodes-2': 'episódios',
      'episodes-other': 'episódios',
      'episodes plural': 'episódios',
      Events: 'Eventos',
      February: 'Fevereiro',
      'February-a': 'Fevereiro',
      'File size should not exceed ': 'O tamanho do arquivo não deve exceder ',
      'File with this name already uploaded to this activity':
        'Arquivo com este nome já enviado para esta atividade',
      'First period': 'Primeiro tempo',
      'Forgot password?': 'Esqueceu sua senha?',
      Fr: 'Sex',
      'Full screen mode': 'Modo de tela cheia',
      Goalkeeper: 'Goleiro',
      Goalkeepers: 'Goleiros',
      H: 'E',
      'Half-back': 'Zagueiro',
      'Half-backs': 'Zagueiros',
      Help: 'Referência',
      Hide: 'Ocultar',
      Home: 'Em casa',
      'Home / away': ' Em casa / fora',
      'I agree that my': 'Concordo que meus',
      'If you were registered with your email':
        'Se você foi registrado com o e-mail indicado.',
      January: 'Janeiro',
      'January-a': 'Janeiro',
      July: 'Julho',
      'July-a': 'Julho',
      June: 'Junho',
      'June-a': 'Junho',
      'Last 10 matches': 'Os últimos 10 jogos',
      'Last 5 matches': 'Os últimos 5 jogos',
      'Last match': 'A Última Partida',
      'Link has expired':
        'A validade do link expirou. Por favor, solicite um link para redefinir sua senha',
      'Link was copied': 'O link foi copiado',
      'Log in': 'Entrar',
      'Login or password is wrong': 'Login ou senha incorreta',
      Loss: 'Derrota',
      'Make a new password:': 'Criar nova senhai:',
      March: 'Março',
      'March-a': 'Março',
      Markup: 'Marcação (vídeo)',
      Match: 'Jogo',
      'Match markup': 'Jogo marcação',
      'Match record': 'Gravação do jogo',
      Matches: 'Jogos',
      matches: 'Jogos',
      'matches-1': 'Jogo',
      'matches-2': 'Jogos',
      'matches-other': 'Jogos',
      'Matches and trainings': 'Jogos e treinos',
      May: 'Maio',
      'May-a': 'Maio',
      Mb: 'Mb',
      Metrics: 'Métricas',
      metrics: 'Métricas',
      Mo: 'Seg',
      'Next filter': 'Próximo filtro',
      'NOT FOUND!': '404. Página não encontrada',
      'Not unique playlist name': 'Não é exclusivo nome da lista de reprodução',
      'New event': 'O evento novo',
      No: 'Não',
      'Not chosen': 'Não selecionado',
      'Not less than 8 symbols': 'Pelo menos 8 caracteres',
      'Not selected': 'Não selecionado',
      November: 'Novembro',
      'November-a': 'Novembro',
      OK: 'OK',
      October: 'Outubro',
      'October-a': 'Outubro',
      Offence: 'Ofencivo',
      Offences: 'Ofencivos',
      'Panoramic view': 'Vista panorâmica',
      'Password was changed': 'A senha foi alterada',
      'Passwords do not match': 'As senhas não coincidem',
      Players: 'Jogadores',
      players: 'Jogadores',
      'Players matching': 'Seleção de jogadores',
      Playlist: 'Playlist',
      Playlists: 'Listas de reprodução',
      'Please enter valid email': 'Por favor, insira um e-mail válido',
      'Please stand by': 'Por favor, aguarde',
      Protocol: 'Protocolo',
      'Record event': 'Gravar evento',
      Recover: 'Recuperar',
      'Recover password': 'Recuperar a senha',
      Register: ' Registar-se',
      Registration: 'Inscrição',
      'Repeat password': 'Repetir a senha',
      'Required field': 'Campo obrigatório',
      Results: 'Resultados',
      Sa: ' Sab',
      Save: 'Salvar',
      Season: 'Temporada',
      'Second period': 'Segundo tempo',
      Select: 'Selecionar',
      Standards: 'Padrões',
      'Simple download': 'Download simples',
      'Select a team or add new': 'Selecionar a equipe ou adicionar uma nova',
      'Select all': 'Selecionar tudo',
      'Select another time or camera': 'Escolha outro horário ou outra câmera.',
      'Select current team to create an event':
        'Selecionar a equipe atual para criar um evento',
      'Select playlist': 'Selecionar a lista de reprodução',
      'Select team': 'Escolha uma equipe',
      'Select tournament': 'Selecionar torneio',
      September: 'Setembro',
      'September-a': 'Setembro',
      'Setup in application': 'Configuração no aplicativo',
      'Show more': 'Mostrar mais',
      'Small latin letters': 'Letras latinas minúsculas',
      Speed: 'Velocidade',
      'Start recording': 'Iniciar gravação',
      Statictics: 'Estatística',
      Statistics: 'Estatística',
      Stop: ' Parar',
      'Stop video recording?': 'Parar a gravação?',
      Su: 'Dom',
      'Teams and players': 'Equipes e jogadores',
      Th: 'Qui',
      'Time is occupied': 'O horário já está ocupado',
      'Time zone': 'Fuso horário',
      Timing: 'Temporização',
      timing: 'Temporização',
      'To playlist': 'Para a lista de reprodução.',
      Today: 'Hoje',
      Tournaments: 'Torneios',
      Training: 'Treino',
      Trainings: 'Treinos',
      Tu: 'Ter',
      'Upload video or markup': 'Carregar vídeo ou marcação de vídeo',
      We: ' Qua',
      Watch: 'Ver',
      Win: ' Vitória',
      Year: 'ano',
      'Yes, combine': 'Yes, combine',
      'Yes, download': 'Yes, download',
      'You cannot restore event.': 'Não é possível recuperar o evento',
      'You cannot restore playlist.':
        'Não é possível recuperar a lista de reprodução apagada',
      'You cannot restore record.': 'Não é possível recuperar a gravação',
      'You cannot resume record.': 'Não é possível recuperar a gravação',
      'You do not have active club set. Please contact technical support.':
        'Você não tem um conjunto de clubes ativo. Entre em contato com o suporte técnico',
      'You will receive an email with a link to download the archive with selected videos.':
        'Você receberá e-mail com um link para fazer o download de um arquivo de vídeos selecionados',
      'Your episodes': 'Seus episódios',
      apr: 'abr',
      'as of': 'Para',
      aug: 'ago',
      dec: 'dez',
      events: 'eventos',
      feb: 'fev',
      from: 'de',
      here: 'Aqui',
      'is busy': 'ocupada',
      jan: 'jan',
      jul: 'jul',
      jun: 'jun',
      mar: 'mar',
      may: 'maio',
      month: 'mês',
      more: 'mais',
      nov: 'nov',
      oct: 'out',
      'personal data will be processed': 'dados pessoais serão processados',
      s: 's.',
      sep: 'set',
      to: 'até',
      'we shall send you a message with password recover link.':
        'Nós lhe enviaremos um e-mail com um link para recuperar sua senha',
      year: 'ano',
    },
  },
};
// const allKeys = new Set();
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// for (const [lang, val] of Object.entries(resources)) {
//   console.log(Object.entries(resources));
//   Object.keys(resources[lang].translation).forEach((key) => allKeys.add(key));
// }
// for (const lang in resources) {
//   allKeys.forEach((key) => {
//     if (!(key in resources[lang]['translation'])) {
//       resources[lang]['translation'][key] = null;
//     }
//   });
// }

// console.log(resources);
// const i18n = i18next.createInstance();
// i18n.use(languageDetector).use(initReactI18next);
// const i18nInit = () => {
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false,
    },
  });
// }
export default i18n;
